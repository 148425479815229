import axios from "axios";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";
import { Post } from "../../models/Post";
import React, { useEffect, useState } from "react";

function NeedHelpSection() {
  const [post, setPost] = useState<Post | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch post data
    axios
      .get(`${API_BASE_URL}/posts/29`)
      .then((response) => {
        if (response.data.status === "PUBLISHED") {
          setPost(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching post data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return (
    <div className="py-16 px-[5%] md:px-[20%] block md:flex bg-[#e5e1d3] text-left items-center gap-x-16">
      <img
        src={`${STORAGE_BASE_URL}/${post?.image}`}
        alt=""
        className="w-[70%] mx-auto mb-6 sm:mb-0 md:w-[40%]"
      />
      <div>
        <h1 className="text-xl lg:text-3xl font-extrabold text-center md:text-left">
          {post?.title}
        </h1>
        {/* <p className="text-sm md:text-base lg:text-lg my-6">
          Si vous avez besoin d'assistance pour naviguer dans votre dossier ou
          si vous avez des questions spécifiques, n'hésitez pas à contacter
          notre service clientèle. Nous sommes là pour vous aider !
        </p>
        <ul className="text-sm md:text-base lg:text-lg my-6">
          <li className="my-1">- Email : serviceclient@oceanbridges.fr</li>
          <li className="my-1">- Téléphone : +33 1 23 45 67 </li>
          <li className="my-1">
            - Horaires : Du lundi au vendredi, de 9h à 18h{" "}
          </li>
        </ul> */}
        <div className="text-sm md:text-base lg:text-lg my-6" dangerouslySetInnerHTML={{ __html: post?.body || '' }} />
      </div>
    </div>
  );
}

export default NeedHelpSection;
