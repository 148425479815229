import React, { useEffect, useState } from "react";
import LatestNewsCard from "./LatestNewsCard";
import axios from "axios";
import { API_BASE_URL } from "../../const";
import { Post } from "../../models/Post";
import { LasEvent } from "../../models/Event";

function LatestNewsSection() {

  const [post, setPost] = useState<Post | null>(null);
  const [events, setEvents] = useState<LasEvent[]>([]);


  useEffect(() => {


    axios.get(`${API_BASE_URL}/posts/9`)
      .then(response => {
        const fetchedPost = response.data as Post;
        setPost(fetchedPost);
      })
      .catch(error => {
        console.error("Error fetching post:", error);
      });
    axios.get(`${API_BASE_URL}/last-events`)
      .then(response => {
        const fetchedEvents = response.data as LasEvent[];


        // Filter visible events
        // const visibleEvents = fetchedEvents.filter(event => event.visible === "1");
        setEvents(fetchedEvents);
      })
      .catch(error => {
        console.error("Error fetching events:", error);
      });

  }, []);

  return (
    <section className="bg-background text-left">
      <h1 className="font-bold text-xl">{post?.title}</h1>
      <div className="text-base mt-2 mb-6" dangerouslySetInnerHTML={{ __html: post?.body || '' }} />

      <div className="grid lg:grid-cols-3 gap-3 ">
        {events.map((event, index) => (
          <LatestNewsCard
            key={event.id}
            date={{ day: new Date(event.date).getDate(), month: new Date(event.date).toLocaleString('default', { month: 'long' }).toUpperCase(), year: new Date(event.date).getFullYear() }}
            title={event.title}
            news={event.description}
            dayBgColor={event.color} // Assuming you want to use color for day background
            // titleBgColor={event.color} // Assuming you want to use color for title background
            titleBgColor= {hexToRgba(event.color, 0.5)} // Assuming you want to use color for title background
          />
        ))}
      </div>
    </section>
  );
}


// Helper function to convert hex color to RGBA
function hexToRgba(hex: string, alpha: number): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export default LatestNewsSection;
