
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Service } from '../../models/ServiceObConnect';
import { API_BASE_URL, STORAGE_BASE_URL } from '../../const';



const ObConnectServices: React.FC = () => {
  const [services, setServices] = useState<Service[]>([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/ob-connect-services`);
        // Filter visible services
        const visibleServices = response.data.filter((service: Service) => service.visibility === 1);
        setServices(visibleServices);
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices();
  }, []);

  return (
    <section className="py-[5%] bg-black text-white text-left">
      <div className="grid grid-cols-1 lg:grid-cols-2 justify-between gap-6">
        {services.map((service, index) => (
          <div key={service.id} className="md:flex gap-4 text-xs 2xl:text-sm">
            <img
              src={`${STORAGE_BASE_URL}/${service.icon}`} // Use API icon URL
              alt={service.title}
              className="w-20 h-20 mb-6 rounded-md"
            />
            <div className="text-left">
              <h2 className="text-xl font-bold 2xl:w-1/2">{service.title}</h2>
              
              <div
                className="text-xs 2xl:text-sm"
                dangerouslySetInnerHTML={{ __html: service.body }}
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ObConnectServices;
