
import Button from "../Button";
import axios from "axios";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";
import { Post } from "../../models/Post";
import React, { useEffect, useState } from "react";

interface StayConnectedSectionProps {
  title?: string;
  subtitle?: string;
  description?: string;
  buttonText?: string;
}

function StayConnectedSection({
  title,
  subtitle,
  description,
  buttonText,
}: StayConnectedSectionProps) {
  const [post, setPost] = useState<Post | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch post data
    axios
      .get(`${API_BASE_URL}/posts/30`)
      .then((response) => {
        if (response.data.status === "PUBLISHED") {
          setPost(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching post data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading state while fetching
  }

  if (!post) {
    return null; // Don't render anything if post is not visible
  }

  return (
    <section className=" block md:flex bg-background text-left items-center gap-x-16">
      <img src={`${STORAGE_BASE_URL}/${post?.image}`} alt="" className="md:w-[40%]" />
      <div>
        <h1 className="font-bold">{post?.title}</h1>
        {/* <p className="text-lg my-2">
          {description ??
            "Pour ne rien manquer de nos actualités et mises à jour, abonnez-vous à notre newsletter et suivez-nous sur nos reseaux sociaux."}
        </p> */}
        <div className="text-lg my-2" dangerouslySetInnerHTML={{ __html: post?.body || '' }} />
        <Button
          text={buttonText ?? "EN SAVOIR +"}
          color="black"
          textColor="white"
          link="/ob-connect"
        />
      </div>
    </section>
  );
}

export default StayConnectedSection;
