import React from "react";

interface ButtonProps {
  link?: string;
  text: string;
  color?: string;
  textColor: string;
  borderColor?: string;
  size?: string;
  sizeSm?: string;
  hoverColor?: string;
  radius?:
  | "full"
  | "none"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl"
  | "7xl"
  | "8xl"
  | "9xl"
  | "10xl"
  | "none";
  padding?: number;
  width?: string;
  widthxl?: string;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  link,
  text,
  color,
  textColor,
  borderColor,
  size,
  sizeSm,
  hoverColor,
  radius,
  padding,
  width,
  widthxl,
  onClick,
}) => {
  // Determine the text size class based on the size prop
  const textSizeClass = size === "2xs" ? "text-[0.5rem] md:text-sm" : `text-${sizeSm ?? "2xs"}`;

  // Determine the width class based on the width prop
  // const widthxl = (width) - 10;
  const widthClass = width ? `w-auto md:w-fit` : "w-[6rem] md:w-fit";

  return (
    <a
      onClick={onClick}
      href={link}
      className={`bg-${color ?? "transparent"} text-center text-${textColor}  border-${borderColor ?? color}
      font-semibold transition duration-300 hover:text-primary
      hover:bg-${hoverColor ?? "transparent"} hover:border-${color} py-${padding ?? "2"} px-4 cursor-pointer border rounded-${radius ?? "full"}
      ${textSizeClass} ${widthClass} justify-self-none md:justify-self-center`}
    >
      {text}
    </a>
  );
};

export default Button;
