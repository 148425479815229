import React, { useState } from "react";
import Chevron from "../../assets/icons/chevron-up-blue.svg"; // Ensure the path is correct

interface OptionProps {
  index: number;
  title: string;
  text: string;
  moreInfo: string;
  checked: boolean;
  onCheckboxChange: (index: number) => void;
}

const CookiesOption: React.FC<OptionProps> = ({
  index,
  title,
  text,
  moreInfo,
  checked,
  onCheckboxChange,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = () => {
    onCheckboxChange(index);
  };

  return (
    <div className="border-b border-gray-200 py-4">
      <div className="flex items-start">
        <input
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          className="mt-1 mr-3 custom-checkbox focus:ring-0"
        />
        <div className="flex-1">
          <h2 className="text-sm md:text-lg font-bold">{title}</h2>
          <p className="text-xs sm:text-sm my-2">{text}</p>
          <div className="flex">
            <button
              onClick={toggleAccordion}
              className="text-primary text-xs sm:text-sm underline focus:outline-none"
            >
              {isOpen
                ? "Afficher moins d'informations"
                : "Afficher plus d'informations"}
            </button>
            <img
              src={Chevron}
              alt="chevron"
              className={`w-4 sm:w-6 transition-transform duration-300 ${
                isOpen ? "rotate-180" : ""
              }`}
            />
          </div>
          <div
            className={`transition-all duration-500 ${
              isOpen ? "max-h-screen" : "max-h-0 overflow-hidden"
            }`}
          >
            <p className="text-xs sm:text-sm mt-2">{moreInfo}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesOption;
