import React from "react";

interface ProcessCardProps {
  index?: number;
  title: string;
  items: { title?: string; description?: string }[];
  icon?: string;
  iconBgColor: string;
  textBgColor: string;
}

const ProcessCard: React.FC<ProcessCardProps> = ({
  index,
  title,
  items,
  icon,
  iconBgColor,
  textBgColor,
}) => {
  return (
    <div className="bg-[#f7f4ea] text-left h-full">
      <div className="flex  w-full">
        <div
          style={{ backgroundColor: iconBgColor }}
          className="p-2 lg:p-6 text-center"
        >
          <h2 className="text-white font-bold text-base lg:text-lg uppercase">
            étape
          </h2>
          <h1 className="text-white font-bold text-2xl lg:text-5xl">
            0{index}
          </h1>
        </div>
        <div
          className="p-4 flex flex-col justify-center w-full"
          style={{ backgroundColor: textBgColor }}
        >
          <h3 className="text-sm lg:text-lg font-semibold">{title}</h3>
        </div>
      </div>
      <div className="p-4">
        {items.map((item, index) => (
          <div key={index} className="my-4 lg:my-6">
            <h4 className="text-base font-semibold">{item.title}</h4>
            {/* <p className="text-sm">{item.description}</p> */}
            <p
              className="text-sm"
              dangerouslySetInnerHTML={{ __html: item.description || '' }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProcessCard;
