
import React, { useEffect, useState } from "react";
import { BoxReveal } from "../box-reveal";
import { useContactModal } from "../../hooks/useContactModal";
import { Post } from "../../models/Post";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";

function PartnershipSection() {
  const { isContactOpen, toggleContactModal } = useContactModal();
  const [post, setPost] = useState<Post | null>(null);

  useEffect(() => {
    // fetch("http://64.226.126.75/api/posts/20")
    fetch(`${API_BASE_URL}/posts/20`)
      .then((response) => response.json())
      .then((data: Post) => {
        // Check if the post is published
        if (data.status === "PUBLISHED") {
          setPost(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching post data:", error);
      });
  }, []);

  if (!post) {
    return null; // Render nothing if the post is not published or not found
  }

  return (
    <section className="relative p-0">
      <img
        src={`${STORAGE_BASE_URL}/${post?.image}`}
        alt=""
        className="object-fill w-full h-full absolute inset-0 z-0"
      />
      <div className="h-full xl:max-w-[70%] px-[5%] md:px-[20%] pt-10 sm:py-16 pb-56 lg:pb-32 items-center justify-center z-50 bg-transparent">
        <BoxReveal boxColor={""} duration={0.5}>
          <h2 className="text-left text-white text-lg md:text-xl mb-8">
            {post.title}
          </h2>
        </BoxReveal>

        <BoxReveal boxColor={""} duration={0.5}>
          <div
            className="text-white mt-[1.5rem] text-left text-xs sm:text-sm md:text-base"
            dangerouslySetInnerHTML={{ __html: post.body }}
          />
        </BoxReveal>

        <div className="m-8 sm:m-2"></div>

        <BoxReveal boxColor={""} duration={0.5}>
          <button
            onClick={toggleContactModal}
            className="bg-white hover:bg-blue text-[#399bd8] text-xs md:text-sm font-extrabold transition duration-300 hover:text-white hover:bg-transparent py-2 px-4 border border-blue hover:border-white rounded-full pointer-events-auto"
          >
            CONTACTEZ NOUS
          </button>
        </BoxReveal>
      </div>
    </section>
  );
}

export default PartnershipSection;