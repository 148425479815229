import { createContext, useState, useContext, ReactNode } from 'react';

// Define types for context state
interface ContactModalState {
  isContactOpen: boolean;
}

// Define types for context methods
interface ContactModalContextType extends ContactModalState {
  toggleContactModal: () => void;
}

// Create context with initial state
const ContactModalContext = createContext<ContactModalContextType | undefined>(undefined);

// Create provider component
export const ContactModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isContactOpen, setIsContactOpen] = useState(false);

  const toggleContactModal = () => {
    setIsContactOpen(!isContactOpen);
  };

  // Define context value with state and methods
  const contextValue: ContactModalContextType = {
    isContactOpen,
    toggleContactModal,
  };

  return (
    <ContactModalContext.Provider value={contextValue}>
      {children}
    </ContactModalContext.Provider>
  );
};

// Create custom hook to use context
export const useContactModal = () => {
  const context = useContext(ContactModalContext);
  if (!context) {
    throw new Error('useContactModal must be used within a ContactModalProvider');
  }
  return context;
};
