import React from "react";

interface OfferCardProps {
  index?: number;
  title: string;
  items: { description?: string }[];
  icon?: string;
  iconBgColor: string;
  textBgColor: string;
}

const OfferCard: React.FC<OfferCardProps> = ({
  index,
  title,
  items,
  icon,
  iconBgColor,
  textBgColor,
}) => {
  return (
    <div className="bg-[#f7f4ea] text-left">
      <div className="flex 2xl:h-[30%] w-full">
        <div
          style={{ backgroundColor: iconBgColor }}
          className="px-6 py-4 text-center"
        >
          <h2 className="text-white font-bold text-lg uppercase">étape</h2>
          <h1 className="text-white font-bold text-5xl">0{index}</h1>
        </div>
        <div
          className="p-4 flex flex-col justify-center w-full"
          style={{ backgroundColor: hexToRgba(iconBgColor, 0.45) }}
        >
          <h3 className="text-xl font-semibold">{title}</h3>
        </div>
      </div>
      <div className="p-4">
        {items.map((item, idx) => (
          
          <div key={idx} className="mb-4">
     
            {/* {item.title && <h4 className="text-base font-semibold">{item.title}</h4>} */}
            {item.description && <p className="text-sm" dangerouslySetInnerHTML={{ __html: item.description }} />}
          </div>
        ))}
      </div>
    </div>
  );
};

// Helper function to convert hex color to RGBA
function hexToRgba(hex: string, alpha: number = 1): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
export default OfferCard;
