import React, { useEffect, useState } from "react";

import { BoxReveal } from "../box-reveal";
import Button from "../Button";
import axios from "axios";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";
import { Post } from "../../models/Post";
import { useContactModal } from "../../hooks/useContactModal";

function ImpactSection() {
  const [post, setPost] = useState<Post | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { toggleContactModal } = useContactModal();

  useEffect(() => {
    // Fetch post data
    axios
      .get(`${API_BASE_URL}/posts/32`)
      .then((response) => {
        if (response.data.status === "PUBLISHED") {
          setPost(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching post data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading state while fetching
  }

  if (!post) {
    return null; // Don't render anything if post is not visible
  }
  return (
    <section className="relative p-0">
      <img
        src={`${STORAGE_BASE_URL}/${post?.image}`}
        alt=""
        className="object-cover w-full h-full absolute inset-0 z-0"
      />
      <div className="h-full xl:max-w-[75%] px-[5%] md:px-[20%] py-[10%] md:py-[8%] items-center justify-center z-50 bg-transparent">
        <BoxReveal boxColor={""} duration={0.5}>
          <h1 className="text-left text-white text-lg md:text-2xl font-bold">
            {post?.title}
          </h1>
        </BoxReveal>

        <BoxReveal boxColor={""} duration={0.5}>
          <div className="text-white mt-[1.5rem] text-left text-sm md:text-base">
            <div dangerouslySetInnerHTML={{ __html: post?.body }} />
          </div>
        </BoxReveal>

        <div className="m-6"></div>

        <BoxReveal boxColor={""} duration={0.5}>
          {/* <button className="bg-white hover:bg-blue text-[#399bd8]  text-sm md:font-semibold md:text-sm transition duration-300 hover:text-white hover:bg-transparent py-2 px-4 border border-blue hover:border-white rounded-full pointer-events-auto">
            NOUS CONTACTER
          </button> */}
          <Button
            text="Contactez-nous"
            color="white"
            textColor="primary"
            onClick={toggleContactModal}
          />
        </BoxReveal>
      </div>
    </section>
  );
}

export default ImpactSection;
