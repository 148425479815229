import React, { useEffect, useState } from "react";
import Contact from "../../assets/img/contact/contact.svg";
import { useContactModal } from "../../hooks/useContactModal";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";

interface ContactUsProps {
  title?: string;
  subtitle?: string;
  description?: string;
  buttonText?: string;
}

const ContactUsSection: React.FC<ContactUsProps> = ({
  title,
  subtitle,
  description,
  buttonText,
}: ContactUsProps) => {
  const { toggleContactModal } = useContactModal();
  const [post, setPost] = useState<any>(null);

  useEffect(() => {
    // Fetch the post data from the API
    fetch(`${API_BASE_URL}/posts/23`)
      .then((response) => response.json())
      .then((data) => {
        // Check if the post is published
        if (data.status === "PUBLISHED") {
          setPost(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching post:", error);
      });
  }, []);

  return (
    <section className="bg-[#fffdf8]">
      <h1 className="text-base md:text-2xl text-black text-center md:text-left">
        {post?.title ?? title ?? "NOS SPÉCIALISTES À VOTRE ÉCOUTE"}
      </h1>
      <div className="block md:flex text-center md:text-left mt-8 gap-x-6 justify-center">
        <img
          src={post?.image ? `${STORAGE_BASE_URL}/${post.image}` : Contact}
          alt=""
          className="w-[25%] md:w-[15%] mx-auto md:mx-0 my-4 md:my-0"
        />
        <div className="md:w-[50%]">
          <h1 className="text-base md:text-xl text-black font-extrabold mb-3">
            {subtitle ??
              "VOUS SOUHAITEZ EN SAVOIR PLUS SUR NOS PRODUITS ET NOS SERVICES ?"}
          </h1>
          <p
            className="text-xs md:text-base text-[#867b78]"
            dangerouslySetInnerHTML={{
              __html:
                post?.body ??
                description ??
                "Nos équipes vous répondent et peuvent vous accompagner avec l'aide de votre courtier pour trouver des solutions d'assurance.",
            }}
          />
        </div>
        <button
          onClick={toggleContactModal}
          className="my-auto mt-4 sm:mt-0 bg-transparent break-keep text-primary font-extrabold text-sm md:text-lg transition duration-300 hover:text-white hover:bg-primary py-2 px-4 border border-primary hover:border-transparent rounded-full h-min"
        >
          {buttonText ?? "CONTACTEZ NOUS"}
        </button>
      </div>
    </section>
  );
};

export default ContactUsSection;
