import React, { useEffect, useState } from "react";
import ProcessCard from "./ProcessCard";
import { InfoSection } from "../../models/InfoSection";
import { API_BASE_URL } from "../../const";
import { Post } from "../../models/Post";

const ProcessSection = () => {
  const [infoSections, setInfoSections] = useState<InfoSection[]>([]);
  const [post, setPost] = useState<Post>();

  useEffect(() => {
    fetch(`${API_BASE_URL}/posts/14`)
      .then((response) => response.json())
      .then((data) => {
        // Check if the post is published
        if (data.status === "PUBLISHED") {
          setPost(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching post:", error);
      });

    // Fetch data from the API
    fetch(`${API_BASE_URL}/recruitment-processes`)
      .then((response) => response.json())
      .then((data) => {
        // Filter the data based on visibility
        const filteredData = data.filter((item: any) => item.visibility === 1);

        // Map the data to InfoSection format
        const sections = filteredData.map((item: any) => ({
          id: item.id,
          title: item.title,
          items: [
            {
              description: item.description,
            },
          ],
          iconBgColor: item.color,
          textBgColor: hexToRgba(item.color, 0.5), // Example background color, adjust as needed
        }));

        setInfoSections(sections);
      });
  }, []);

  return (
    <section className="bg-accent">
      {post && (
        <>
          <h1 className="text-left font-bold text-xl md:text-2xl mb-4">
            {post.title}
          </h1>
          <h2
            className="text-left text-base md:text-lg mb-8"
            dangerouslySetInnerHTML={{ __html: post.body }}
          />
        </>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {infoSections.map((section) => (
          <div key={section.id} className="text-black">
            <ProcessCard
              index={section.id}
              key={section.id}
              title={section.title}
              items={section.items}
              iconBgColor={section.iconBgColor}
              textBgColor={section.textBgColor}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

// Helper function to convert hex color to RGBA
function hexToRgba(hex: string, alpha: number = 1): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export default ProcessSection;
