import { useEffect, useState } from "react";
import axios from "axios";
import Question from "./Question";
import { FAQ } from "../../models/FAQ";
import { API_BASE_URL } from "../../const";


function QuestionsSection() {
  const [faqs, setFaqs] = useState<FAQ[]>([]);

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        // const response = await axios.get<FAQ[]>("http://64.226.126.75/api/faqs");
        const response = await axios.get<FAQ[]>(`${API_BASE_URL}/faqs`);
        const visibleFaqs = response.data.filter(faq => faq.visibility === 1);
        setFaqs(visibleFaqs);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFaqs();
  }, []);

  return (
    <section className="bg-background">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8">
        {faqs.map((faq, i) => (
          <Question
            key={i}
            question={faq.question}
            answer={faq.response}
            color={faq.color}
            bgColor={hexToRgba(faq.color, 0.5)}
            index={faq.number}
          />
        ))}
      </div>
    </section>
  );
}

// Helper function to convert hex color to RGBA
function hexToRgba(hex: string, alpha: number): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export default QuestionsSection;