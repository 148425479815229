import { Outlet } from "react-router-dom";
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import Subscribe from "../components/Subscribe";
import BackToUp from "@uiw/react-back-to-top";
import chevron from "../assets/icons/chevron-up.svg";
import ContactModel from "../components/common/ContactModal";
import SpringModal from "../components/common/SpringModal";
import { useContactModal } from "../hooks/useContactModal";

function Layout() {
  const { isContactOpen, toggleContactModal } = useContactModal();

  return (
    <div className="min-h-screen">
      <SpringModal
        widthClass="max-w-[100%] 2xl:max-w-[40%]"
        isOpen={isContactOpen}
        toggleIsOpen={toggleContactModal}
        children={<ContactModel />}
      />
      <Subscribe />
      <NavigationBar toggleContactModal={toggleContactModal} />
      <Outlet />
      <Footer />
      <BackToUp className="sm:m-8">
        <img src={chevron} alt="chevron" />
      </BackToUp>
    </div>
  );
}

export default Layout;
