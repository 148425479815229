import React, { useEffect, useState } from "react";
import axios from "axios";
import { A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";

function PartnersSection() {
  const [partners, setPartners] = useState<any[]>([]);
  const [fixedPartners, setFixedPartners] = useState<any[]>([]);
  const [slidingPartners, setSlidingPartners] = useState<any[]>([]);

  useEffect(() => {
    axios.get(`${API_BASE_URL}/parteners`)
      .then(response => {
        const partners = response.data.filter((partner: any) => partner.visible === 1);
        setFixedPartners(partners.filter((partner: any) => partner.fixed === 1));
        setSlidingPartners(partners.filter((partner: any) => partner.fixed === 0));
      })
      .catch(error => {
        console.error("Error fetching partners data", error);
      });
  }, []);

  return (
    <section className="py-12 px-0 md:px-[20%] bg-[#e5e1d3]">
      <h1 className="text-base md:text-2xl text-[#867b78] mb-10 text-center sm:text-left">
        NOS PARTENAIRES
      </h1>
      <div className="flex justify-between md:gap-x-12 items-center">
        {/* Display fixed partners */}
        {fixedPartners.map((partner, index) => (
          <img
            key={partner.id}
            src={`${STORAGE_BASE_URL}/${partner.logo_url}`}
            alt={`Partner ${index + 1}`}
            className={`max-w-[20%]`}
          />
        ))}

        {/* Display sliding partners inside Swiper */}
        {slidingPartners.length > 0 && (
          <Swiper
            modules={[A11y, Autoplay]}
            slidesPerView={1}
            loop={true}
            autoplay={{ delay: 1000, disableOnInteraction: false }}
            pagination={{ clickable: true }}
            className="max-w-[100vw] h-auto content-center 4xl:h-[8vh] mt-4 text-white"
            breakpoints={{
              320: {
                slidesPerView: 2.1,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: 3.1,
                spaceBetween: 5,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 5,
              },
              2560: {
                slidesPerView: 5,
                spaceBetween: 5,
              },
            }}
          >
            {slidingPartners.map((partner) => (
              <SwiperSlide key={partner.id} className="text-black h-full self-center">
                <div className="flex items-center justify-center h-full bg-contain">
                  <img src={`${STORAGE_BASE_URL}/${partner.logo_url}`} alt={`Partner ${partner.id}`} className={`self-center`} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </section>
  );
}

export default PartnersSection;
