import React from "react";
import HeroTemplate from "../components/common/HeroTemplate";
import CalendarEventsSection from "../components/calendar/CalendarEventsSection";
import RemindersSection from "../components/calendar/RemindersSection";
import StayConnectedSection from "../components/news/StayConnectedSection";

import { useEffect, useState } from "react";
import { API_BASE_URL, STORAGE_BASE_URL } from "../const";
import axios from "axios";

function Calendar() {
  const [heroData, setHeroData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/heroes/2`);
        if (response.data.visiblity === 1) {
          setHeroData(response.data);
        } else {
          setError('Hero data is not visible.');
        }
      } catch (err) {
        setError('Failed to fetch hero data.');
      } finally {
        setLoading(false);
      }
    };

    fetchHeroData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (!heroData) return null;
  return (
    <div>
      {/* <HeroTemplate
        title="CALENDRIER"
        subtitle="Chez Ocean Bridges, nous croyons à la valeur de rester
connectés et informés. Notre calendrier d'événements
est conçu pour vous tenir au courant des opportunités
de networking, des webinaires éducatifs, et des
événements majeurs de l'industrie de l'assurance."
        background={background}
        color="#43588f"
      /> */}
      <HeroTemplate
      title={heroData.title}
      subtitle={heroData.description.replace(/<\/?div>/g, '')} 
      background={`${STORAGE_BASE_URL}/${heroData.imageurl}`}
        // color="#43588f"
    />
      <CalendarEventsSection />
      <RemindersSection />
      <StayConnectedSection
        title="ABONNEZ-VOUS À NOTRE CALENDRIER "
        description="Intégrez notre calendrier à votre agenda personnel pour ne jamaismanquer un événement important."
        buttonText="ABONNEZ-VOUS"
      />
    </div>
  );
}

export default Calendar;
