import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { FormContext } from "../../context/ReclamaionFormContext";
import Slider from "../../components/Slider";

const validationSchema = Yup.object({
  alreadyInsured: Yup.string().required(),
  lastName: Yup.string().required("Le champ Nom est obligatoire"),
  firstName: Yup.string().required("Le champ Prénom cas est obligatoire"),
  address: Yup.string().required("Le champ Adresse postale est obligatoire"),
  email: Yup.string()
    .email("Le format d'email est invalide")
    .required("Le champ Adresse Email est obligatoire"),
  phone: Yup.string()
    .matches(
      /^(?:(?:(?:\+|00)33[ ]?(?:\(0\)[ ]?)?)|0){1}[1-7]{1}([ .-]?)(?:\d{2}\1?){3}\d{2}$/,
      "Le champ Téléphone est un numero français"
    )
    .required("Le champ Téléphone est obligatoire"),
  adhesionDate: Yup.lazy((_, context) => {
    if (context.parent.alreadyInsured === "Oui") {
      return Yup.string().required("Le champ Date adhésion est obligatoire");
    }
    return Yup.string();
  }),
  adhesionNumber: Yup.lazy((_, context) => {
    if (context.parent.alreadyInsured === "Oui") {
      return Yup.string()
        .matches(/^\d+$/, "Le champ Numéro adhésion est un numero")
        .required("Le champ Numéro adhésion est obligatoire");
    }
    return Yup.string();
  }),
  clientNumber: Yup.lazy((_, context) => {
    if (context.parent.alreadyInsured === "Oui") {
      return Yup.string()
        .matches(/^\d+$/, "Le champ Numéro de client est un numero")
        .required("Le champ Numéro de client est obligatoire");
    }
    return Yup.string();
  }),
  contractNumber: Yup.lazy((_, context) => {
    if (context.parent.alreadyInsured === "Oui") {
      return Yup.string()
        .matches(/^\d+$/, "Le champ Numéro de contrat est un numero")
        .required("Le champ Numéro de contrat est obligatoire");
    }
    return Yup.string();
  }),
  claimNumber: Yup.lazy((_, context) => {
    if (context.parent.alreadyInsured === "Oui") {
      return Yup.string()
        .matches(/^\d+$/, "Le champ Numéro de sinistre est un numero")
        .required("Le champ Numéro de sinistre est obligatoire");
    }
    return Yup.string();
  }),
  receiptNumber: Yup.lazy((_, context) => {
    if (context.parent.alreadyInsured === "Oui") {
      return Yup.string()
        .matches(/^\d+$/, "Le champ Numéro de quittance est un numero")
        .required("Le champ Numéro de quittance est obligatoire");
    }
    return Yup.string();
  }),
});

function ContactInfo() {
  const navigate = useNavigate();

  const { formData, setFormData } = useContext(FormContext);

  const formik = useFormik({
    initialValues: {
      alreadyInsured: formData.alreadyInsured || "",
      lastName: formData.lastName || "",
      firstName: formData.firstName || "",
      address: formData.address || "",
      email: formData.email || "",
      phone: formData.phone || "",
      adhesionDate: formData.adhesionDate || "",
      adhesionNumber: formData.adhesionNumber || "",
      clientNumber: formData.clientNumber || "",
      contractNumber: formData.contractNumber || "",
      claimNumber: formData.claimNumber || "",
      receiptNumber: formData.receiptNumber || "",
    },
    validationSchema,
    onSubmit: (values) => {
      setFormData({ ...formData, ...values });
      navigate("/claim/first-confirmation");
    },
  });

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;

  //   const updatedData = {
  //     [name]: value,
  //   };

  //   if (name === "alreadyInsured" && value === "Non") {
  //     Object.assign(updatedData, {
  //       adhesionDate: "",
  //       adhesionNumber: "",
  //       clientNumber: "",
  //       contractNumber: "",
  //       claimNumber: "",
  //       receiptNumber: "",
  //     });
  //   }

  //   setFormData((prevData) => ({
  //     ...prevData,
  //     ...updatedData,
  //   }));
  // };

  return (
    <div className="overflow-x-hidden">
      <Slider activeStep={2} />

      <section className="bg-background lg:-mx-20">
        <div className="text-center p-4 sm:p-6 md:p-8 lg:p-0">
          <h1 className="text-primary text-base font-semibold md:text-lg">
            VOS COORDONNÉES
          </h1>
          <div
            className={`lg:flex lg:justify-between ${
              (formik.errors.lastName && formik.touched.lastName) ||
              (formik.errors.firstName && formik.touched.firstName)
                ? "lg:items-center"
                : "lg:items-end"
            }`}
          >
            <div className="mt-2 px-4 lg:px-0 pt-8 flex items-center justify-between lg:mb-3 lg:w-[50%]">
              <p className="text-black text-xs text-left max-w-36 sm:max-w-none sm:text-base">
                Etes déjà assuré auprès de Ocean Bridges?
              </p>
              <div
                className={`flex gap-2 text-xs lg:text-base ${
                  formik.errors.alreadyInsured && formik.touched.alreadyInsured
                    ? "text-red"
                    : "text-black"
                }`}
              >
                <input
                  type="radio"
                  name="alreadyInsured"
                  value="Oui"
                  checked={formik.values.alreadyInsured === "Oui"}
                  onChange={formik.handleChange}
                />
                <label>Oui</label>
                <input
                  type="radio"
                  name="alreadyInsured"
                  value="Non"
                  checked={formik.values.alreadyInsured === "Non"}
                  onChange={formik.handleChange}
                />
                <label>Non</label>
              </div>
            </div>
            <div className="flex justify-between lg:w-[45%]">
              <div className="mt-2 px-4 lg:px-4 pt-8 flex flex-col gap-2">
                <p className="text-black text-xs text-left max-w-52 sm:text-base">
                  Nom
                </p>
                <input
                  type="text"
                  name="lastName"
                  value={formik.values.lastName || ""}
                  onChange={formik.handleChange}
                  className={`w-full rounded-full border-2  ${
                    formik.errors.lastName && formik.touched.lastName
                      ? "border-red"
                      : "border-accent"
                  }`}
                />
                {formik.errors.lastName && formik.touched.lastName && (
                  <p className="text-red text-base mt-2">
                    {formik.errors.lastName}
                  </p>
                )}
              </div>
              <div className="mt-2 px-4 lg:px-0 pt-8 flex flex-col gap-2">
                <p className="text-black text-xs text-left max-w-52 sm:text-base">
                  Prénom
                </p>
                <input
                  type="text"
                  name="firstName"
                  value={formik.values.firstName || ""}
                  onChange={formik.handleChange}
                  className={`w-full rounded-full border-2  ${
                    formik.errors.firstName && formik.touched.firstName
                      ? "border-red"
                      : "border-accent"
                  }`}
                />
                {formik.errors.firstName && formik.touched.firstName && (
                  <p className="text-red text-base mt-2">
                    {formik.errors.firstName}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="lg:flex lg:justify-between lg:items-start lg:flex-wrap">
            <div className="mt-2 px-4 lg:px-0 pt-8 flex flex-col gap-2 lg:w-[50%]">
              <p className="text-black text-xs text-left max-w-52 sm:text-base">
                Adresse postale
              </p>
              <input
                type="text"
                name="address"
                value={formik.values.address || ""}
                onChange={formik.handleChange}
                className={`w-full rounded-full border-2  ${
                  formik.errors.address && formik.touched.address
                    ? "border-red"
                    : "border-accent"
                }`}
              />
              {formik.errors.address && formik.touched.address && (
                <p className="text-red text-base mt-2">
                  {formik.errors.address}
                </p>
              )}
            </div>

            {formik.values.alreadyInsured === "Oui" && (
              <div className="flex justify-between lg:w-[45%]">
                <div className="mt-2 px-4 lg:px-4 pt-8 flex flex-col gap-2">
                  <p className="text-black text-xs text-left max-w-52 sm:text-base">
                    Date adhésion
                  </p>
                  <input
                    name="adhesionDate"
                    type="date"
                    value={formik.values.adhesionDate || ""}
                    onChange={formik.handleChange}
                    className={`w-full rounded-full border-2  ${
                      formik.errors.adhesionDate && formik.touched.adhesionDate
                        ? "border-red"
                        : "border-accent"
                    }`}
                  />
                  {formik.errors.adhesionDate &&
                    formik.touched.adhesionDate && (
                      <p className="text-red text-base mt-2">
                        {formik.errors.adhesionDate}
                      </p>
                    )}
                </div>
                <div className="mt-2 px-4 lg:px-0 pt-8 flex flex-col gap-2">
                  <p className="text-black text-xs text-left max-w-52 sm:text-base">
                    Numéro adhésion
                  </p>
                  <input
                    type="text"
                    name="adhesionNumber"
                    value={formik.values.adhesionNumber || ""}
                    onChange={formik.handleChange}
                    className={`w-full rounded-full border-2  ${
                      formik.errors.adhesionNumber &&
                      formik.touched.adhesionNumber
                        ? "border-red"
                        : "border-accent"
                    }`}
                  />
                  {formik.errors.adhesionNumber &&
                    formik.touched.adhesionNumber && (
                      <p className="text-red text-base mt-2">
                        {formik.errors.adhesionNumber}
                      </p>
                    )}
                </div>
              </div>
            )}

            <div
              className={`flex justify-between ${
                formik.values.alreadyInsured === "Oui"
                  ? "lg:w-[100%]"
                  : "lg:w-[45%]"
              }`}
            >
              <div
                className={`mt-2 px-4 ${
                  formik.values.alreadyInsured === "Oui" ? "lg:px-0" : "lg:px-4"
                } pt-8 flex flex-col gap-2 ${
                  formik.values.alreadyInsured === "Oui" ? "lg:w-[50%]" : ""
                }`}
              >
                <p className="text-black text-xs text-left max-w-52 sm:text-base">
                  Adresse Email
                </p>
                <input
                  type="email"
                  name="email"
                  value={formik.values.email || ""}
                  onChange={formik.handleChange}
                  className={`w-full rounded-full border-2  ${
                    formik.errors.email && formik.touched.email
                      ? "border-red"
                      : "border-accent"
                  }`}
                />
                {formik.errors.email && formik.touched.email && (
                  <p className="text-red text-base mt-2">
                    {formik.errors.email}
                  </p>
                )}
              </div>
              <div
                className={`mt-2 px-4 lg:px-4 lg:pr-0 pt-8 flex flex-col gap-2 ${
                  formik.values.alreadyInsured === "Oui" ? "lg:w-[45%]" : ""
                }`}
              >
                <p className="text-black text-xs text-left max-w-52 sm:text-base">
                  Téléphone
                </p>
                <input
                  type="tel"
                  name="phone"
                  value={formik.values.phone || ""}
                  onChange={formik.handleChange}
                  className={`w-full rounded-full border-2  ${
                    formik.errors.phone && formik.touched.phone
                      ? "border-red"
                      : "border-accent"
                  }`}
                />
                {formik.errors.phone && formik.touched.phone && (
                  <p className="text-red text-base mt-2">
                    {formik.errors.phone}
                  </p>
                )}
              </div>
            </div>
          </div>

          {formik.values.alreadyInsured === "Oui" && (
            <div className="lg:flex lg:justify-between">
              <div className="flex justify-between lg:w-[51.5%]">
                <div className="mt-2 px-4 lg:px-0 pt-8 flex flex-col gap-2">
                  <p className="text-black text-xs text-left max-w-52 sm:text-base">
                    Numéro de client
                  </p>
                  <input
                    type="text"
                    name="clientNumber"
                    value={formik.values.clientNumber || ""}
                    onChange={formik.handleChange}
                    className={`w-full rounded-full border-2  ${
                      formik.errors.clientNumber && formik.touched.clientNumber
                        ? "border-red"
                        : "border-accent"
                    }`}
                  />
                  {formik.errors.clientNumber &&
                    formik.touched.clientNumber && (
                      <p className="text-red text-base mt-2">
                        {formik.errors.clientNumber}
                      </p>
                    )}
                </div>
                <div className="mt-2 px-4 lg:px-4 pt-8 flex flex-col gap-2">
                  <p className="text-black text-xs text-left max-w-52 sm:text-base">
                    Numéro de contrat
                  </p>
                  <input
                    type="text"
                    name="contractNumber"
                    value={formik.values.contractNumber || ""}
                    onChange={formik.handleChange}
                    className={`w-full rounded-full border-2  ${
                      formik.errors.contractNumber &&
                      formik.touched.contractNumber
                        ? "border-red"
                        : "border-accent"
                    }`}
                  />
                  {formik.errors.contractNumber &&
                    formik.touched.contractNumber && (
                      <p className="text-red text-base mt-2">
                        {formik.errors.contractNumber}
                      </p>
                    )}
                </div>
              </div>
              <div className="flex justify-between lg:w-[45%]">
                <div className="mt-2 px-4 lg:px-4 pt-8 flex flex-col gap-2">
                  <p className="text-black text-xs text-left max-w-52 whitespace-nowrap sm:text-base">
                    Numéro de sinistre
                  </p>
                  <input
                    type="text"
                    name="claimNumber"
                    value={formik.values.claimNumber || ""}
                    onChange={formik.handleChange}
                    className={`w-full rounded-full border-2  ${
                      formik.errors.claimNumber && formik.touched.claimNumber
                        ? "border-red"
                        : "border-accent"
                    }`}
                  />
                  {formik.errors.claimNumber && formik.touched.claimNumber && (
                    <p className="text-red text-base mt-2">
                      {formik.errors.claimNumber}
                    </p>
                  )}
                </div>
                <div className="mt-2 px-4 lg:px-0 pt-8 flex flex-col gap-2">
                  <p className="text-black text-xs text-left max-w-52 whitespace-nowrap sm:text-base">
                    Numéro de quittance
                  </p>
                  <input
                    type="text"
                    name="receiptNumber"
                    value={formik.values.receiptNumber || ""}
                    onChange={formik.handleChange}
                    className={`w-full rounded-full border-2 ${
                      formik.errors.receiptNumber &&
                      formik.touched.receiptNumber
                        ? "border-red"
                        : "border-accent"
                    }`}
                  />
                  {formik.errors.receiptNumber &&
                    formik.touched.receiptNumber && (
                      <p className="text-red text-base mt-2">
                        {formik.errors.receiptNumber}
                      </p>
                    )}
                </div>
              </div>
            </div>
          )}

          <div className="flex gap-4 justify-center md:justify-between sm:mx-16 mt-20">
            <button
              className="bg-black font-semibold text-white text-xs rounded-[0.875rem] sm:rounded-[1.25rem] w-[7.625rem] sm:w-[11.75rem] sm:text-base px-6 py-2 my-6"
              onClick={() => navigate("/claim/yours")}
            >
              PRÉCÉDENT
            </button>
            <button
              className="bg-primary font-semibold text-white text-xs rounded-[0.875rem] sm:rounded-[1.25rem] w-[7.625rem] sm:w-[11.75rem] sm:text-base px-6 py-2 my-6"
              onClick={() => formik.handleSubmit()}
            >
              SUIVANT
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactInfo;
