import { useEffect, useState } from "react";
import Connect from "../../assets/connect.png";
import { Post } from "../../models/Post";
import axios from "axios";
import { API_BASE_URL } from "../../const";
import Button from "../Button";
import BoxReveal from "../box-reveal";
import { useContactModal } from "../../hooks/useContactModal";

function RegulationContact() {
  const [post, setPost] = useState<Post>();
  const { toggleContactModal } = useContactModal();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get<Post>(`${API_BASE_URL}/posts/35`);
        // const visiblePosts = response.data.filter((post) => post.visible === 1);
        setPost(response.data);
      } catch (error) {
        console.error("Error fetching useful tips:", error);
      }
    };

    fetchPosts();
  }, [])
  return (
    <div className="py-16 px-[5%] md:px-[20%] block md:flex bg-[#e5e1d3] text-left items-center gap-x-16">
      <img src={Connect} alt="" className="md:w-[40%]" />
      <div>
        <h1 className="font-bold">{post?.title}</h1>
        <div className="text-lg my-6">
            <p dangerouslySetInnerHTML={{ __html: post?.body || "" }} />
          </div>
        {/* <p className="text-lg my-6">
          Pour questions ou assistance relative à la réglementation, veuillez
          contacter notre département juridique à
        </p>
        <ul className="text-lg my-6">
          <li className="my-1">- Email : serviceclient@oceanbridges.fr</li>
          <li className="my-1">- Téléphone : +33 1 23 45 67 </li>
        </ul> */}
         <BoxReveal boxColor={""} duration={0.5}>
          {/* <button className="bg-white hover:bg-blue text-[#399bd8]  text-sm md:font-semibold md:text-sm transition duration-300 hover:text-white hover:bg-transparent py-2 px-4 border border-blue hover:border-white rounded-full pointer-events-auto">
            NOUS CONTACTER
          </button> */}
          <Button
            text="Contactez-nous"
            color="black"
            textColor="white"
            onClick={toggleContactModal}
          />
        </BoxReveal>
      </div>
    </div>
  );
}

export default RegulationContact;
