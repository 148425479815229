
import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";
import { Tip } from "../../models/Tip";
import { Post } from "../../models/Post";

function UsefulTipsSection() {
  const [tips, setTips] = useState<Tip[]>([]);
  const [post, setPost] = useState<Post>();

  useEffect(() => {
    const fetchTips = async () => {
      try {
        const response = await axios.get<Tip[]>(`${API_BASE_URL}/conseilsutiles`);
        const visibleTips = response.data.filter((tip) => tip.visible === 1);
        setTips(visibleTips);
      } catch (error) {
        console.error("Error fetching useful tips:", error);
      }
    };

    const fetchPosts = async () => {
      try {
        const response = await axios.get<Post>(`${API_BASE_URL}/posts/12`);
        // const visiblePosts = response.data.filter((post) => post.visible === 1);
        setPost(response.data);
      } catch (error) {
        console.error("Error fetching useful tips:", error);
      }
    };

    fetchPosts();
    fetchTips();
  }, []);

  return (
    <section className="bg-black py-0 lg:px-[16%] text-white text-left bg-pattern">
      <div className="bg-black py-8 lg:py-16 lg:px-[6%]">
        <h1 className="text-2xl font-bold">{post?.title}</h1>
        <p className="text-base mt-2 mb-6" dangerouslySetInnerHTML={{ __html: post?.body || "" }} />


        <div className="grid lg:grid-cols-3 gap-4 md:gap-6">
          {tips.map((tip, index) => (
            <div
              key={tip.id}
              className="bg-background text-center group relative"
              style={{ backgroundColor: tip.color }}
            >
              <div className="relative overflow-hidden">
                <img
                  src={`${STORAGE_BASE_URL}/${tip.image_url}`}
                  alt={tip.title}
                  className="w-full object-cover"
                />
                <div
                  className={`absolute inset-0 opacity-0 group-hover:opacity-50 transition-opacity duration-300`}
                  style={{ backgroundColor: tip.color }}
                ></div>
              </div>
              <p className="text-sm p-4 text-black text-left font-bold">
                {tip.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default UsefulTipsSection;