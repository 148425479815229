
import { useEffect, useRef, useState } from "react";
import { Navigation, A11y, Autoplay, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { Engagement } from "../../models/Engagement";
import axios from "axios";
import { API_BASE_URL } from "../../const";
import { Swiper as SwiperType } from "swiper";

function Engagements() {
  const [engagements, setEngagements] = useState<Engagement[]>([]);
  const swiperRef = useRef<SwiperType | null>(null);

  useEffect(() => {
    const fetchEngagements = async () => {
      try {
        const response = await axios.get<Engagement[]>(`${API_BASE_URL}/engagements`);
        const data = response.data;
        const visibleEngagements = data.filter(engagement => engagement.visibility === 1);
        setEngagements(visibleEngagements);
      } catch (error) {
        console.error("Error fetching engagements:", error);
      }
    };

    fetchEngagements();
  }, []);

  useEffect(() => {
    if (swiperRef.current && engagements.length > 0) {
      swiperRef.current.autoplay.start();
      swiperRef.current.update();
    }
  }, [engagements]);

  return (
    <section className="bg-black py-0 pr-0 sm:px-[5%] md:px-[15%] 2xl:px-[15%] bg-pattern w-full">
      <div className="relative md:bg-black py-12 sm:py-16">
        <div className="h-full w-full"></div>
        <h1 className="text-2xl text-white font-meduim hidden sm:block ml-8 md:ml-[3%] 2xl:ml-[7%]">
          NOS ENGAGEMENTS
        </h1>
        <div className="flex justify-center mx-16">
          <div className="flex flex-col items-center justify-center p-2">
            <button className="rounded-full hidden lg:block custom-prev transition bg-[#675a5a] hover:bg-primary text-white p-3">
              <FaChevronLeft />
            </button>
          </div>

          <Swiper
            onSwiper={(swiper: SwiperType) => {
              swiperRef.current = swiper;
              if (engagements.length > 0) {
                swiper.autoplay.start();
              }
            }}
            scrollbar={{ draggable: true }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            pagination={{ clickable: true }}
            modules={[Navigation, A11y, Autoplay, Scrollbar]}
            slidesPerView={1}
            navigation={{
              nextEl: ".custom-next",
              prevEl: ".custom-prev",
            }}
            loop={engagements?.length > 1}
            className="h-[50vh] min-w-full overscroll-contain"
            breakpoints={{
              320: {
                slidesPerView: 1.2,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: 1.8,
                spaceBetween: 5,
              },
              1350: {
                slidesPerView: 2.2,
                spaceBetween: 5,
              },
              1536: {
                slidesPerView: 3.2,
                spaceBetween: 5,
              },
            }}
          >
            {engagements.map((engagement) => (
              <SwiperSlide key={engagement.id} className="text-black w-[100%] ">
                <div
                  className='rounded-xl mx-2 my-0 md:my-4 p-4 2xl:p-7 h-[95%] md:h-[90%] sm:[80%] overflow-y-scroll'
                  style={{
                    backgroundColor: engagement.color, msOverflowStyle: "none",
                    scrollbarWidth: "none",
                  }}
                >
                  {/* <h2 className="text-sm sm:text-sm lg:text-sm 2xl:text-base font-extrabold">
                    {engagement.title}
                  </h2> */}
                  <div className="justify-between mt-6  ">
                    <div className="my-2 ">
                      <h3 className="text-sm md:text-base 2xl:text-base font-bold">
                        {engagement.title}
                      </h3>
                      <p
                        className="mt-2 text-xs md:text-sm"
                        dangerouslySetInnerHTML={{ __html: engagement.description }}
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="flex flex-col items-center justify-center p-2">
            <button className="rounded-full hidden lg:block custom-next transition bg-[#675a5a] hover:bg-primary text-white p-3">
              <FaChevronRight />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Engagements;