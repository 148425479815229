import React, { useEffect, useState } from "react";
import obconnect from "../../assets/connect.png";
import { API_BASE_URL } from "../../const";
import { useDeviceSize } from "react-device-sizes";

interface Reclamation {
  id: number;
  title: string;
  body: string;
  visible: number;
  number: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

const SVGRenderer = ({ listItems }: { listItems: string[] }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="auto" viewBox="0 0 248 135">
      {listItems.map((item, index) => (
        <g key={index}>
          <circle cx="10" cy={25 + index * 25} r="3" fill="white" />
          {index < listItems.length - 1 && (
            <line
              x1="10"
              y1={25 + index * 25}
              x2="10"
              y2={25 + (index + 1) * 25}
              stroke="#fff"
              strokeWidth="0.5"
              strokeDasharray="3"
            />
          )}
          <text
            x="20"
            y={30 + index * 25}
            fill="white"
            fontSize="14"
            fontFamily="Arial, sans-serif"
          >
            {item}
          </text>
        </g>
      ))}
    </svg>
  );
};


const ObConnectClaimSection: React.FC = () => {
  const [reclamations, setReclamations] = useState<Reclamation[]>([]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/reclamations`)
      .then((response) => response.json())
      .then((data) => {
        // Filter visible reclamations
        const visibleReclamations = data.filter((reclamation: Reclamation) => reclamation.visible === 1);
        setReclamations(visibleReclamations);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const parseListItems = (body: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(body, "text/html");
    return Array.from(doc.querySelectorAll("li")).map((li) => li.textContent || "");
  };

  // Swap reclamation numbers 2 and 3
  const swapNumbers = (reclamations: Reclamation[]) => {
    const sorted = [...reclamations].sort((a, b) => a.number - b.number);
    const index2 = sorted.findIndex(r => r.number === 2);
    const index3 = sorted.findIndex(r => r.number === 3);

    if (index2 !== -1 && index3 !== -1) {
      const temp = sorted[index2];
      sorted[index2] = sorted[index3];
      sorted[index3] = temp;
    }

    return sorted;
  };

  const sortedReclamations = swapNumbers(reclamations);
  const { xsDown } = useDeviceSize();
  const listItemsMobile = parseListItems(sortedReclamations[1]?.body);

  return (
    <section className="bg-primary text-white text-left py-[5%]">
      <div className="lg:flex items-center justify-center">
        <img src={obconnect} alt="" className="lg:max-w-[40%] h-auto hidden md:block" />
        <div className="ml-4 lg:ml-8">
          <h1 className="font-bold text-center md:text-left text-2xl mb-8 md:mb-2">RECLAMATION</h1>
          <img src={obconnect} alt="" className="lg:max-w-[40%] h-auto block md:hidden" />
          {xsDown ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            
              <div className="grid mx-8 md:mx-0 lg:grid-cols-2 gap-8">
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col md:flex-row gap-4 items-start">
                    <div className="bg-white rounded-full text-center font-bold flex items-center justify-center w-10 h-10">
                      <h1 className="text-primary text-xl">{sortedReclamations[0]?.number}</h1>
                    </div>
                    <div className="text-sm w-[80%]">
                      <h2 className="font-bold">{sortedReclamations[0]?.title}</h2>
                      <div dangerouslySetInnerHTML={{ __html: sortedReclamations[0]?.body }} />

                    </div>
                  </div>
                  <hr className="-mx-6 md:mx-0 block md:hidden" />

                  <div className="flex flex-col md:flex-row gap-4 items-start">
                    <div className="bg-white rounded-full text-center font-bold flex items-center justify-center w-10 h-10">
                      <h1 className="text-primary text-xl">{sortedReclamations[2]?.number}</h1>
                    </div>
                    <h2 className="font-bold">{sortedReclamations[2]?.title}</h2>
                    <div className="text-sm " dangerouslySetInnerHTML={{ __html: sortedReclamations[2]?.body }} />
                  </div>

                  <hr className=" -mx-6 md:mx-0 block md:hidden" />

                  <div className="flex flex-col md:flex-row items-start gap-4 md:hidden">
                    <div className="bg-white rounded-full text-center font-bold flex items-center justify-center w-10 h-10">
                      <h1 className="text-primary text-xl">{sortedReclamations[1]?.number}</h1>
                    </div>
                    <h2 className="text-sm font-bold">{sortedReclamations[1]?.title}</h2>

                    <div className="text-sm ">
                      <SVGRenderer listItems={listItemsMobile} /></div>
                  </div>
                </div>


              </div>

            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {sortedReclamations.map((reclamation) => {
                const listItems = parseListItems(reclamation.body);
                return (
                  <div
                    key={reclamation.id}
                    className={`flex flex-row gap-2 ${reclamation.number === 3 ? 'row-span-2' : ''}`}
                  >
                    <div className="flex flex-col md:flex-row gap-4 items-start">
                      <div className="bg-white rounded-full text-center font-bold flex items-center justify-center w-10 h-10">
                        <h1 className="text-primary text-xl">{reclamation.number}</h1>
                      </div>
                      <div className="text-sm w-[80%]">
                        <h2 className="font-bold">{reclamation.title}</h2>
                        <p className="text-xs 2xl:text-sm mt-1 mb-4">
                          {reclamation.number === 3 ? (
                            <SVGRenderer listItems={listItems} />
                          ) : (
                            <div dangerouslySetInnerHTML={{ __html: reclamation.body }} />
                          )}
                        </p>
                      </div>
                    </div>
                    {reclamation.number !== 3 && <hr className="-mx-6 md:mx-0" />}
                  </div>
                );
              })}
            </div>)}

        </div>
      </div>
    </section >
  );
};

export default ObConnectClaimSection;