import React from "react";

interface OfferProps {
  image: string;
  title: string;
  textColor?: string;
}

const Offer: React.FC<OfferProps> = ({ image, title, textColor }) => {
  return (
    <div className="flex flex-col items-center" style={{ color: textColor }}>
      <img src={image} alt={title}  className="w-auto h-auto mb-6 rounded-md" />
      <h2 className="text-base md:text-lg lg:text-xl font-bold text-center">
        {title}
      </h2>
    </div>
  );
};

export default Offer;
