import {A11y, Autoplay, Navigation, Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import {Swiper as SwiperType} from "swiper"; // Import Swiper type
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import HeroTitle from "./HeroTitle";
import {useDeviceSize} from "react-device-sizes";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {API_BASE_URL, STORAGE_BASE_URL} from "../../const";
import {Slider} from "../../models/Slider";

function Hero() {
  const { xsDown } = useDeviceSize();
  const [sliders, setSliders] = useState<Slider[]>([]);
  const swiperRef = useRef<SwiperType | null>(null);

  useEffect(() => {
    axios
        .get(`${API_BASE_URL}/sliders`)
        .then((response) => {
          setSliders(response.data);
        })
        .catch((error) => {
          console.error("Error fetching sliders:", error);
        });
  }, []);

  useEffect(() => {
    if (!xsDown) {
      if (swiperRef.current && sliders.length > 0) {
        swiperRef.current.autoplay.start(); // Start autoplay manually
        swiperRef.current.update(); // Update Swiper
      }
    }
  }, [sliders]);

  return (
      <div className="sm:h-[80vh] w-full relative">
        {xsDown ? (
                <>
                  {sliders
                      .filter((slider) => slider.is_mobile)
                      .map((slider) => (
                          <div key={slider.id}>
                            <img
                                className="object-cover w-full"
                                src={`${STORAGE_BASE_URL}/${slider?.urlImage}`}
                                alt="hero-mobile"
                            />
                            <HeroTitle title={slider?.title} subTitle={slider?.subTitle}/>
                          </div>
                      ))}
                </>
            )
            :
            (
                <Swiper
                    onSwiper={(swiper: SwiperType) => {
                      swiperRef.current = swiper;
            if (sliders.length > 0) {
              swiper.autoplay.start(); // Start autoplay on initial load if sliders exist
            }
                    }}
                    autoplay={{delay: 3000, disableOnInteraction: false}}
                    pagination={{clickable: true}}
                    modules={[Navigation, Pagination, A11y, Autoplay]}
                    slidesPerView={1}
                    navigation
                    loop={sliders?.length > 1}
                    className="h-[80vh] w-screen min-w-full"
                >
                  {sliders.filter((slider) => !slider.is_mobile).map((slider) =>
                      xsDown && slider.is_mobile ? (  // Check mobile attribute
                          <SwiperSlide key={slider.id}>
                            <img
                                className="object-fill h-[80vh] w-full"
                                src={`${STORAGE_BASE_URL}/${slider.urlImage}`}  // Use mobile image
                                alt={slider.title}
                            />
                            <HeroTitle title={slider.title} subTitle={slider.subTitle}/>
                          </SwiperSlide>
                      ) : (
                          <SwiperSlide key={slider.id}>
                            <img
                                className="object-fill h-[80vh] w-full"
                                src={`${STORAGE_BASE_URL}/${slider.urlImage}`}  // Use desktop image
                                alt={slider.title}
                            />
                            <HeroTitle title={slider.title} subTitle={slider.subTitle}/>
                          </SwiperSlide>
                      )
                  )}
                </Swiper>
            )}
      </div>
  );
}

export default Hero;
