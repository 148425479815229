import React from "react";

import OpportunitiesSection from "../components/recruitment/OpportunitiesSection";
import WhyWorkHere from "../components/recruitment/WhyWorkHere";
import ProcessSection from "../components/recruitment/ProcessSection";
import ContactUsSection from "../components/contact/ContactUsSection";
import { useEffect, useState } from "react";
import { API_BASE_URL, STORAGE_BASE_URL } from "../const";
import axios from "axios";
import HeroTemplate from "../components/common/HeroTemplate";


function Recrutement() {
  const [heroData, setHeroData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/heroes/4`);
        if (response.data.visiblity === 1) {
          setHeroData(response.data);
        } else {
          setError('Hero data is not visible.');
        }
      } catch (err) {
        setError('Failed to fetch hero data.');
      } finally {
        setLoading(false);
      }
    };

    fetchHeroData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (!heroData) return null;
  return (
    <div>

      <HeroTemplate
        title={heroData.title}
        subtitle={heroData.description.replace(/<\/?div>/g, '')}
        background={`${STORAGE_BASE_URL}/${heroData.imageurl}`}
        color="#c4b380"
        titleClass="w-[70vw] md:max-w-[50%]"
      />
      <OpportunitiesSection />
      <WhyWorkHere />
      <ProcessSection />
      <ContactUsSection />
    </div>
  );
}

export default Recrutement;
