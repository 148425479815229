import HeroTemplateTitle from "./HeroTemplateTitle";

interface HeroTemplateProps {
  background: string;
  color?: string;
  title?: string;
  subtitle?: string;
  titleClass?: string;
}

const HeroTemplate: React.FC<HeroTemplateProps> = ({ background, color, title, subtitle, titleClass }) => {
  return (
    <div className="min-h-[70vh] w-full relative">
      <img className="object-cover h-[70vh] w-full" src={background} alt="" />
      <HeroTemplateTitle color={color} title={title} text={subtitle} titleClass={titleClass} />
    </div>
  );
};

export default HeroTemplate;
