import React from "react";
import JoinUsSection from "../components/about/JoinUsSection";
import AdvantagesSection from "../components/advantages/AdvantagesSection";
import BetterUnderStandSection from "../components/real-estate/BetterUnderStandSection";
import Offers from "../components/offers/Offers";

import CustomOffersSection from "../components/custom-offers/CustomOffers";

import { useEffect, useState } from "react";
import { API_BASE_URL, STORAGE_BASE_URL } from "../const";
import axios from "axios";
import HeroTemplate from "../components/common/HeroTemplate";




function RealEstateFinancialIntermediary() {
  const [heroData, setHeroData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/heroes/14`);
        if (response.data.visiblity === 1) {
          setHeroData(response.data);
        } else {
          setError('Hero data is not visible.');
        }
      } catch (err) {
        setError('Failed to fetch hero data.');
      } finally {
        setLoading(false);
      }
    };

    fetchHeroData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (!heroData) return null;
  return (
    <div>
      <HeroTemplate
        title={heroData.title}
        subtitle={heroData.description.replace(/<\/?div>/g, '')}
        background={`${STORAGE_BASE_URL}/${heroData.imageurl}`}
        color="#a1c7cf"
      // titleClass="lg:max-w-[40%] 2xl:max-w-[20%]"
      />
      <CustomOffersSection />
      <Offers color="black" />
      <BetterUnderStandSection />
      <AdvantagesSection />
      <JoinUsSection />
    </div>
  );
}

export default RealEstateFinancialIntermediary;
