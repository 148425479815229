import React from "react";

interface TestimonialProps {
  image: string;
  name: string;
  position: string;
  quote: string;
  hashtags: string;
  rating: number; // Assuming rating is out of 5
}

const Testimonial: React.FC<TestimonialProps> = ({
  image,
  name,
  position,
  quote,
  hashtags,
  rating,
}) => {
  const renderStars = (rating: number) => {
    const totalStars = 5; // Assuming a 5-star rating system
    return Array.from({ length: totalStars }, (_, i) => (
      <span key={i} className="text-primary text-3xl">
        {i < rating ? "★" : "☆"}
      </span>
    ));
  };

  return (
    <div className="block md:flex items-center text-center md:text-left rounded-lg my-4">
      <img
        src={image}
        alt={name}
        className="w-[30%] md:w-[10%] h-auto rounded-full mx-auto my-4 md:m-0 md:mr-4"
      />
      <div className="block md:flex md:flex-col">
        <div className="block md:flex items-center justify-between">
          <h2 className="text-base sm:text-lg">
            <span className="font-bold block sm:inline">{name}</span>{" "}
            <div className="hidden sm:inline">-</div> {position}
          </h2>
          <div className="flex items-center w-min mx-auto md:w-auto md:mx-0">
            {renderStars(rating)}
          </div>
        </div>
        {/* <p className="mt-2 text-xs sm:text-base lg:text-lg text-gray-700 md:w-2/3 italic font-semibold">
          {quote} 
        </p> */}
        <div className="mt-2 text-xs sm:text-base lg:text-lg text-gray-700 md:w-2/3 italic font-semibold" dangerouslySetInnerHTML={{ __html: quote }} />
        <p className="mt-2 text-xs sm:text-base text-primary">{hashtags}</p>
      </div>
    </div>
  );
};

export default Testimonial;
