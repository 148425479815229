import HeroTemplate from "../components/common/HeroTemplate";
import LatestNewsSection from "../components/news/LatestNewsSection";
import NewsEventsSection from "../components/news/NewsEventsSection";
import StayConnectedSectionPropsNews from "../components/news/StayConnectedSectionNews";
import PressReleasesSection from "../components/news/PressReleasesSection";
import { useEffect, useState } from "react";
import { API_BASE_URL, STORAGE_BASE_URL } from "../const";
import axios from "axios";

function News() {
  const [heroData, setHeroData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/heroes/1`);
        if (response.data.visiblity === 1) {
          setHeroData(response.data);
        } else {
          setError('Hero data is not visible.');
        }
      } catch (err) {
        setError('Failed to fetch hero data.');
      } finally {
        setLoading(false);
      }
    };

    fetchHeroData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (!heroData) return null;
  return (
    <div>
       <HeroTemplate
      title={heroData.title}
      subtitle={heroData.description.replace(/<\/?div>/g, '')} 
      background={`${STORAGE_BASE_URL}/${heroData.imageurl}`}
      // color="#f1c5a8"
      titleClass="lg:max-w-[40%] 2xl:max-w-[20%]"
    />
      <LatestNewsSection />
      <PressReleasesSection />
      <NewsEventsSection />
      <StayConnectedSectionPropsNews />
    </div>
  );
}

export default News;
