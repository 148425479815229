import React, { useState, useEffect }  from "react";
import HeroTemplate from "../components/common/HeroTemplate";
import axios from "axios";
import { API_BASE_URL, STORAGE_BASE_URL } from "../const";
import { Hero } from "../models/Hero";

export interface TermsAndConditions {
  id: number;
  author_id: number;
  title: string;
  excerpt: string | null;
  body: string;
}

function Legal() {
  const [conditions, setConditions] = useState<TermsAndConditions>();
  const [isLoading, setIsLoading] = useState(true);
  const [heroes, setHeroes] = useState<Hero>();

  // Fetch data from API
  useEffect(() => {
    axios.get<any[]>(`${API_BASE_URL}/pages/4`)
      .then(response => {
        // const visibleItems = response.data.filter(item => item.status == "ACTIVE");
        const visibleItems: any = response.data;

        setConditions(visibleItems);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

      const fetchHeroes = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/heroes/11`);
          const data: Hero = await response.json();
          // Filter heroes based on visibility
          const visibleHeroes = data;
          setHeroes(visibleHeroes);
        } catch (error) {
          console.error("Error fetching heroes:", error);
        }
      };
      fetchHeroes();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      {/* <HeroTemplate background={legal} title="MENTIONS LEGALES" /> */}
      <HeroTemplate
        background={`${STORAGE_BASE_URL}/${heroes?.imageurl}`}
        title={heroes?.title}
      />
      <section>
        <div className="text-left text-base conditions w-full">
          {/* <h1 className="text-primary font-bold mb-4">{conditions?.title}</h1> */}
          {/* <p className="mb-6">{conditions?.body}</p> */}
          <div
            className="text-xs md:text-base mt-3 mb-2 sm:mb-8"
            dangerouslySetInnerHTML={{ __html: conditions?.body || '' }}
          />
        </div>
      </section>
      </div>
  );
}

export default Legal;
