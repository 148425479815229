import React, { useEffect, useState } from "react";
import axios from "axios";
import PrincipleCard from "./PrincipleCard";
import { Principle } from "../../models/Principle";
import { API_BASE_URL } from "../../const";



function PrinciplesSection() {
  const [principles, setPrinciples] = useState<Principle[]>([]);

  useEffect(() => {
    const fetchPrinciples = async () => {
      try {
        const response = await axios.get<Principle[]>(`${API_BASE_URL}/principereglements`);
        const visiblePrinciples = response.data.filter((principle) => principle.visibility === 1);
        setPrinciples(visiblePrinciples);
      } catch (error) {
        console.error("Error fetching principles:", error);
      }
    };

    fetchPrinciples();
  }, []);

  return (
    <section className="bg-black px-[3%] md:px-[10%] 2xl:px-[15%] py-0 bg-pattern">
      <div className="bg-black py-8 md:py-16 md:px-[5%] lg:px-[10%]">
        <h1 className="text-white md:text-left mb-10">PRINCIPALES REGLEMENTATIONS</h1>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {principles.map((principle) => (
            <PrincipleCard
              key={principle.id}
              description={principle.text} 
              title="" 
              color={principle.color}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default PrinciplesSection;
