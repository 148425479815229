// src/components/InsuranceTab.tsx
import React from "react";

interface InsuranceTabProps {
  columns: {
    title: string;
    description: string[];
  }[];
  color: string;
}

const InsuranceTab: React.FC<InsuranceTabProps> = ({ columns, color }) => {
  return (
    <div className="transition">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 text-center transition">
        {columns.map((column, index) => (
          <div key={index} className="bg-white">
            <h3
              className="text-lg font-bold p-4 text-white uppercase"
              style={{ backgroundColor: color }}
            >
              {column.title}
            </h3>
            <div className="p-8">
              <ul
                className={` text-base ${
                  column.description.length > 1 ? "list-disc" : ""
                } ${index === 0 ? "text-center" : "text-left"}`}
              >
                {column.description.map((desc, index) => (
                  <li key={index}>{desc}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InsuranceTab;
