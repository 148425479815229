import axios from "axios";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";
import { Post } from "../../models/Post";
import React, { useEffect, useState } from "react";

function JoinUsSection() {
  const [post, setPost] = useState<Post | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch post data
    axios
      .get(`${API_BASE_URL}/posts/26`)
      .then((response) => {
        if (response.data.status === "PUBLISHED") {
          setPost(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching post data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return (
    <div className="px-[5%] md:px-[20%] py-16 bg-[#fffdf8]">
      <h1 className="text-lg md:text-2xl text-black text-center md:text-left">
        PROFESSIONNELS DE L'ASSURANCE, REJOIGNEZ-NOUS !
      </h1>
      <div className="block md:flex text-center md:text-left mt-8 gap-x-6">
        <img
          src={`${STORAGE_BASE_URL}/${post?.image}`}
          alt=""
          className="w-[30%] md:w-[12%] h-[30%] md:h-[12%] mx-auto md:mx-0 my-4 md:my-0"
        />
        <div className="md:w-[50%]">
          <h1 className="text-base sm:text-xl text-black font-extrabold">
            {post?.title}
          </h1>
          {/* <p className="text-xs sm:text-base text-[#867b78] mt-4 font-semibold">
            Nos equipes vous repondent et peuvent vous accompagner avec l'aide
            de votre courtier pour trouver des solutions d'assurance.
          </p> */}
          <div className="text-xs sm:text-base text-[#867b78] mt-4 font-semibold" dangerouslySetInnerHTML={{ __html: post?.body || '' }} />
        </div>
        <div className="flex justify-center">
          <a
            href="https://extranet.oceanbridges.fr/"
            className="mt-[5%] block bg-transparent break-keep  text-primary font-extrabold text-base 2xl:text-lg transition duration-300 hover:text-white hover:bg-primary py-2 px-4 border border-primary hover:border-transparent rounded-full h-min"
          >
            DEVENIR PARTENAIRE
          </a>
        </div>
      </div>
    </div>
  );
}

export default JoinUsSection;
