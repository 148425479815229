import React from "react";
import HeroTemplate from "../components/common/HeroTemplate";

import RSEengagements from "../components/responsability/RSEengagements";
import ImpactSection from "../components/responsability/ImpactSection";
import { useEffect, useState } from "react";
import { API_BASE_URL, STORAGE_BASE_URL } from "../const";
import axios from "axios";
// import HeroTemplate from "../components/common/HeroTemplate";



function Responsability() {

  const [heroData, setHeroData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/heroes/16`);
        if (response.data.visiblity === 1) {
          setHeroData(response.data);
        } else {
          setError('Hero data is not visible.');
        }
      } catch (err) {
        setError('Failed to fetch hero data.');
      } finally {
        setLoading(false);
      }
    };

    fetchHeroData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (!heroData) return null;

  return (
    <div>
      {/* <HeroTemplate
        title="Responsabilité Sociétale
de l'Entreprise"
        subtitle="Chez Ocean Bridges, nous sommes profondément engagés
dans une démarche de responsabilité sociétale
qui reflète nos valeurs fondamentales de respect,
d'intégrité et de durabilité. Notre objectif est
de mener nos activités de manière
éthique tout en contribuant
positivement à la société
et à l'environnement."
        background={background}
        titleClass="max-w-[40%] 2xl:max-w-[30%]"
        color="#acfdcd"
      /> */}
       <HeroTemplate
      title={heroData.title}
      subtitle={heroData.description.replace(/<\/?div>/g, '')} 
      background={`${STORAGE_BASE_URL}/${heroData.imageurl}`}
      color="#acfdcd"
      titleClass="max-w-[40%] 2xl:max-w-[30%]"
    />
      <RSEengagements />
      <ImpactSection />
    </div>
  );
}

export default Responsability;
