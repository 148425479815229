import React, { useEffect, useState } from "react";
import axios from "axios";
import StepsCard from "../common/StepsCard";
import { API_BASE_URL } from "../../const";
import { EtapeSinistre } from "../../models/EtapeSinistre"; // Assuming you have this model defined
import { Post } from "../../models/Post";

function HowToDeclare() {
  const [steps, setSteps] = useState<EtapeSinistre[]>([]);
  const [post, setPost] = useState<Post>();

  useEffect(() => {
    const fetchSteps = async () => {
      try {
        const response = await axios.get<EtapeSinistre[]>(`${API_BASE_URL}/etapesinistres`);
        const visibleSteps = response.data.filter(step => step.visible === 1);
        setSteps(visibleSteps);
      } catch (error) {
        console.error("Error fetching steps:", error);
      }
    };
    const fetchPosts = async () => {
      try {
        const response = await axios.get<Post>(`${API_BASE_URL}/posts/12`);
        // const visiblePosts = response.data.filter((post) => post.visible === 1);
        setPost(response.data);
      } catch (error) {
        console.error("Error fetching useful tips:", error);
      }
    };

    fetchPosts();

    fetchSteps();
  }, []);

  return (
    <section className="bg-background">

      <h1 className="text-left text-xl font-extrabold">{post?.title}</h1>
      <h2 className="text-lg text-left mb-6 mt-2" dangerouslySetInnerHTML={{ __html: post?.body || "" }} />
      <div className="grid xl:grid-cols-3 gap-4">
        {steps.map((step, index) => (
          <StepsCard
            key={index}
            step={step.etape}
            title={step.title}
            items={[
              {
                title: step.title,
                description: step.description,
              },
            ]}
            stepBgColor={step.color}
            titleBgColor={hexToRgba(step.color, 0.5)} // Adjust alpha as needed
          />
        ))}
      </div>
    </section>
  );
}

// Helper function to convert hex color to RGBA
function hexToRgba(hex: string, alpha: number = 1): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export default HowToDeclare;

