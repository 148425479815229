import Connect from "../../assets/connect.png";
import Button from "../Button";
import axios from "axios";
import {API_BASE_URL} from "../../const";
import {Post} from "../../models/Post";
import React, {useEffect, useState} from "react";


interface ObConnectSectionProps {
  title?: string;
  subtitle?: string;
  description?: string;
  buttonText?: string;
  backgroundColor?: string;
}

function ObConnectSection({
  title,
  subtitle,
  description,
  buttonText,
  backgroundColor,
}: ObConnectSectionProps) {
  const [post, setPost] = useState<Post | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch post data
    axios
      .get(`${API_BASE_URL}/posts/25`)
      .then((response) => {
        if (response.data.status === "PUBLISHED") {
          setPost(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching post data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <section
      className={`block md:flex bg-${backgroundColor ?? "[#e5e1d3]"
        } text-center md:text-left items-center gap-x-16`}
    >
      <img
        src={Connect}
        alt=""
        className="w-[60%] mx-auto md:w-[40%] mb-6 md:mb-0"
      />
      <div className="">
        <h1 className="text-base md:text-lg xl:text-2xl font-extrabold">
          {post?.title ?? "OB CONNECT"}
        </h1>
        <div className="text-[.7rem] md:text-base mt-2 mb-10 md:my-4 " dangerouslySetInnerHTML={{ __html: post?.body || '' }} />
        <div className="w-auto md:block flex justify-center">
          {/*<Button*/}
          {/*  text={buttonText ?? "EN SAVOIR +"}*/}
          {/*  color="black"*/}
          {/*  textColor="white"*/}
          {/*  link="/ob-connect"*/}
          {/*/>*/}
          <Button
              link="/ob-connect"
              text={buttonText ?? "EN SAVOIR +"}
              color="black"
              textColor="white"
              size="md"
              sizeSm="[.6rem]"
              width="100"
          />
        </div>
      </div>
    </section>
  );
}

export default ObConnectSection;
