import React, { useState } from "react";
import Chevron from "../../assets/icons/chevron-up.svg";
import { motion } from "framer-motion";

interface QuestionProps {
  question: string;
  answer: string;
  color: string;
  bgColor: string;
  index: number;
}

const Question: React.FC<QuestionProps> = ({
  question,
  answer,
  color,
  bgColor,
  index,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-background text-left">
      <div className="">
        <div
          className="flex items-center gap-4 pr-2 lg:pr-6 cursor-pointer w-full"
          style={{ backgroundColor: bgColor }}
          onClick={handleToggle}
        >
          <div
            style={{ backgroundColor: color }}
            className="p-4 items-center flex justify-center w-[25%] sm:w-[20%]"
          >
            <h1 className="aspect-square text-6xl font-bold text-white">
              {index}
            </h1>
          </div>
          <div className="flex justify-between w-full gap-2 items-center">
            <h3 className="text-sm lg:text-base 2xl:text-lg font-semibold flex-[6]">
              {question}
            </h3>
            <button
              className="rounded-full aspect-square flex-1 h-min"
              style={{ backgroundColor: color }}
            >
              <img
                src={Chevron}
                alt="chevron"
                className={`m-auto w-16 transition-transform duration-300 ${isOpen ? "" : "rotate-180"
                  }`}
              />
            </button>
          </div>
        </div>
      </div>
      <motion.div
        initial={false}
        animate={{ height: isOpen ? "auto" : 0 }}
        transition={{ duration: 0.3 }}
        style={{ overflow: "hidden" }}
      >
        <div className="p-2 pb-12 md:pb-0 lg:p-8 bg-[#f7f4ea]">
          <p
            className="text-xs lg:text-sm 2xl:text-lg text-secondary"
            dangerouslySetInnerHTML={{ __html: answer }}
          />
        </div>
      </motion.div>
    </div>
  );
};

export default Question;
