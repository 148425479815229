import React from "react";

interface StepsCardProps {
  step?: number;
  title: string;
  items: { title?: string; description?: string }[];
  icon?: string;
  stepBgColor: string;
  titleBgColor: string;
}

const StepsCard: React.FC<StepsCardProps> = ({
  step,
  title,
  items,
  stepBgColor,
  titleBgColor,
}) => {
  return (
    <div className="bg-[#f7f4ea] text-left">
      <div className="flex lg:h-[30%] 2xl:h-[30%] w-full">
        <div
          style={{ backgroundColor: stepBgColor }}
          className="p-6 text-center flex flex-col justify-center"
        >
          <h2 className="text-white font-bold text-base 2xl:text-lg uppercase">
            étape
          </h2>
          <h1 className="text-white font-bold text-3xl 2xl:text-5xl">
            0{step}
          </h1>
        </div>
        <div
          className="py-4 pl-4 flex flex-col justify-center w-full"
          style={{ backgroundColor: titleBgColor }}
        >
          <h3 className="text-sm 2xl:text-xl font-semibold">{title}</h3>
        </div>
      </div>
      <div className="p-4">
        {items.map((item, index) => (
          <div key={index} className="mb-4">
            <p
              className="text-xs lg:text-sm 2xl:text-lg text-secondary"
              dangerouslySetInnerHTML={{ __html: item?.description || "" }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default StepsCard;
