import React from "react";
import { BoxReveal } from "../box-reveal";

interface HeroTemplateTitleProps {
  color?: string;
  title?: string;
  text?: string;
  titleClass?: string;
}

function HeroTemplateTitle({
  color,
  title,
  text,
  titleClass,
}: HeroTemplateTitleProps) {
  return (
    <div
      className={`h-full w-[90%] ${titleClass ?? "md:max-w-[35%]" 
        } items-center justify-center overflow-hidden absolute inset-x-[2%] inset-y-[10%] md:inset-x-[20%] md:inset-y-[18%] z-10 pointer-events-none`}
    >
      <BoxReveal duration={0.5}>
        <h1 className="text-white font-bold text-left text-xl lg:text-2xl 2xl:text-4xl">
          {title}
        </h1>
      </BoxReveal>
      <div className="m-8"></div>
      <BoxReveal duration={0.5}>
        <div
          className="text-left text-white text-base 2xl:text-lg"
          dangerouslySetInnerHTML={{ __html: text || '' }}
        />
      </BoxReveal>
    </div>
  );
}

export default HeroTemplateTitle;
