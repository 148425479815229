import React, { useEffect, useState } from "react";
import EventCard, { EventCardProps } from "./EventCard";
import axios from "axios";
import { API_BASE_URL } from "../../const";
import { Post } from "../../models/Post";

function CalendarEventsSection() {
  const [events, setEvents] = useState<EventCardProps[]>([]);
  const [post, setPost] = useState<Post | null>(null);

  useEffect(() => {
    axios.get(`${API_BASE_URL}/calenders`)
      .then(response => {
        const fetchedEvents = response.data.map((event: any) => ({
          date: {
            day: new Date(event.date).getDate().toString().padStart(2, '0'),
            month: new Date(event.date).toLocaleString('default', { month: 'short' }).toUpperCase(),
            year: new Date(event.date).getFullYear().toString(),
            time: event.time ? event.time.slice(0, 5) : "",
          },
          title: event.titleEvent,
          news: event.descriptionEvent,
          dayBgColor: event.color, 
          titleBgColor: hexToRgba(event.color, 0.5),
          location: event.localisation,
        }));
        setEvents(fetchedEvents);
      })
      .catch(error => {
        console.error("Error fetching events:", error);
      });
    axios.get(`${API_BASE_URL}/posts/7`)
      .then(response => {
        const fetchedPost = response.data as Post;
        
        setPost(fetchedPost);
      })
      .catch(error => {
        console.error("Error fetching post:", error);
      });
  }, []);

  return (
    <section className="bg-background text-left">

      <h1 className="font-extrabold text-xl lg:text-2xl">{post?.title}</h1>
      <div className="text-base font-semibold mt-2 mb-6 lg:mb-12" dangerouslySetInnerHTML={{ __html: post?.body || '' }} />
      <div className="grid xl:grid-cols-3 gap-3">
        {events.map((item, index) => (
          <EventCard
            key={index}
            date={item.date}
            title={item.title}
            news={item.news}
            dayBgColor={item.dayBgColor}
            titleBgColor={item.titleBgColor}
            location={item.location}
          />
        ))}
      </div>
    </section>
  );
}

// Helper function to convert hex color to RGBA
function hexToRgba(hex: string, alpha: number): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export default CalendarEventsSection;