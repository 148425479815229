import Slider from "../../components/Slider";

function Confirmation() {
  return (
    <>
      <Slider activeStep={3} />

      <section className="bg-background">
        <div className="text-center p-4 sm:p-6 md:p-8 lg:p-0">
          <h1 className="text-primary text-base font-semibold md:text-lg">
            CONFIRMATION
          </h1>
          <div className="mt-2 px-3 lg:px-16 pt-8 flex items-center justify-between">
            <p className="text-black text-xs text-left sm:text-base">
              <strong>Chère Madame, cher Monsieur,</strong>
              <br />
              <br />
              Nous accusons réception de la documentation complète relative à
              votre réclamation et tenons à vous remercier pour votre
              collaboration dans ce processus. Votre dossier est désormais en
              cours de validation.
              <br />
              <br />
              Nous sommes pleinement engagés à traiter votre réclamation de
              manière diligente et efficace. Une confirmation écrite de
              l’enregistrement de votre réclamation vous a été envoyée par
              courriel. Veuillez conserver cette confirmation, car elle contient
              un numéro de référence unique que vous pourrez utiliser pour le
              suivi de votre dossier.
              <br />
              <br />
              Nous nous efforçons de résoudre toute réclamation dans les
              meilleurs délais. Notre équipe dédiée examinera attentivement les
              informations fournies et vous contactera directement pour vous
              tenir informé(e) des avancements et de la résolution.
              <br />
              Si vous avez d’autres questions ou si vous avez besoin d’une
              assistance supplémentaire en attendant, n’hésitez pas à nous
              contacter via nos canaux de support client.
              <br />
              <br />
              Nous vous remercions de votre patience et de votre confiance en
              Ocean Bridges.
              <br />
              <br />
              <strong>L’équipe de gestion des réclamations</strong>
              <br />
              <strong>Ocean Bridges</strong>
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Confirmation;
