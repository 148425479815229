import React, { useState, useEffect } from "react";
import HeroTemplate from "../components/common/HeroTemplate";
import axios from "axios";
import { API_BASE_URL, STORAGE_BASE_URL } from "../const";
import { Hero } from "../models/Hero";

export interface TermsAndConditions {
  id: number;
  author_id: number;
  title: string;
  excerpt: string | null;
  body: string;
}

function Conditions() {
  // State to store conditions data
  const [conditions, setConditions] = useState<TermsAndConditions>();
  const [isLoading, setIsLoading] = useState(true);

  const [heroes, setHeroes] = useState<Hero>();

  // Fetch data from API
  useEffect(() => {
    axios.get<any[]>(`${API_BASE_URL}/pages/2`)
      .then(response => {
        // const visibleItems = response.data.filter(item => item.status == "ACTIVE");
        const visibleItems: any = response.data;

        setConditions(visibleItems);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

      const fetchHeroes = async () => {
        try {
          const response = await fetch(`${API_BASE_URL}/heroes/13`);
          const data: Hero = await response.json();
          // Filter heroes based on visibility
          const visibleHeroes = data;
          setHeroes(visibleHeroes);
        } catch (error) {
          console.error("Error fetching heroes:", error);
        }
      };
      fetchHeroes();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* <HeroTemplate
        background={background}
        title="CONDITIONS GÉNÉRALES D'UTILISATION DU SITE OCEAN BRIDGES"
        subtitle="Un petit blabla de deux ou trois lignes Serait le bienvenu pour nourrir un peu Cet espace"
        color="#527c7a"
      /> */}
         <HeroTemplate
        background={`${STORAGE_BASE_URL}/${heroes?.imageurl}`}
        title={heroes?.title}
        subtitle={heroes?.description.replace(/<\/?div>/g, '')}
        // color="#527c7a"
      />
      {/* {conditions.map((condition, index) => ( */}
      <div className="text-left text-base conditions w-full">
        {/* <h1 className="text-primary font-bold mb-4">{conditions?.title}</h1> */}
        {/* <p className="mb-6">{conditions?.body}</p> */}
        <div
          className="text-xs md:text-base mt-3 mb-2 sm:mb-8"
          dangerouslySetInnerHTML={{ __html: conditions?.body || '' }}
        />
      </div>
      {/* ))} */}

    </div>
  );
}

export default Conditions;
