import React from "react";

interface AdvantageProps {
  image: string;
  title: string;
  subtitle: string;
}

const Advantage: React.FC<AdvantageProps> = ({ image, title, subtitle }) => {
  return (
    <div className="flex flex-col items-center">
      <img
        src={image}
        alt={title}
        className="sm:w-auto sm:h-auto mb-2 sm:mb-6 rounded-md"
      />
      <h2 className="text-[.6rem] sm:text-xl font-bold text-center">{title}</h2>
      <p className="text-center text-[.6rem] sm:text-base mt-2 sm:mt-4">
        {subtitle}
      </p>
    </div>
  );
};

export default Advantage;
