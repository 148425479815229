import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { FormContext } from "../../context/ReclamaionFormContext";
import Slider from "../../components/Slider";
import upload from "../../assets/icons/upload.svg";
import trash from "../../assets/icons/trash.svg";
import valid from "../../assets/icons/valid.svg";

const validationSchema = Yup.object().shape({
  insured: Yup.string().required(),
  against: Yup.string().required(),
  otherCase: Yup.lazy((_, context) => {
    if (context.parent.insured === "Non" && context.parent.against === "Non") {
      return Yup.string().required("Le champ Autre cas est obligatoire");
    }
    return Yup.string();
  }),
  insuranceProposal: Yup.string().required(),
  contractManagement: Yup.string().required(),
  information: Yup.string().required(),
  claim: Yup.string().required(),
  contract: Yup.string().required(),
  subscription: Yup.string().required(),
  premium: Yup.string().required(),
  personalInfo: Yup.string().required(),
  other: Yup.string().required(),
  otherCases: Yup.lazy((_, context) => {
    if (context.parent.other === "Oui") {
      return Yup.string().required("Le champ Autres cas est obligatoire");
    }
    return Yup.string();
  }),
});

function Identification() {
  const navigate = useNavigate();

  const { formData, setFormData } = useContext(FormContext);

  const formik = useFormik({
    initialValues: {
      insured: formData.insured || "",
      against: formData.against || "",
      otherCase: formData.otherCase || "",
      insuranceProposal: formData.insuranceProposal || "",
      contractManagement: formData.contractManagement || "",
      information: formData.information || "",
      claim: formData.claim || "",
      contract: formData.contract || "",
      subscription: formData.subscription || "",
      premium: formData.premium || "",
      personalInfo: formData.personalInfo || "",
      other: formData.other || "",
      otherCases: formData.otherCases || "",
    },
    validationSchema,
    onSubmit: (values) => {
      setFormData({ ...formData, ...values });
      if (!isNotValid) {
        navigate("/claim/contact");
      }
    },
  });

  const isNotValid =
    [
      formik.values.insuranceProposal,
      formik.values.contractManagement,
      formik.values.information,
      formik.values.claim,
      formik.values.contract,
      formik.values.subscription,
      formik.values.premium,
      formik.values.personalInfo,
      formik.values.other,
    ].every((value) => value !== "") &&
    ![
      formik.values.insuranceProposal,
      formik.values.contractManagement,
      formik.values.information,
      formik.values.claim,
      formik.values.contract,
      formik.values.subscription,
      formik.values.premium,
      formik.values.personalInfo,
      formik.values.other,
    ].includes("Oui");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    const updatedData = {
      [name]: value,
    };

    if (name === "against" && value === "Oui") {
      updatedData.otherCase = "";
    } else if (name === "other" && value === "Non") {
      updatedData.otherCases = "";
    }

    setFormData((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  };

  const [filesUploaded, setFilesUploaded] = useState<boolean[]>([]);

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const files = e.target.files ? Array.from(e.target.files) : [];

    setFilesUploaded((prevFilesUploaded) => {
      const updatedFiles = [...prevFilesUploaded];
      updatedFiles[index] = files.length > 0;
      return updatedFiles;
    });

    setFormData((prevData) => {
      const updatedFiles = [...prevData.files];
      updatedFiles[index] = files.length > 0 ? (files[0] as any) : null;
      return { ...prevData, files: updatedFiles };
    });
  };

  const addFileInput = () => {
    setFormData((prevData: any) => {
      if (prevData.files.length < 3) {
        return {
          ...prevData,
          files: [...prevData.files, [] as File[]],
        };
      }
      return prevData;
    });
  };

  const removeFileInput = (index: number) => {
    setFilesUploaded((prevFilesUploaded) => {
      const updatedFiles = [...prevFilesUploaded];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });

    setFormData((prevData) => {
      if (prevData.files.length > 0) {
        const updatedFiles = prevData.files.filter((_, i) => i !== index);
        return { ...prevData, files: updatedFiles };
      }
      return prevData;
    });
  };

  return (
    <div className="overflow-x-hidden">
      <Slider activeStep={1} />

      <section className="bg-background lg:-mx-20">
        <div className="text-center p-4 sm:p-6 md:p-4 lg:py-2 lg:px-0">
          <h1 className="text-primary text-base font-semibold md:text-lg">
            VOTRE RÉCLAMATION
          </h1>

          <div className="mt-2 px-4 lg:px-16 pt-8 flex items-center justify-between">
            <p className="text-black text-xs text-left max-w-52 lg:max-w-none sm:text-base">
              Vous souhaitez ou avez souhaité être assuré auprès de Ocean
              Bridges ?
            </p>
            <div
              className={`flex gap-2 text-xs lg:text-base ${
                formik.errors.insured && formik.touched.insured
                  ? "text-red"
                  : "text-black"
              }`}
            >
              <input
                type="radio"
                name="insured"
                value="Oui"
                checked={formik.values.insured === "Oui"}
                onChange={formik.handleChange}
              />

              <label>Oui</label>
              <input
                type="radio"
                name="insured"
                value="Non"
                checked={formik.values.insured === "Non"}
                onChange={formik.handleChange}
              />
              <label>Non</label>
            </div>
          </div>

          <div className="mt-2 px-4 lg:px-16 pt-8 flex items-center justify-between">
            <p className="text-black text-xs text-left max-w-52 lg:max-w-none sm:text-base">
              Vous avez eu un sinistre contre un assuré de Ocean Bridges ?
            </p>
            <div
              className={`flex gap-2 text-xs lg:text-base ${
                formik.errors.against && formik.touched.against
                  ? "text-red"
                  : "text-black"
              }`}
            >
              <input
                type="radio"
                name="against"
                value="Oui"
                checked={formik.values.against === "Oui"}
                onChange={formik.handleChange}
              />
              <label>Oui</label>
              <input
                type="radio"
                name="against"
                value="Non"
                checked={formik.values.against === "Non"}
                onChange={formik.handleChange}
              />
              <label>Non</label>
            </div>
          </div>
          {formik.values.insured === "Non" &&
            formik.values.against === "Non" && (
              <div className="mt-2 px-4 lg:px-16 pt-8 flex flex-col gap-2 lg:flex-row lg:items-center lg:justify-between lg:gap-10">
                <p className="text-black text-xs text-left max-w-52 lg:max-w-none sm:text-base">
                  Autre cas
                </p>

                <input
                  type="text"
                  name="otherCase"
                  className={`rounded-full border-2 lg:w-[80%] ${
                    formik.errors.otherCase && formik.touched.otherCase
                      ? "border-red"
                      : "border-accent"
                  }`}
                  value={formik.values.otherCase}
                  onChange={formik.handleChange}
                />
              </div>
            )}
          {formik.errors.otherCase && formik.touched.otherCase && (
            <p className="text-red text-base mt-2">{formik.errors.otherCase}</p>
          )}
          <div className="text-black text-xs text-left mt-2 px-4 lg:px-16 pt-8 sm:text-base">
            Votre réclamation porte sur :
          </div>
          <div className="flex flex-col lg:flex-row lg:justify-between">
            <div className="w-full">
              <div className="mt-2 px-4 lg:px-16 pt-8 flex items-center justify-between">
                <p className="text-black text-xs text-left max-w-52 lg:max-w-none sm:text-base">
                  Une proposition d’assurance
                </p>
                <div
                  className={`flex gap-2 text-xs lg:text-base ${
                    formik.errors.insuranceProposal &&
                    formik.touched.insuranceProposal
                      ? "text-red"
                      : "text-black"
                  }`}
                >
                  <input
                    type="radio"
                    name="insuranceProposal"
                    value="Oui"
                    checked={formik.values.insuranceProposal === "Oui"}
                    onChange={formik.handleChange}
                  />
                  <label>Oui</label>
                  <input
                    type="radio"
                    name="insuranceProposal"
                    value="Non"
                    checked={formik.values.insuranceProposal === "Non"}
                    onChange={formik.handleChange}
                  />
                  <label>Non</label>
                </div>
              </div>
              <div className="mt-2 px-4 lg:px-16 pt-8 flex items-center justify-between">
                <p className="text-black text-xs text-left max-w-52 lg:max-w-none sm:text-base">
                  La gestion d’un contrat
                </p>
                <div
                  className={`flex gap-2 text-xs lg:text-base ${
                    formik.errors.contractManagement &&
                    formik.touched.contractManagement
                      ? "text-red"
                      : "text-black"
                  }`}
                >
                  <input
                    type="radio"
                    name="contractManagement"
                    value="Oui"
                    checked={formik.values.contractManagement === "Oui"}
                    onChange={formik.handleChange}
                  />
                  <label>Oui</label>
                  <input
                    type="radio"
                    name="contractManagement"
                    value="Non"
                    checked={formik.values.contractManagement === "Non"}
                    onChange={formik.handleChange}
                  />
                  <label>Non</label>
                </div>
              </div>
              <div className="mt-2 px-4 lg:px-16 pt-8 flex items-center justify-between">
                <p className="text-black text-xs text-left max-w-52 lg:max-w-none sm:text-base">
                  Un relevé d’information
                </p>
                <div
                  className={`flex gap-2 text-xs lg:text-base ${
                    formik.errors.information && formik.touched.information
                      ? "text-red"
                      : "text-black"
                  }`}
                >
                  <input
                    type="radio"
                    name="information"
                    value="Oui"
                    checked={formik.values.information === "Oui"}
                    onChange={formik.handleChange}
                  />
                  <label>Oui</label>
                  <input
                    type="radio"
                    name="information"
                    value="Non"
                    checked={formik.values.information === "Non"}
                    onChange={formik.handleChange}
                  />
                  <label>Non</label>
                </div>
              </div>
              <div className="mt-2 px-4 lg:px-16 pt-8 flex items-center justify-between">
                <p className="text-black text-xs text-left max-w-52 lg:max-w-none sm:text-base">
                  Un sinistre
                </p>
                <div
                  className={`flex gap-2 text-xs lg:text-base ${
                    formik.errors.claim && formik.touched.claim
                      ? "text-red"
                      : "text-black"
                  }`}
                >
                  <input
                    type="radio"
                    name="claim"
                    value="Oui"
                    checked={formik.values.claim === "Oui"}
                    onChange={formik.handleChange}
                  />
                  <label>Oui</label>
                  <input
                    type="radio"
                    name="claim"
                    value="Non"
                    checked={formik.values.claim === "Non"}
                    onChange={formik.handleChange}
                  />
                  <label>Non</label>
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="mt-2 px-4 lg:px-16 pt-8 flex items-center justify-between">
                <p className="text-black text-xs text-left max-w-52 lg:max-w-none sm:text-base">
                  Un contrat
                </p>
                <div
                  className={`flex gap-2 text-xs lg:text-base ${
                    formik.errors.contract && formik.touched.contract
                      ? "text-red"
                      : "text-black"
                  }`}
                >
                  <input
                    type="radio"
                    name="contract"
                    value="Oui"
                    checked={formik.values.contract === "Oui"}
                    onChange={formik.handleChange}
                  />
                  <label>Oui</label>
                  <input
                    type="radio"
                    name="contract"
                    value="Non"
                    checked={formik.values.contract === "Non"}
                    onChange={formik.handleChange}
                  />
                  <label>Non</label>
                </div>
              </div>
              <div className="mt-2 px-4 lg:px-16 pt-8 flex items-center justify-between">
                <p className="text-black text-xs text-left max-w-52 lg:max-w-none sm:text-base">
                  La souscription d’un contrat
                </p>
                <div
                  className={`flex gap-2 text-xs lg:text-base ${
                    formik.errors.subscription && formik.touched.subscription
                      ? "text-red"
                      : "text-black"
                  }`}
                >
                  <input
                    type="radio"
                    name="subscription"
                    value="Oui"
                    checked={formik.values.subscription === "Oui"}
                    onChange={formik.handleChange}
                  />
                  <label>Oui</label>
                  <input
                    type="radio"
                    name="subscription"
                    value="Non"
                    checked={formik.values.subscription === "Non"}
                    onChange={formik.handleChange}
                  />
                  <label>Non</label>
                </div>
              </div>
              <div className="mt-2 px-4 lg:px-16 pt-8 flex items-center justify-between">
                <p className="text-black text-xs text-left max-w-52 lg:max-w-none sm:text-base">
                  Une prime due et/ou son règlement
                </p>
                <div
                  className={`flex gap-2 text-xs lg:text-base ${
                    formik.errors.premium && formik.touched.premium
                      ? "text-red"
                      : "text-black"
                  }`}
                >
                  <input
                    type="radio"
                    name="premium"
                    value="Oui"
                    checked={formik.values.premium === "Oui"}
                    onChange={formik.handleChange}
                  />
                  <label>Oui</label>
                  <input
                    type="radio"
                    name="premium"
                    value="Non"
                    checked={formik.values.premium === "Non"}
                    onChange={formik.handleChange}
                  />
                  <label>Non</label>
                </div>
              </div>
              <div className="mt-2 px-4 lg:px-16 pt-8 flex items-center justify-between">
                <p className="text-black text-xs text-left max-w-52 lg:max-w-none sm:text-base">
                  Des informations personnelles
                </p>
                <div
                  className={`flex gap-2 text-xs lg:text-base ${
                    formik.errors.personalInfo && formik.touched.personalInfo
                      ? "text-red"
                      : "text-black"
                  }`}
                >
                  <input
                    type="radio"
                    name="personalInfo"
                    value="Oui"
                    checked={formik.values.personalInfo === "Oui"}
                    onChange={formik.handleChange}
                  />
                  <label>Oui</label>
                  <input
                    type="radio"
                    name="personalInfo"
                    value="Non"
                    checked={formik.values.personalInfo === "Non"}
                    onChange={formik.handleChange}
                  />
                  <label>Non</label>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row lg:justify-between">
            <div
              className={`lg:h-24  ${
                formik.values.other === "Oui" ? "w-full" : "lg:w-1/2"
              }`}
            >
              <div className="mt-2 px-4 lg:px-16 pt-8 flex items-center justify-between">
                <p className="text-black text-xs text-left max-w-52 lg:max-w-none sm:text-base">
                  Autres cas
                </p>
                <div
                  className={`flex gap-2 text-xs lg:text-base ${
                    formik.errors.other && formik.touched.other
                      ? "text-red"
                      : "text-black"
                  }`}
                >
                  <input
                    type="radio"
                    name="other"
                    value="Oui"
                    checked={formik.values.other === "Oui"}
                    onChange={formik.handleChange}
                  />
                  <label>Oui</label>
                  <input
                    type="radio"
                    name="other"
                    value="Non"
                    checked={formik.values.other === "Non"}
                    onChange={formik.handleChange}
                  />
                  <label>Non</label>
                </div>
              </div>
            </div>
            {formik.values.other === "Oui" && (
              <div className="w-full mt-2 lg:mt-8">
                <input
                  type="text"
                  name="otherCases"
                  className={`rounded-full border-2 w-[92%] lg:w-[80%] ${
                    formik.errors.otherCases && formik.touched.otherCases
                      ? "border-red"
                      : "border-accent"
                  }`}
                  value={formik.values.otherCases}
                  onChange={formik.handleChange}
                />
                {formik.errors.otherCases && formik.touched.otherCases && (
                  <p className="text-red text-base mt-2">
                    {formik.errors.otherCases}
                  </p>
                )}
              </div>
            )}
          </div>

          {isNotValid && (
            <span className="text-base text-red">
              Au moins un des champs doit être "Oui"
            </span>
          )}

          <div className="mt-2 px-4 lg:px-16 pt-8">
            <textarea
              name="details"
              placeholder="Détail de votre réclamation"
              className="rounded-xl border-2 border-accent px-6 py-3 text-lg h-28 w-full"
              value={formData.details}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="mt-2 px-4 lg:px-16 pt-8 flex items-start lg:items-center justify-between lg:justify-start lg:gap-8 lg:h-10">
            <p className="text-black text-xs text-left max-w-52 lg:max-w-none sm:text-base">
              Joignez d’éventuelles pièces.
            </p>
            <div className="flex flex-col lg:flex-row gap-4 lg:gap-6">
              {formData.files.map((file, index) => {
                const fileName = file?.name || "No file selected";
                const truncatedFileName =
                  fileName.length > 20
                    ? `${fileName.slice(0, 20)}...`
                    : fileName;

                return (
                  <div
                    key={index}
                    className="flex items-center gap-4 overflow-hidden"
                  >
                    <label
                      htmlFor={`file-upload-${index}`}
                      className="flex items-center"
                    >
                      <img
                        src={filesUploaded[index] ? valid : upload}
                        alt="file-upload"
                        className="w-6"
                      />
                      <input
                        id={`file-upload-${index}`}
                        type="file"
                        multiple
                        onChange={(e) => handleFileChange(e, index)}
                        className="hidden"
                      />
                    </label>
                    <span className="text-sm text-gray-700" title={fileName}>
                      {truncatedFileName}
                    </span>
                    <img
                      src={trash}
                      alt="delete"
                      onClick={() => removeFileInput(index)}
                      className="cursor-pointer"
                    />
                  </div>
                );
              })}
              {formData.files.length < 3 && (
                <span
                  className="text-primary font-medium text-3xl -mt-2 lg:mt-0 cursor-pointer"
                  onClick={addFileInput}
                >
                  +
                </span>
              )}
            </div>
          </div>
          <div className="flex gap-4 justify-center md:justify-between sm:mx-16 mt-20">
            <button
              type="button"
              className="bg-black font-semibold text-white text-xs rounded-[0.875rem] sm:rounded-[1.25rem] w-[7.625rem] sm:w-[11.75rem] sm:text-base px-6 py-2 my-6"
              onClick={() => navigate("/claim")}
            >
              PRÉCÉDENT
            </button>
            <button
              type="submit"
              className="bg-primary font-semibold text-white text-xs rounded-[0.875rem] sm:rounded-[1.25rem] w-[7.625rem] sm:w-[11.75rem] sm:text-base px-6 py-2 my-6"
              onClick={() => formik.handleSubmit()}
            >
              SUIVANT
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Identification;
