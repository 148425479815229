import axios from "axios";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";
import { Post } from "../../models/Post";
import React, { useEffect, useState } from "react";


function SinisterContactUs() {
  const [post, setPost] = useState<Post | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch post data
    axios
      .get(`${API_BASE_URL}/posts/31`)
      .then((response) => {
        if (response.data.status === "PUBLISHED") {
          setPost(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching post data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);


  return (
    <section className=" block md:flex bg-background text-left items-center gap-x-16">
      <img src={`${STORAGE_BASE_URL}/${post?.image}`} alt="" className="md:w-[40%]" />
      <div>
        <h1 className="font-extrabold"> {post?.title}</h1>
        {/* <p className="text-lg my-4">
          Pour toute question ou besoin d'assistance immédiate :
          <br />
          - Email : sinistres@oceanbridges.fr
          <br />- Téléphone : +33 1 23 45 67 89
        </p> */}
        <div className="text-lg my-4" dangerouslySetInnerHTML={{ __html: post?.body || '' }} />
      </div>
    </section>
  );
}

export default SinisterContactUs;
