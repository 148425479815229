
import React, { useEffect, useState } from "react";
import Advantage from "./Advantage";
import { useDeviceSize } from "react-device-sizes";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";

interface AdvantageProps {
  bg?: string;
}

interface AdvantageData {
  id: number;
  icon: string;
  title: string;
  description: string;
  visible: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

const AdvantagesSection: React.FC<AdvantageProps> = ({ bg }) => {
  const { xsDown } = useDeviceSize();
  const [advantages, setAdvantages] = useState<AdvantageData[]>([]);

  useEffect(() => {
    const fetchAdvantages = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/our-adventage-offers`);
        const data: AdvantageData[] = await response.json();
        // Filter visible advantages
        const visibleAdvantages = data.filter((adv) => adv.visible === 1);
        setAdvantages(visibleAdvantages);
      } catch (error) {
        console.error("Failed to fetch advantages:", error);
      }
    };

    fetchAdvantages();
  }, []);

  // Map API advantages to local advantage objects with appropriate images


  return (
    <section
      className={`bg-${bg ?? "primary"} text-white text-left py-10 sm:py-16`}
    >
      <h1 className="text-base md:text-2xl text-center md:text-left px-4 mb-16">
        NOS OFFRES D'ASSURANCE <br className="sm:hidden" />
        <span className="hidden sm:inline-block">-</span> LES AVANTAGES
      </h1>
      {xsDown ? (
        <div className="grid gap-y-4">
          <div className="flex gap-2">
            {advantages.slice(0, 3).map((advantage, index) => (
              <Advantage
                key={index}
                image={`${STORAGE_BASE_URL}/${advantage.icon}`}
                title={advantage.title}
                subtitle={advantage.description}
              />
            ))}
          </div>
          <div className="flex gap-2 px-[15%]">
            {advantages.slice(3).map((advantage, index) => (
              <Advantage
                key={index}
                image={`${STORAGE_BASE_URL}/${advantage.icon}`}
                title={advantage.title}
                subtitle={advantage.description}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="block lg:flex justify-between gap-x-4 mt-16">
          {advantages.map((advantage, index) => (
            <Advantage
              key={index}
              image={`${STORAGE_BASE_URL}/${advantage.icon}`}
              title={advantage.title}
              subtitle={advantage.description}
            />
          ))}
        </div>
      )}
    </section>
  );
};

export default AdvantagesSection;
