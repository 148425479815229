import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import Button from "../Button";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";
import { Post } from "../../models/Post";
import { useContactModal } from "../../hooks/useContactModal";

interface CustomAssurance {
  id: number;
  title: string | null;
  url_image: string;
  button: number;
  color: string;
  button_text: string;
  visible: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  link: number;
  link_text: string | null;
}

function AssuranceSection() {
  const [assurances, setAssurances] = useState<CustomAssurance[]>([]);
  const [post, setPost] = useState<Post | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { toggleContactModal } = useContactModal();

  useEffect(() => {
    const fetchAssurances = async () => {
      try {
        const response = await axios.get<CustomAssurance[]>(`${API_BASE_URL}/custom-assurances`);
        // Filter visible items
        const visibleAssurances = response.data.filter(item => item.visible === 1);
        setAssurances(visibleAssurances);
      } catch (error) {
        console.error('Error fetching custom assurances data:', error);
      }
    };

    // Fetch post data
    axios
      .get(`${API_BASE_URL}/posts/34`)
      .then((response) => {
        if (response.data.status === "PUBLISHED") {
          setPost(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching post data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    fetchAssurances();
  }, []);

  return (
    <div className="bg-black text-white px-0 sm:px-[5%] md:px-[15%] lg:px-[20%] text-left bg-pattern">
      <div className="bg-black py-8 lg:py-16 px-4 sm:px-0">

        <h1 className="sm:font-bold text-base sm:text-lg">
          {post?.title}
        </h1>
        <div
          className="text-sm md:text-base mt-4"
          dangerouslySetInnerHTML={{ __html: post?.body || "" }}
        />

        <div className="flex flex-col md:flex-row md:gap-4 py-4">
          {/* Column 1 (Mobile: Full width, Desktop: 45% width) */}
          <div className="mb-4 md:mb-0 md:w-[45%]">
            {assurances[0] && (
              <div className="flex flex-col h-full">
                <Link
                  to="/real-estate-professions"
                  className="hidden sm:block flex-grow"
                >
                  <div className="relative overflow-hidden h-full">
                    <img
                      src={`${STORAGE_BASE_URL}/${assurances[0].url_image}`}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-[#6f4eb1] opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
                  </div>
                </Link>
                <div
                  className="bg-[#cdbbef] sm:h-24 flex justify-between pl-4 pr-8 py-4 sm:p-2 items-center sm:items-start flex-shrink-0"
                  style={{ backgroundColor: assurances[0].color }}
                >
                  <p className="text-left text-sm sm:text-base text-black font-bold">
                    {assurances[0].title}
                  </p>
                  <div className="flex flex-col items-center space-y-2">
                    <Button
                      link="/subscribe"
                      text={assurances[0].button_text}
                      color="black"
                      textColor="white"
                      size="md"
                      sizeSm="[.6rem]"
                      width="100"
                    />
                    {/* <Button
                      link="/subscribe"
                      text={assurances[0].button_text}
                      color="black"
                      textColor="white"
                      size="md"
                      sizeSm="[.6rem]"
                      width="60"
                    /> */}
                    <a
                      className="text-[.6rem] sm:text-sm text-black underline"
                      href="/real-estate-professions"
                    >
                      En savoir {"+"}
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Column 2 (Mobile: Full width, Desktop: 55% width) */}
          <div className="md:w-[55%] grid grid-cols-1 md:grid-cols-2 gap-4">
            {assurances.slice(1).map((item, index) => (
              item.visible === 1 ? (
                <div key={item.id} className="w-full h-auto">
                  {item?.id === 5 || item?.id === 1 ? (
                    <Link to={`/real-estate-financial-intermediary`} className="hidden sm:block">
                      <div className="relative overflow-hidden">
                        <img
                          src={`${STORAGE_BASE_URL}/${item.url_image}`}
                          alt=""
                          className="w-full h-auto object-cover"
                        />
                        <div className="absolute inset-0 bg-[#309275] opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
                      </div>
                    </Link>
                  ) : (
                    <div className="hidden sm:block" onClick={toggleContactModal}>
                      <div className="relative overflow-hidden">
                        <img
                          src={`${STORAGE_BASE_URL}/${item.url_image}`}
                          alt=""
                          className="w-full h-auto object-cover"
                        />
                        <div className="absolute inset-0 bg-[#309275] opacity-0 group-hover:opacity-50 transition-opacity duration-300"></div>
                      </div>
                    </div>
                  )}

                  <div
                    className={`bg-[${index % 2 === 0 ? '#a7ebd6' : '#fbe8a4'}] w-full sm:h-24 flex justify-between pl-4 pr-8 py-4 sm:p-2 items-center sm:items-start`}
                    style={{ backgroundColor: item.color }}
                  >
                    <p className="text-left w-[50%] text-xs sm:text-sm text-black font-bold">
                      {item.title}
                    </p>
                    <Button
                      link={(item?.id === 5 || item?.id === 1) ? "/real-estate-financial-intermediary" : undefined}
                      onClick={(item?.id !== 5 && item?.id !== 1) ? toggleContactModal : undefined}
                      text={item.button_text}
                      color="black"
                      textColor="white"
                      size="xs"
                      sizeSm="[.6rem]"
                      width="85"
                    />
                  </div>
                </div>
              ) : null
            ))}
          </div>

        </div>
      </div>
    </div>
  );
}

export default AssuranceSection;
