import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";

interface Post {
  id: number;
  title: string;
  body: string;
  slug: string;
  image: string;
}

function Alliance() {
  const [post, setPost] = useState<Post | null>(null);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/posts/6`)
      .then((response) => {
        const fetchedPost = response.data;
        setPost(fetchedPost);
        // if (fetchedPost.id === "nos-assurances-sectorielle") {
        //   setPost(fetchedPost);
        // }
      })
      .catch((error) => {
        console.error("Error fetching the post:", error);
      });
  }, []);

  // If the post is null or slug doesn't match, return null or alternative content
  if (!post) {
    return null; // or you can return some placeholder or alternative content
  }

  return (
    <section className="block text-left py-10 sm:py-16 md:flex text-black w-full bg-[#fffdf8]">
      <div className="mb-8 md:m-0 w-full md:w-[20%] flex flex-row md:flex-col justify-center md:self-start ">
        <img
          src={`${STORAGE_BASE_URL}/${post.image}`}
          alt={post.title}
          className="w-[20%] md:w-[80%]  mr-none md:mr-[10%] my-auto"
        />
      </div>
      <div className="w-[100%]">
        <h2 className="text-md md:text-2xl  font-extrabold md:w-2/3">
          {post.title}
        </h2>
        <div
          className="text-xs md:text-[16px] leading-normal mt-3 mb-2 sm:mb-8"
          dangerouslySetInnerHTML={{ __html: post.body }}
        />
      </div>
    </section>
  );
}

export default Alliance;
