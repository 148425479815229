import React from "react";
import Alliance from "./Alliance";
import OurAssurance from "./OurAssurance";

function AboutSection() {
  return (
    <div>
      <OurAssurance />
      <Alliance />
    </div>
  );
}

export default AboutSection;
