import React from "react";

import NormsSection from "../components/regulation/NormsSection";
import PrinciplesSection from "../components/regulation/PrinciplesSection";
import NewRegulationsSection from "../components/regulation/NewRegulationsSection";
import TransparencySection from "../components/regulation/TransparencySection";
import RegulationContact from "../components/regulation/RegulationContact";
import { API_BASE_URL, STORAGE_BASE_URL } from "../const";
import { useEffect, useState } from "react";
import axios from "axios";
import HeroTemplate from "../components/common/HeroTemplate";


function Regulation() {

  const [heroData, setHeroData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/heroes/7`);
        if (response.data.visiblity === 1) {
          setHeroData(response.data);
        } else {
          setError('Hero data is not visible.');
        }
      } catch (err) {
        setError('Failed to fetch hero data.');
      } finally {
        setLoading(false);
      }
    };

    fetchHeroData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (!heroData) return null;

  return (
    <div>
      {/* <RegulationHero background={background} color="#87c0af" /> */}

      <HeroTemplate
      title={heroData.title}
      subtitle={heroData.description.replace(/<\/?div>/g, '')} 
      background={`${STORAGE_BASE_URL}/${heroData.imageurl}`}
      color="#a1c7cf"
      // titleClass="lg:max-w-[40%] 2xl:max-w-[20%]"
    />
      <NormsSection />
      <PrinciplesSection />
      <NewRegulationsSection />
      <TransparencySection />
      <RegulationContact />
    </div>
  );
}

export default Regulation;
