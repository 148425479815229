import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";
import { Event } from "../../models/Event";
import { Post } from "../../models/Post";



function NewsEventsSection() {
  const [events, setEvents] = useState<Event[]>([]);
  const [post, setPost] = useState<Post | null>(null);

  useEffect(() => {
    axios.get(`${API_BASE_URL}/next-events`)
      .then(response => {
        const fetchedEvents = response.data as Event[];

        // Apply slug-based filtering here if necessary
        const filteredEvents = fetchedEvents.filter(event => event.visibility === 1);
        setEvents(filteredEvents);
      })
      .catch(error => {
        console.error("Error fetching events:", error);
      });

    // Fetch post data
    axios.get(`${API_BASE_URL}/posts/8`)
      .then(response => {
        const fetchedPost = response.data as Post;
        setPost(fetchedPost);
      })
      .catch(error => {
        console.error("Error fetching post:", error);
      });

  }, []);

  return (
    <section className="bg-black py-0 lg:px-[16%] text-white text-left bg-pattern">
      <div className="bg-black py-8 lg:py-16 lg:px-[6%]">
        <h1 className="text-xl mb-4 md:mb-10">{post?.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: post?.body || '' }} />
        <div className="grid lg:grid-cols-3 gap-4 md:gap-6">
          {events.length > 0 ? (
            events.map((event, index) => (
              <div
                key={index}
                className="bg-background text-center group relative"
                style={{ backgroundColor: event.color }}
              >
                <div className="relative overflow-hidden">
                  <img
                    src={`${STORAGE_BASE_URL}/${event.url_image}`}
                    alt={event.title}
                    className="w-full object-cover"
                  />
                  <div
                    className="absolute inset-0 opacity-0 group-hover:opacity-50 transition-opacity duration-300"
                    style={{ backgroundColor: event.color }}
                  ></div>
                </div>
                <p className="text-base p-4 text-black text-left font-bold">
                  {event.title}
                  <p className="text-base text-left text-gray-800 ">
                    {new Date(event.date).toLocaleDateString('fr-FR')}
                  </p>
                </p>
              </div>
            ))
          ) : (
            <p>No events available.</p>
          )}
        </div>
      </div>
    </section>
  );
}

export default NewsEventsSection;