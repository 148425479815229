import React from "react";
import Login from "../components/subscribe/Login";
import SignUp from "../components/subscribe/SignUp";

function SubscribePage() {
  return (
    <section className="bg-black grid lg:grid-cols-2 gap-4 bg-pattern ">
      <Login />
      <SignUp />
    </section>
  );
}

export default SubscribePage;
