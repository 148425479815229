import React from "react";

interface PrincipleCardProps {
  title: string;
  description: string;
  color: string;
}

const PrincipleCard: React.FC<PrincipleCardProps> = ({
  
  description,
  color,
}) => {
  return (
    <div className="p-4 md:p-6 text-left" style={{ backgroundColor: color }}>
   
      <p className="text-base mb-16" dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

export default PrincipleCard;
