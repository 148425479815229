

import React, { useEffect, useState } from "react";
import { useDeviceSize } from "react-device-sizes";
import { PreferredPartner } from "../../models/PreferredPartner";
import { API_BASE_URL } from "../../const";


function ChooseUsSection() {
  const [partner, setPartner] = useState<PreferredPartner | null>(null);
  const { smDown } = useDeviceSize();

  useEffect(() => {
    fetch(`${API_BASE_URL}/preferred-partners`)
      .then((response) => response.json())
      .then((data: PreferredPartner[]) => {
        // Filter based on visibility and select the first visible partner
        const visiblePartner = data.find((item) => item.visible === 1) || null;
        setPartner(visiblePartner);
      })
      .catch((error) => {
        console.error("Error fetching preferred partners:", error);
      });
  }, []);

  if (!partner) {
    return null; // If no visible partner is found, render nothing
  }

  return (
    <div className="bg-black text-white sm:px-[5%] md:px-[20%] lg:px-[12%] xl:px-[16%] text-left bg-pattern">
      <div className="bg-black py-8 lg:py-16 px-[5%] sm:px-0 lg:px-[6%]">
        <h1 className="mb-10 text-xl lg:text-2xl font-light">
          CHOISIR OCEAN BRIDGES
        </h1>
        <h1 className="font-bold mb-4 text-xl lg:text-2xl">
          {partner.title}
        </h1>
        <p
          className="text-xs sm:text-sm md:text-lg"
          dangerouslySetInnerHTML={{ __html: partner.description }}
        />
        {/* {smDown ? (
          <img
            src={diagramMobile}
            alt="diagram"
            className="w-full py-8 px-20 sm:p-32"
          />
        ) : (
          <></>
          // <img src={diagram} alt="diagram" className="w-full my-10" />
        )} */}
        <div className="flex justify-center mt-6">
          <a
            href="https://extranet.oceanbridges.fr/"
            className="block bg-transparent break-keep text-white font-semibold text-base sm:text-lg transition duration-300 hover:text-black hover:bg-white py-2 px-4 border border-white hover:border-transparent rounded-full h-min"
          >
            DEVENIR PARTENAIRE
          </a>
        </div>
      </div>
    </div>
  );
}

export default ChooseUsSection;
