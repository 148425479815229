import React, { useEffect, useState } from "react";
import oceanBridgeIcon from "../../assets/icons/bridge-icon.svg";
import axios from "axios";
import { Courtiers } from "../../models/Courtiers";
import { API_BASE_URL } from "../../const";
import { Post } from "../../models/Post";



const WhatWeOfferSection: React.FC = () => {
  const [offers, setOffers] = useState<Courtiers[]>([]);
  const [post, setPost] = useState<Post | null>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get<Courtiers[]>(`${API_BASE_URL}/courtiers`);
        // Filter the offers to only include those with visibility: 1
        const visibleOffers = response.data.filter(offer => offer.visiblility === 1);
        setOffers(visibleOffers);
      } catch (error) {
        console.error("Error fetching the offers:", error);
      }
    }
    async function fetchPosts() {
      try {
        axios
          .get(`${API_BASE_URL}/posts/15`)
          .then((response) => {
            const postData = response.data;

            // If there's a visibility check, include it here:
            // if (postData.visibility) {
              setPost(postData);
            // }
          })
          .catch((error) => {
            console.error("Error fetching the post data:", error);
          });
      } catch (error) {
        console.error("Error fetching the offers:", error);
      }
    }

    fetchData();
    fetchPosts();
  }, []);

  return (
    <div className="bg-black text-white sm:px-[5%] md:px-[20%] lg:px-[16%] text-left bg-pattern">
      <div className="bg-black py-8 sm:py-16 px-[5%] sm:px-0 lg:px-[6%]">
        <h1 className="mb-10 text-base sm:text-xl md:text-2xl lg:font-bold">
          {post?.title}
        </h1>
        {/* <h1 className="font-bold text-base sm:text-lg md:text-xl mb-4">
          UNE EXPÉRIENCE PREMIUM AVEC OCEAN BRIDGES
        </h1> */}
        <p
          className="text-xs sm:text-sm md:text-lg mb-10"
          dangerouslySetInnerHTML={{ __html: post?.body || "" }}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
          {offers.map((offer) => (
            <div key={offer.id} className="mb-6 flex items-start px-8 sm:px-0">
              <img
                src={oceanBridgeIcon}
                alt="Ocean Bridges"
                className="sm:w-8 sm:h-8 mr-4"
              />
              <p className="text-xs sm:text-base md:text-xl">
                <span className="font-bold text-primary">{offer.title}</span>
                <span dangerouslySetInnerHTML={{ __html: offer.description }} />
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatWeOfferSection;