import React, { useEffect, useState } from "react";
import { BoxReveal } from "../box-reveal";
import axios from "axios";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";
import { Post } from "../../models/Post";

function ObConnectApiSection() {
  const [post, setPost] = useState<Post | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch post data
    axios
      .get(`${API_BASE_URL}/posts/33`)
      .then((response) => {
        if (response.data.status === "PUBLISHED") {
          setPost(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching post data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <section className="relative p-0">
      <img
        src={`${STORAGE_BASE_URL}/${post?.image}`}
        alt=""
        className="object-cover w-full h-full absolute inset-0 z-0"
      />
      <div className="h-full xl:max-w-[75%] px-[5%] md:px-[20%] py-16 lg:pb-32 items-center justify-center z-50 bg-transparent">
        <BoxReveal boxColor={""} duration={0.5}>
          <h1 className="text-left text-white text-lg md:text-2xl font-bold">
            {post?.title}
          </h1>
        </BoxReveal>

        <BoxReveal boxColor={""} duration={0.5}>
          <div className="text-white mt-[1.5rem] text-left text-sm md:text-base">
            <div dangerouslySetInnerHTML={{ __html: post?.body || ''}} />
          </div>
        </BoxReveal>
        <div className="m-6"></div>
        <div className="flex justify-center sm:justify-start">
          <BoxReveal boxColor={""} duration={0.5}>
            <a
              href="/ob-connect"
              className="block bg-white hover:bg-blue text-[#399bd8]  text-sm font-extrabold transition duration-300 hover:text-white hover:bg-transparent py-2 px-4 border border-blue hover:border-white rounded-full pointer-events-auto"
            >
              EN SAVOIR PLUS
            </a>
          </BoxReveal>
        </div>
      </div>
    </section>
  );
}

export default ObConnectApiSection;
