import { useEffect, useState } from "react";
import background from "../../assets/img/about/partnership.jpg";
import { BoxReveal } from "../box-reveal";
import { Post } from "../../models/Post";
import axios from "axios";
import { API_BASE_URL } from "../../const";

function TransparencySection() {

  const [post, setPost] = useState<Post>();
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get<Post>(`${API_BASE_URL}/posts/19`);
        // const visiblePosts = response.data.filter((post) => post.visible === 1);
        setPost(response.data);
      } catch (error) {
        console.error("Error fetching useful tips:", error);
      }
    };

    fetchPosts();
  }, [])
  return (
    <section className="relative p-0">
      <img
        src={background}
        alt=""
        className="object-cover w-full h-full absolute inset-0 z-0"
      />
      <div className="h-full xl:max-w-[70%] px-[5%] md:px-[20%] py-[10%] md:py-[8%] items-center justify-center z-50 bg-transparent">
        <BoxReveal boxColor={""} duration={0.5}>
          <h1 className=" text-left text-white text-lg  md:text-2xl font-bold">
            {post?.title}
          </h1>
        </BoxReveal>

        <BoxReveal boxColor={""} duration={0.5}>
          <div className="text-white mt-[1.5rem] text-left text-sm md:text-base">
            <p dangerouslySetInnerHTML={{ __html: post?.body || "" }} />
          </div>
        </BoxReveal>
      </div>
    </section>
  );
}

export default TransparencySection;
