import { useEffect, useState } from "react";
import InsuranceTab from "./InsuranceTab";
import Tabs from "./Tab";
import { decode } from 'he';
import axios from "axios";
import { API_BASE_URL } from "../../const";
import { Assurance } from "../../models/Assurance";
import { Post } from "../../models/Post";
import {useDeviceSize} from "react-device-sizes";


function BetterUnderStandSection() {
    const { xsDown } = useDeviceSize();
    const [assurances, setAssurances] = useState<Assurance[]>([]);
    const [post, setPost] = useState<Post>();

    useEffect(() => {
        axios
            .get(`${API_BASE_URL}/assurances`)
            .then((response) => {
                // Filter by visibility if needed
                const visibleAssurances = response.data.filter(
                    (assurance: Assurance) => assurance.visibility === 1
                );
                setAssurances(visibleAssurances);
            })
            .catch((error) => {
                console.error("Error fetching assurances data", error);
            });

        axios
            .get(`${API_BASE_URL}/posts/10`)
            .then((response) => {
                setPost(response.data);
            })
            .catch((error) => {
                console.error("Error fetching assurances data", error);
            });
    }, []);

    const tabs = assurances.map((assurance) => ({
        label: xsDown ? assurance?.service_title_mobile : assurance?.service_title, // Use service_title_mobile for mobile view
        labelAbbreviation: (xsDown ? assurance?.service_title_mobile : assurance?.service_title)
            .split(" ")
            .map((word) => word)
            .join(""),
        content: (
            <InsuranceTab
                columns={[
                    {
                        title: "Base Légale",
                        description: decode(assurance?.base_legale)
                            .replace(/<\/?[^>]+(>|$)/g, "")
                            .split(/\r?\n/)
                            .filter((line: any) => line.trim().length > 0)
                            .map((line: string) => `${line}`),
                    },
                    {
                        title: "Définition",
                        description: decode(assurance?.definition)
                            .replace(/<\/?[^>]+(>|$)/g, "")
                            .split(/\r?\n/)
                            .filter((line: any) => line.trim().length > 0)
                            .map((line: string) => `${line}`),
                    },
                    {
                        title: "Conditions d’Exercice",
                        description: decode(assurance?.conditions_exercice)
                            .replace(/<\/?[^>]+(>|$)/g, "")
                            .split(/\r?\n/)
                            .filter((line: any) => line.trim().length > 0)
                            .map((line: string) => `${line}`),
                    },
                ]}
                color={assurance?.color}
            />
        ),
    }));

    if (!post) {
        return null; // Or return some placeholder content
    }

    return (
        <section className="text-left bg-[#f6f4ea] transition-transform">
            <h2 className="text-xl">
                {post?.title}
            </h2>
            <div
                className="text-sm sm:text-xl lg:text-3xl my-8"
                dangerouslySetInnerHTML={{ __html: post?.body }}
            />
            <Tabs tabs={tabs} />
            <div className="flex justify-center mt-8">
                <a href="https://extranet.oceanbridges.fr/">
                    <button className="hover:bg-blue text-white bg-black text-sm md:font-semibold md:text-base transition duration-300 hover:bg-primary py-2 px-4 rounded-full">
                        OBTENIR UN DEVIS
                    </button>
                </a>
            </div>
        </section>
    );
}

export default BetterUnderStandSection;
