import React, { useEffect, useState } from "react";
import axios from "axios";
import OfferCard from "./OfferCard";
import { Offer } from "../../models/Offer";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";

const CustomOffersSection: React.FC = () => {
  const [offers, setOffers] = useState<Offer[]>([]);

  const decodeHtmlEntities = (text: string) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = text;
    return textarea.value;
  };

  const formatDescription = (text: string) => {
    // Decode HTML entities
    const decodedText = decodeHtmlEntities(text);
    // Replace newlines with <br /> tags
    return decodedText.replace(/\n/g, '<br />');
  };

  useEffect(() => {

    axios.get(`${API_BASE_URL}/avantages`)
      .then(response => {
        setOffers(response.data);
      })
      .catch(error => {
        console.error("Error fetching offers:", error);
      });

  }, []);

  return (
    <section>
      
      <div className="grid grid-cols-1 2xl:grid-cols-3 gap-4">
      
        {offers.map((offer) => (
          <OfferCard
            key={offer.id}
            title={offer.title}
            description={formatDescription(offer.description)}
            icon={`${STORAGE_BASE_URL}/${offer.icon}`} 
            iconBgColor={offer.color} 
            textBgColor={hexToRgba(offer.color, 0.5)}
          />
        ))}
      </div>
    </section>
  );
};

// Helper function to convert hex color to RGBA
function hexToRgba(hex: string, alpha: number): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export default CustomOffersSection;