import { AnimatePresence, motion } from "framer-motion";
import { FiAlertCircle } from "react-icons/fi";

interface SpringModalProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
  children?: React.ReactNode;
  widthClass?: string;
}

const SpringModal: React.FC<SpringModalProps> = ({
  isOpen,
  toggleIsOpen,
  children,
  widthClass,
}: SpringModalProps) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => {
            toggleIsOpen();
          }}
          className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-[60] grid place-items-center overflow-y-scroll cursor-pointer"
        >
          <motion.div
            initial={{ scale: 0, rotate: "12.5deg" }}
            animate={{ scale: 1, rotate: "0deg" }}
            exit={{ scale: 0, rotate: "0deg" }}
            onClick={(e) => e.stopPropagation()}
            className={`bg-background ${
              widthClass
                ? widthClass
                : `w-full md:max-w-[80%] 2xl:max-w-["40%"]`
            } shadow-xl cursor-default relative overflow-hidden h-[80vh] 2xl:h-auto overflow-y-scroll lg:overflow-auto`}
          >
            {/* <FiAlertCircle className="text-white/10 rotate-12 text-[250px] absolute z-0 -top-24 -left-24" /> */}
            {children ?? (
              <div className="relative z-10">
                <div className="bg-white w-16 h-16 mb-2 rounded-full text-3xl text-indigo-600 grid place-items-center mx-auto">
                  <FiAlertCircle />
                </div>
                <h3 className="text-3xl font-bold text-center mb-2">
                  One more thing!
                </h3>
                <p className="text-center mb-6">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Id
                  aperiam vitae, sapiente ducimus eveniet in velit.
                </p>
                <div className="flex gap-2">
                  <button
                    onClick={() => toggleIsOpen}
                    className="bg-transparent hover:bg-white/10 transition-colors text-white font-semibold w-full py-2 rounded"
                  >
                    Nah, go back
                  </button>
                  <button
                    onClick={() => toggleIsOpen}
                    className="bg-white hover:opacity-90 transition-opacity text-indigo-600 font-semibold w-full py-2 rounded"
                  >
                    Understood!
                  </button>
                </div>
              </div>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SpringModal;
