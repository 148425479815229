import { Post } from "../../models/Post";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";
import { Reminder } from "../../models/Reminder";

function RemindersSection() {

  const [post, setPost] = useState<Post>();
  const [reminders, setReminders] = useState<Reminder[]>([]);


  useEffect(() => {

    const fetchReminders = async () => {
      try {
        const response = await axios.get<Reminder[]>(
          // "http://64.226.126.75/api/rappels-importants"
          `${API_BASE_URL}/rappels-importants`
        );
        const visibleReminders = response.data.filter(
          (reminder) => reminder.visibility === 1
        );
        setReminders(visibleReminders);
      } catch (error) {
        console.error("Error fetching reminders:", error);
      }
    };

    const fetchPosts = async () => {
      try {
        const response = await axios.get<Post>(`${API_BASE_URL}/posts/17`);
        // const visiblePosts = response.data.filter((post) => post.visible === 1);
        setPost(response.data);
      } catch (error) {
        console.error("Error fetching useful tips:", error);
      }
    };

    fetchPosts();
    fetchReminders();

  }, []);

  return (
    <section className="bg-black py-0 text-white text-left bg-pattern">
      <div className="bg-black py-8 lg:py-16">
        <h1 className="font-bold text-xl md:text-2xl mb-4">
          {post?.title}
        </h1>
        <p className=" text-base md:text-lg" dangerouslySetInnerHTML={{ __html: post?.body || "" }} />
        <div className="grid lg:flex mt-8 gap-4">
          {reminders.map((reminder, index) => (
            <div key={reminder.id} className="flex-1 group relative">
              <div className="relative overflow-hidden">
                <img
                  src={`${STORAGE_BASE_URL}/${reminder.url_image}`}
                  alt={reminder.title}
                  className="w-full object-cover"
                />
                <div
                  className="absolute inset-0 opacity-0 group-hover:opacity-50 transition-opacity duration-300"
                  style={{ backgroundColor: hexToRgba(reminder.color, 0.3) }}
                ></div>
              </div>
              <div
                className="h-24 flex justify-between p-3"
                style={{ backgroundColor: reminder.color }}
              >
                <p className="text-left text-base text-black font-bold">
                  {reminder.title}
                </p>
              </div>
            </div>
          ))}

        </div>
      </div>
    </section>
  );
}
// Helper function to convert hex color to RGBA
function hexToRgba(hex: string, alpha: number = 1): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}


export default RemindersSection;
