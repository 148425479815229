import HeroTemplate from "../components/common/HeroTemplate";

import HowToDeclare from "../components/sinister/HowToDeclare";
import SinisterContactUs from "../components/sinister/SinisterContactUs";
import SinisterSupport from "../components/sinister/SinisterSupport";
import UsefulTipsSection from "../components/sinister/UsefulTipsSection";
import { useEffect, useState } from "react";
import { API_BASE_URL, STORAGE_BASE_URL } from "../const";
import axios from "axios";
// import HeroTemplate from "../components/common/HeroTemplate";



function Sinister() {
  const [heroData, setHeroData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/heroes/6`);
        if (response.data.visiblity === 1) {
          setHeroData(response.data);
        } else {
          setError('Hero data is not visible.');
        }
      } catch (err) {
        setError('Failed to fetch hero data.');
      } finally {
        setLoading(false);
      }
    };

    fetchHeroData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (!heroData) return null;
  return (
    <div>
      {/* <HeroTemplate
        title="Déclaration et Gestion de Sinistres"
        subtitle="Chez Ocean Bridges, nous comprenons que faire face
à un sinistre peut être une période stressante.
Nous nous engageons à traiter votre demande rapidement
et avec le plus grand soin, pour que vous puissiez reprendre
votre activité normale le plus tôt possible."
        background={background}
      /> */}
       <HeroTemplate
      title={heroData.title}
      subtitle={heroData.description.replace(/<\/?div>/g, '')} 
      background={`${STORAGE_BASE_URL}/${heroData.imageurl}`}
      // color="#a1c7cf"
      // titleClass="lg:max-w-[40%] 2xl:max-w-[20%]"
    />
      <HowToDeclare />
      <UsefulTipsSection />
      <SinisterSupport />
      <SinisterContactUs />
    </div>
  );
}

export default Sinister;
