// src/components/Tabs.tsx
import React, { useState } from "react";
import { useDeviceSize } from "react-device-sizes";

interface Tab {
  label: string;
  labelAbbreviation: string;
  content: JSX.Element;
}

interface TabsProps {
  tabs: Tab[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const { mdDown } = useDeviceSize();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <div className="grid grid-cols-4 md:grid-cols-5 gap-2 transition">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`sm:px-4 py-2 font-semibold text-xs sm:text-sm focus:outline-none transition ${
              activeTab === index
                ? "bg-black text-white"
                : "bg-transparent border-black border-2"
            }`}
            onClick={() => setActiveTab(index)}
          >
            {mdDown ? tab.labelAbbreviation : tab.label}
          </button>
        ))}
      </div>
      <div className="pt-2">{tabs[activeTab]?.content}</div>
    </div>
  );
};

export default Tabs;
