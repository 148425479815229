
import React, { useEffect, useState } from "react";
import { useDeviceSize } from "react-device-sizes";
import Testimonial from "./Testimonial";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";
import { TestimonialType } from "../../models/TestimonialType";

function TestimonialSection() {
  const { smDown } = useDeviceSize();
  const [testimonials, setTestimonials] = useState<TestimonialType[]>([]);

  useEffect(() => {
    // fetch("http://64.226.126.75/api/testemonials")
    fetch(`${API_BASE_URL}/testemonials`)

      .then((response) => response.json())
      .then((data: TestimonialType[]) => {
        // Filter visible testimonials
        const visibleTestimonials = data.filter(
          (testimonial) => testimonial.visibility === 1
        );
        setTestimonials(visibleTestimonials);
      })
      .catch((error) => {
        console.error("Error fetching testimonials:", error);
      });
  }, []);

  if (testimonials.length === 0) {
    return null; // Render nothing if no visible testimonials
  }

  return (
    <section className="text-center sm:text-left text-black pt-6 pb-16 sm:grid gap-y-4 bg-accent">
      <h1 className="text-base sm:text-xl lg:text-2xl text-onAccent mb-4">
        L'AVIS DE NOS PARTNAIRES ET DE NOS CLIENTS COMPTE
      </h1>
      <div>
        <h1 className="text-lg sm:text-2xl font-extrabold">
          CE QUE DISENT NOS COURTIERS
        </h1>

        {smDown ? (
          <Swiper
            modules={[A11y, Autoplay]}
            slidesPerView={1}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            pagination={{ clickable: true }}
            className="max-w-[100vw] mt-4 text-white"
            breakpoints={{
              768: {
                slidesPerView: 1,
                spaceBetween: 5,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 5,
              },
            }}
          >
            {testimonials.map((testimonial) => (
              <SwiperSlide key={testimonial.id} className="text-black">
                <Testimonial
                  image={`${STORAGE_BASE_URL}/${testimonial.image}`}
                  name={testimonial.person_name}
                  position={testimonial.function}
                  quote={testimonial.testemonial.replace(/<[^>]+>/g, '')}
                  hashtags={testimonial.tag}
                  rating={testimonial.note}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          testimonials.map((testimonial) => (
            <Testimonial
              key={testimonial.id}
              image={`${STORAGE_BASE_URL}/${testimonial.image}`}
              name={testimonial.person_name}
              position={testimonial.function}
              quote={testimonial.testemonial.replace(/<[^>]+>/g, '')}
              hashtags={testimonial.tag}
              rating={testimonial.note}
            />
          ))
        )}
      </div>
    </section>
  );
}

export default TestimonialSection;