import React, { useEffect, useState } from "react";
import axios from "axios"; // Adjust the import path as needed
import OfferCard from "./OfferCard";
import { API_BASE_URL } from "../../const";
import { Post } from "../../models/Post";
interface SubscriptionApiResponse {
  id: number;
  etap: string;
  title: string;
  body: string;
  color: string;
  visibility: number;
}

interface InfoSection {
  id: number;
  title: string;
  items: { title?: string; description?: string }[];
  iconBgColor: string;
  textBgColor: string;
}

const transformApiResponseToInfoSections = (
  apiData: SubscriptionApiResponse[]
): InfoSection[] => {
  return apiData
    .filter((item) => item.visibility === 1)
    .map((item) => ({
      id: item.id,
      title: item.title,
      items: parseBodyContent(item.body),
      iconBgColor: item.color,
      textBgColor: "#ffffff" // Or any logic for textBgColor
    }));
};

const parseBodyContent = (body: string): { title?: string; description?: string }[] => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(body, "text/html");
  const items: { title?: string; description?: string }[] = [];

  doc.querySelectorAll('p').forEach((p) => {
    const titleMatch = p.querySelector('strong');
    const title = titleMatch ? titleMatch.textContent || undefined : undefined;
    const description = p.innerHTML || undefined;
    items.push({ title, description });
  });

  return items;
};

function ObConnectSubscriptionSection() {
  const [infoSections, setInfoSections] = useState<InfoSection[]>([]);
  const [post, setPost] = useState<Post | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/souscriptions`);
        const data: SubscriptionApiResponse[] = await response.json();
        const visibleSubscriptions = transformApiResponseToInfoSections(data);
        setInfoSections(visibleSubscriptions);
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
      }
    };

    // Fetch post data
    axios
      .get(`${API_BASE_URL}/posts/22`)
      .then((response) => {
        if (response.data.status === "PUBLISHED") {
          setPost(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching post data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    fetchSubscriptions();
  }, []);

  return (
    <section className="bg-accent">
      <h1 className="text-left font-bold text-xl md:text-2xl mb-4">
        {post?.title}
      </h1>
      {/* <h2 className="text-left text-base md:text-lg mb-8">
        Dans le respect des règles fixées au binder
      </h2> */}
      <div
        className="text-left text-base md:text-lg mb-8"
        dangerouslySetInnerHTML={{ __html: post?.body || "" }}
      />
      <div className="grid grid-cols-1 2xl:grid-cols-3 gap-4">
    
        {infoSections.map((section) => (
          <OfferCard
            index={section.id}
            key={section.id}
            title={section.title}
            items={section.items}
            icon="linkedin.svg" // Adjust as necessary
            iconBgColor={section.iconBgColor}
            textBgColor={section.textBgColor}
          />
        ))}
      </div>
    </section>
  );
}

export default ObConnectSubscriptionSection;
