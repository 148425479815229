
import { useEffect, useRef, useState } from "react";
import axios from "axios";
// Import Swiper React components
import { Navigation, A11y, Autoplay, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { API_BASE_URL } from "../../const";
import { Swiper as SwiperType } from "swiper";

interface OnlineService {
  id: number;
  title: string;
  description: string;
  color: string;
  visibility: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

function OnlineServicesSection() {
  const [services, setServices] = useState<OnlineService[]>([]);
  const swiperRef = useRef<SwiperType | null>(null);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get<OnlineService[]>(
          `${API_BASE_URL}/online-services`
        );
        const visibleServices = response.data.filter(
          (service) => service.visibility === 1
        );
        setServices(visibleServices);
      } catch (error) {
        console.error("Error fetching online services:", error);
      }
    };

    fetchServices();
  }, []);

  useEffect(() => {
    if (swiperRef.current && services.length > 0) {
      swiperRef.current.autoplay.start();
      swiperRef.current.update();
    }
  }, [services]);

  return (
    <div className="pl-[5%] md:px-[20%] py-16 bg-background">
      <div className="relative">
        <h1 className="text-xl font-extrabold text-left">
          VOS SERVICES EN LIGNE
        </h1>
        <button className="rounded-full hidden absolute -left-14 top-1/2 lg:block custom-prev transition bg-[#675a5a] hover:bg-primary text-white p-3">
          <FaChevronLeft />
        </button>
        <button className="rounded-full hidden absolute lg:block -right-14 top-1/2 custom-next transition bg-[#675a5a] hover:bg-primary text-white p-3">
          <FaChevronRight />
        </button>
        <Swiper
          onSwiper={(swiper: SwiperType) => {
            swiperRef.current = swiper;
            if (services.length > 0) {
              swiper.autoplay.start();
            }
          }}
          modules={[Navigation, A11y, Autoplay, Scrollbar]}
          slidesPerView={1}
          navigation={{
            nextEl: ".custom-next",
            prevEl: ".custom-prev",
          }}
          loop={services?.length > 1}  
          scrollbar={{ draggable: true }}
          pagination={{ clickable: true }}
          className="max-w-[100vw] h-[500px] sm:h-[60vh] lg:h-[70vh] 2xl:h-[50vh] mt-8 text-white gap-x-2"
          breakpoints={{
            320: {
              slidesPerView: 1.2,
              spaceBetween: 0,
            },
            800: {
              slidesPerView: 1.2,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 2.2,
              spaceBetween: 0,
            },
            1400: {
              slidesPerView: 3.2,
              spaceBetween: 0,
            },
          }}
        >
          {services.map((service) => (
            <SwiperSlide key={service.id} className="text-black">
              <div
                className="rounded-xl mx-2 p-7 h-[90%] sm:h-[70%] md:h-[95%] text-left"
                style={{ backgroundColor: service.color }}
              >
                <h2 className="text-base font-extrabold">{service.title}</h2>
                <div
                  className="justify-between mt-6 text-base"
                  dangerouslySetInnerHTML={{ __html: service.description }}
                ></div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default OnlineServicesSection;
