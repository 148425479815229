
import React, { useEffect, useState } from "react";
import axios from "axios";
import RegulationCard from "./RegulationCard";
import { API_BASE_URL } from "../../const";
import { Regulation } from "../../models/Regulation";

function NewRegulationsSection() {
  const [regulations, setRegulations] = useState<Regulation[]>([]);

  useEffect(() => {
    const fetchRegulations = async () => {
      try {
        const response = await axios.get<Regulation[]>(`${API_BASE_URL}/nouvellereglements`);
        const visibleRegulations = response.data.filter((reg) => reg.visibility === 1);
        setRegulations(visibleRegulations);
      } catch (error) {
        console.error("Error fetching regulations:", error);
      }
    };

    fetchRegulations();
  }, []);

  return (
    <section className="bg-accent">
      <h1 className="md:text-left mb-10">NOUVELLES RÉGLEMENTATIONS INCLUSES</h1>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        {regulations.map((regulation) => (
          <RegulationCard
            key={regulation.id}
            title={regulation.title}
            description={regulation.description}
            color={regulation.color}
          />
        ))}
      </div>
    </section>
  );
}

export default NewRegulationsSection;