import React from "react";
import PageNotFound404 from "../assets/illustrations/404.svg";

function NoPage() {
  return (
    <div>
      <h1>Page not found.</h1>
      <img src={PageNotFound404} alt="" />
      <a className="text-4xl" href="/">
        Go back to home
      </a>
    </div>
  );
}

export default NoPage;
