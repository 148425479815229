import React from "react";
// Import Swiper React components
import { Navigation, A11y, Autoplay, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

interface ColoredCardsSliderProps {
  title?: string;
  cards: {
    title: string;
    color: string;
    description: string;
    // items: {
    //   title: string;

    // }[];
  }[];
}

const ColoredCardsSlider: React.FC<ColoredCardsSliderProps> = ({
  title,
  cards,
}) => {
  return (
    <section>
      <div className="relative">
        <h1 className="text-xl font-extrabold text-left">{title}</h1>
        <button className="rounded-full hidden absolute -left-14 top-1/2 lg:block custom-prev transition bg-[#675a5a] hover:bg-primary text-white p-3">
          <FaChevronLeft />
        </button>
        <button className="rounded-full hidden absolute lg:block -right-14 top-1/2  custom-next transition bg-[#675a5a] hover:bg-primary text-white p-3">
          <FaChevronRight />
        </button>
        <Swiper
          modules={[Navigation, A11y, Autoplay, Scrollbar]}
          slidesPerView={1}
          navigation={{
            nextEl: ".custom-next",
            prevEl: ".custom-prev",
          }}
          loop={cards?.length > 1}
          scrollbar={{ draggable: true }}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          pagination={{ clickable: true }}
          className="max-w-[100vw] h-[90vh] sm:h-[60vh] md:h-[55vh] xl:h-[60vh] lg:h-[60vh] 2xl:h-[50vh] mt-8 text-white gap-x-2 mb-[2%]"
          breakpoints={{
            320: {
              slidesPerView: 1.2,
              spaceBetween: 0,
            },
            800: {
              slidesPerView: 1.2,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 2.2,
              spaceBetween: 0,
            },
            1400: {
              slidesPerView: 3.2,
              spaceBetween: 0,
            },
          }}
        >
          {cards.map((card, index) => (
            <SwiperSlide key={index} className="text-black">
              <div
                className={`rounded-xl mx-2 p-4 h-[80%] sm:h-[70%] md:h-[90%] text-left overflow-hidden`}
                style={{ backgroundColor: card.color }}
              >
                <h2 className="text-base font-extrabold">{card.title}</h2>

                <div
                  className="justify-between mt-6 h-[calc(100%-2.5rem)] overflow-y-scroll no-scrollbar"
                  style={{
                    msOverflowStyle: "none",  
                    scrollbarWidth: "none",  
                  }}
                >
                  {/* <p>{card.description}</p> */}
                  <p
                    className="text-sm"
                    dangerouslySetInnerHTML={{ __html: card.description || '' }}
                  />
                 
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default ColoredCardsSlider;
