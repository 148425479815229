import React from "react";
import HeroTemplate from "../components/common/HeroTemplate";
import FunctionalitiesSection from "../components/connect/FunctionalitiesSection";
import ObConnectServices from "../components/connect/ObConnectServices";
import ObConnectSubscriptionSection from "../components/connect/ObConnectSubscriptionSection";
import ObConnectClaimSection from "../components/connect/ObConnectClaimSection";
import { useEffect, useState } from "react";
import { API_BASE_URL, STORAGE_BASE_URL } from "../const";
import axios from "axios";

function OBConnect() {
  const [heroData, setHeroData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/heroes/9`);
        if (response.data.visiblity === 1) {
          setHeroData(response.data);
        } else {
          setError('Hero data is not visible.');
        }
      } catch (err) {
        setError('Failed to fetch hero data.');
      } finally {
        setLoading(false);
      }
    };

    fetchHeroData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (!heroData) return null;

  return (
    <div>
      {/* <HeroTemplate title="OB CONNECT" background={background} /> */}
      <HeroTemplate
        title={heroData.title}
        // subtitle={heroData.description.replace(/<\/?div>/g, '')}
        background={`${STORAGE_BASE_URL}/${heroData.imageurl}`}
      // color="#f1c5a8"
      // titleClass="lg:max-w-[40%] 2xl:max-w-[20%]"
      />
      <FunctionalitiesSection />
      <ObConnectServices />
      <ObConnectSubscriptionSection />
      <ObConnectClaimSection />
    </div>
  );
}

export default OBConnect;
