import React from "react";
import Hero from "../components/hero/HeroSection";
import WhyUsSection from "../components/about/WhyUsSection";
import PartnersSection from "../components/partners/PartnersSection";
import ChooseUsSection from "../components/about/ChooseUsSection";
import ContactUsSection from "../components/contact/ContactUsSection";
import TestimonialSection from "../components/about/TestimonialSection";
import PartnershipSection from "../components/about/PartnershipSection";
import ObConnectApiSection from "../components/about/ObConnectApiSection";
import WhatWeOfferSection from "../components/about/WhatWeOfferSection";
import ObConnectSection from "../components/connect/ObConnectSection";
import JoinUsSection from "../components/about/JoinUsSection";

const About = () => {
  return (
    <div className="">
      <Hero />
      <WhyUsSection />
      <PartnersSection />
      <ChooseUsSection />
      <ContactUsSection />
      <TestimonialSection />
      <PartnershipSection />
      <WhatWeOfferSection />
      <ObConnectSection backgroundColor="[#fffdf8]"/>
      <ObConnectApiSection />
      <JoinUsSection />
    </div>
  );
};

export default About;
