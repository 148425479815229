import React from "react";
import QuestionsSection from "../components/faq/QuestionsSection";
import { useEffect, useState } from "react";
import { API_BASE_URL, STORAGE_BASE_URL } from "../const";
import axios from "axios";
import HeroTemplate from "../components/common/HeroTemplate";


function Faq() {
  const [heroData, setHeroData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/heroes/5`);
        if (response.data.visiblity === 1) {
          setHeroData(response.data);
        } else {
          setError('Hero data is not visible.');
        }
      } catch (err) {
        setError('Failed to fetch hero data.');
      } finally {
        setLoading(false);
      }
    };

    fetchHeroData();
  }, []);
  return (
    <div>
      {/* <FaqHeroSection background={background} color="" /> */}
      <HeroTemplate
      title={heroData?.title}
      subtitle={heroData?.description.replace(/<\/?div>/g, '')} 
      background={`${STORAGE_BASE_URL}/${heroData?.imageurl}`}
      // color="#f1c5a8"
      // titleClass="lg:max-w-[40%] 2xl:max-w-[20%]"
    />
      <QuestionsSection />
    </div>
  );
}

export default Faq;
