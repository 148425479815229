import React from "react";
import Logo from "../assets/logo.png";
import LloydsLogo from "../assets/iconcoverholder.svg";
import Linkedin from "../assets/icons/linkedin.svg";
import { useContactModal } from "../hooks/useContactModal";

function Footer() {
  const { toggleContactModal } = useContactModal();
  return (
    <footer className="bg-black sm:px-[5%] md:px-[10%]  text-white text-left bg-pattern">
      <div className="block lg:flex justify-between px-[5%] sm:px-0 bg-black py-16">
        <div className="text-left text-white text-base items-center sm:items-start justify-between flex flex-col sm:mr-[10%] sm:w-[40%] lg:w-[60%] 2xl:w-[40%]">
          <img
            src={Logo}
            alt="logo"
            className="w-[60%] lg:w-[75%] h-auto mb-4 sm:mb-16"
          />
          <img src={LloydsLogo} alt="logo" className="h-20 sm:hidden" />

          <p className="text-[#d7d5d1] text-center sm:text-left my-2 sm:my-0 text-sm">
            24 rue de Kérandouret <br /> 29750 Loctudy <br />
            France
          </p>
          <a
            href="mailto:contact@oceanbridges.fr"
            className="block font-bold text-base my-4"
          >
            PAR MAIL
          </a>
          <a
            href="https://www.linkedin.com/company/ocean-bridges"
            target="_blank"
            className="flex-col sm:flex-row flex items-center sm:gap-4"
          >
            <p className="font-bold text-base lg:text-xs sm:my-4">
              SUIVEZ-NOUS
            </p>
            <img src={Linkedin} className="h-6 w-auto" />
          </a>
          <p className="mt-8 text-xs text-[#7d7d7d] hidden sm:block">
            OCEANBRIDGES &copy; 2023 Tous droits réservs
          </p>
        </div>
        <div className="footer-links">
          <div className="block lg:flex justify-between text-[.6rem] sm:text-xs sm:gap-x-4 text-white sm:text-[#7d7d7d]">
            <div className="flex-1">
              <h1 className="text-xs 2xl:text-base mt-4 mb-2 sm:my-4  lg:mt-0 text-white">
                INFORMATIONS <br className="hidden sm:block" /> REGLEMENTAIRES
              </h1>
              <a
                className="my-2 transition hover:text-white block font-bold cursor-pointer"
                href="/conditions"
              >
                CGU
              </a>

              <a
                href="/politic"
                className="my-2  transition hover:text-white block font-bold cursor-pointer"
              >
                Protection des données & Politique de confidentialité
              </a>
              <a
                href="/legal"
                className="my-2  transition  hover:text-white block font-bold cursor-pointer"
              >
                Mentions legales{" "}
              </a>
              <a
                href="/cookies"
                className="my-2  transition  hover:text-white block font-bold cursor-pointer"
              >
                Cookies & Securites
              </a>
              <a
                className="sm:font-bold text-white mt-4 sm:mt-8 block transition hover:text-primary hover:underline "
                href="/claim"
              >
                FAIRE UNE RECLAMATION
              </a>
              <a
                href="/ob-connect"
                className="block sm:font-bold text-white mt-4 transition hover:text-primary hover:underline "
              >
                API - OB CONNECT
              </a>
            </div>
            <Divider />
            <div className="flex-1 ">
              <h1 className="text-xs 2xl:text-base mt-4 mb-2 sm:my-4  lg:mt-0 text-white">
                OBTENIR VOTRE DEVIS
              </h1>
              <p className="font-bold text-white text-[.7rem] 2xl:text-xs">
                Vous exercez en tant que :
              </p>
              <a
                href="/real-estate-professions"
                className="my-3 block transition hover:text-white font-bold cursor-pointer"
              >
                Intermediaire D'assurance Immobilier & financier
              </a>
              <a
                href="/real-estate-financial-intermediary"
                className="block transition hover:text-white font-bold cursor-pointer"
              >
                Professionnel de l'immobilier
              </a>
            </div>

            <Divider />

            <div className="flex-1 ">
              <h1 className="text-xs 2xl:text-base mt-4 mb-2 sm:my-4  lg:mt-0 text-white">
                NOS OFFRES
              </h1>
              <a
                href="/real-estate-professions"
                className="my-1 block transition hover:text-white font-bold cursor-pointer"
              >
                Responsabilité Civile
              </a>
              <a
                href="/real-estate-professions"
                className="my-1 block transition hover:text-white font-bold cursor-pointer"
              >
                Garanție financière
              </a>
              <a onClick={toggleContactModal} className="my-1 block transition hover:text-white font-bold cursor-pointer">
                RCMS
              </a>
              <a onClick={toggleContactModal} className="my-1 block transition hover:text-white font-bold cursor-pointer">
                Protection juridique
              </a>
              <a onClick={toggleContactModal} className="my-1 block transition hover:text-white font-bold cursor-pointer">
                MRP
              </a>
              <a onClick={toggleContactModal} className="my-1 block transition hover:text-white font-bold cursor-pointer">
                Individuelle accident
              </a>
              <a onClick={toggleContactModal} className="my-1 block transition hover:text-white font-bold cursor-pointer">
                Assistance cursor-pointer
              </a>
            </div>

            <Divider />

            <div className="flex-1 ">
              <h1 className="text-xs 2xl:text-base mt-4 mb-2 sm:my-4  lg:mt-0 text-white">
                A PROPOS D'OCEAN BRIDGES
              </h1>
              <a
                href="/about"
                className="my-3 block transition hover:text-white font-bold cursor-pointer"
              >
                Pourquoi choisir Ocean Bridges
              </a>
              {/* <a
                href="/responsability"
                className="block my-3 transition hover:text-white"
              >
                Responsabilite Societale de l'Entreprise
              </a> */}
              <a
                href="/recruitment"
                className="my-3 block transition hover:text-white font-bold cursor-pointer"
              >
                Ocean Bridges recrute
              </a>
            </div>
            <img src={LloydsLogo} alt="logo" className="h-16 hidden sm:block" />
          </div>
          <p className="text-[.5rem] 2xl:text-xs text-center sm:text-left text-[#dfddd9] mt-8 2xl:mt-16">
            Ce site est edite par Ocean Bridges, societe par actions simplifiee
            au capital de 100 000 €, immatriculee au RCS de Quimper sous le
            n°980 005 011, dont le siege social est situe 24 rue de Kerandouret
            29750 LOCTUDY. Conseil et courtier en assurances (exercant
            conformement aux dispositions de l'article L521-2-1°b) du Code des
            Assurances - societe immatriculee a l'Organisme pour le Registre des
            Intermediaires en Assurances (ORIAS) sous le numero 23 006 811 -
            www.orias.fr. Ocean Bridges est soumis au contrôle de l'Autorite de
            Contrôle Prudentiel et de Resolution (ACPR), 4 place de Budapest
            75009 Paris. Directeur de la publication : Madame Charlotte LAMIDON.
          </p>
          <p className="mt-8 text-[.6rem] text-center text-white opacity-70  sm:hidden">
            OCEANBRIDGES &copy; 2023 Tous droits réservs
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

export function Divider() {
  return <div className="h-[1px] bg-white opacity-40 m-1 my-6"></div>;
}
