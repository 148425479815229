import Reason from "../about/Reason";

import protection from "../../assets/icons/protection.svg";
import politic from "../../assets/icons/politic.svg";

function SecuritySection() {
  const reasons = [
    {
      title: "Protection des données",
      subtitle:
        "Vos informations personnelles et financières sont sécurisées grâce à des protocoles de cryptage avancés.",
      image: protection,
    },
    {
      title: "Politique de confidentialité",
      subtitle:
        "Consultez notre politique de confidentialité pour comprendre comment vos donnees sont collectées, utilisées et protégées.",
      image: politic,
    },
  ];
  return (
    <div className="px-[5%] md:px-[20%] py-16 bg-primary text-white text-left lg:text-center">
      {/* todo: post  will insert */}
      <h1 className="text-xl font-bold mb-4">SÉCURITÉ ET CONFIDENTIALITÉ</h1>
      <h2 className="text-base">
        Votre sécurité est notre priorité. Ocean Bridges utilise des
        technologies de pointe pour protéger vos données et garantir la
        confidentialité de vos informations.
      </h2>
      <div className="grid xl:flex justify-between gap-6 mt-16">
        {reasons.map((reason, index) => (
          <Reason
            key={index}
            image={reason.image}
            title={reason.title}
            subtitle={reason.subtitle}
          />
        ))}
      </div>
    </div>
  );
}

export default SecuritySection;
