import React, { useEffect, useState } from "react";
import axios from "axios";
import background from "../../assets/img/about/obconnect.jpg";
import { BoxReveal } from "../box-reveal";
import Button from "../Button";
import { Post } from "../../models/Post";
import { API_BASE_URL } from "../../const";

function SinisterSupport() {
  const [post, setPost] = useState<Post | null>(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get<Post>(
          // "http://64.226.126.75/api/posts/16"
          `${API_BASE_URL}/posts/16`
        );
        if (response.data.status === "PUBLISHED") {
          setPost(response.data);
        }
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    };

    fetchPost();
  }, []);

  if (!post) {
    return null; // Or a loading spinner, etc.
  }

  return (
    <section className="relative p-0">
      <img
        src={background}
        alt=""
        className="object-cover w-full h-full absolute inset-0 z-0"
      />
      <div className="h-full xl:max-w-[70%] px-[5%] md:px-[20%] py-[5%] items-center justify-center z-50 bg-transparent">
        <BoxReveal boxColor={""} duration={0.5}>
          <h1 className=" text-left text-white text-lg  md:text-2xl font-bold">
            {post.title}
          </h1>
        </BoxReveal>

        <BoxReveal boxColor={""} duration={0.5}>
          <div
            className="text-white mt-[1.5rem] text-left text-sm md:text-base"
            dangerouslySetInnerHTML={{ __html: post.body }}
          />
        </BoxReveal>

        <div className="m-6"></div>

        <BoxReveal boxColor={""} duration={0.5}>
          <Button
            link="#"
            text="EN SAVOIR PLUS"
            color="white"
            textColor="primary"
          />
        </BoxReveal>
      </div>
    </section>
  );
}

export default SinisterSupport;