import React, { useEffect, useState } from "react";
import ColoredCardsSlider from "../common/ColoredCardsSliderObConnect";
import { API_BASE_URL } from "../../const";
import { Functionality } from "../../models/Functionality";



function FunctionalitiesSection() {
  const [functionalities, setFunctionalities] = useState<Functionality[]>([]);

  useEffect(() => {
    const fetchFunctionalities = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/ob-connect-functionalities`);
        const data: Functionality[] = await response.json();
        const visibleFunctionalities = data.filter((func) => func.visibility === 1);
        setFunctionalities(visibleFunctionalities);
      } catch (error) {
        console.error("Failed to fetch functionalities:", error);
      }
    };

    fetchFunctionalities();
  }, []);

  const cards = functionalities.map((func) => ({
    title: func.text,
    color: func.color,
    description: func.body,
  }));

  return <ColoredCardsSlider title="FONCTIONNALITÉS" cards={cards} />;
}



export default FunctionalitiesSection;
