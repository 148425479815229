import React from "react";

interface SliderProps {
    activeStep: number;
}

const Slider: React.FC<SliderProps> = ({ activeStep }) => {
    const steps = [
        "DÉMARRER",
        "IDENTIFICATION",
        "TYPE DE RECLAMATION",
        "DÉCLARER",
    ];

    const isStepActive = (index: number) => index <= activeStep;

    return (
        <div className="h-[5.25rem] bg-primary px-2.5 md:px-[20%] py-5 space-y-3.5 text-white text-xs font-bold">
            <div className="flex justify-between items-center">
                {steps.map((step, index) => (
                    <span
                        key={index}
                        className={`max-w-24 md:max-w-max ${
                            isStepActive(index) ? "font-bold" : "font-medium opacity-30"
                        }`}
                    >
            {step}
          </span>
                ))}
            </div>
            <div className="flex justify-between items-center mx-7">
                {steps.map((_, index) => (
                    <React.Fragment key={index}>
                        <div>
                            <div
                                className={`bg-white rounded-full w-1.5 h-1.5 ${
                                    isStepActive(index) ? "" : "opacity-30"
                                }`}
                            />
                        </div>
                        {index < steps.length - 1 && (
                            <hr
                                className={`h-px w-full bg-white ${
                                    isStepActive(index + 1) ? "" : "opacity-30"
                                }`}
                            />
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default Slider;
