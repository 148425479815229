import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { FormContext } from "../../context/ReclamaionFormContext";
import Slider from "../../components/Slider";
import api from "../../api/axios";

function FirstConfirmation() {
  const navigate = useNavigate();

  const { formData } = useContext(FormContext);

  const fieldMapping = {
    insured: "ocean_bridges_insurance_interest",
    against: "ocean_bridges_claim_history",
    otherCase: "other_cases",
    insuranceProposal: "insurance_proposal",
    contractManagement: "contract_management",
    information: "information_report",
    claim: "claim",
    contract: "contract",
    subscription: "contract_subscription",
    premium: "due_premium_payment",
    personalInfo: "personal_information",
    other: "other_claim_cases",
    otherCases: "other_claim_details",
    details: "claim_details",

    files: "files",

    alreadyInsured: "already_insured",
    lastName: "last_name",
    firstName: "first_name",
    address: "address",
    adhesionDate: "adhesion_date",
    adhesionNumber: "adhesion_number",
    email: "email",
    phone: "phone",
    clientNumber: "client_number",
    contractNumber: "contract_number",
    claimNumber: "claim_number",
    receiptNumber: "receipt_number",
  };

  const handleSubmit = async () => {
    const dataToSend = Object.keys(fieldMapping).reduce<{
      [key: string]: string | number | File[];
    }>((acc, key) => {
      const mappedKey = fieldMapping[key as keyof typeof fieldMapping];
      const value = formData[key as keyof typeof formData];
      if (
        [
          "insured",
          "against",
          "insuranceProposal",
          "contract",
          "contractManagement",
          "subscription",
          "premium",
          "personalInfo",
          "other",
          "claim",
          "information",
          "alreadyInsured",
        ].includes(key)
      ) {
        acc[mappedKey] = value === "Oui" ? 1 : 0;
      } else {
        acc[mappedKey] = value;
      }
      return acc;
    }, {});

    try {
      await api.post("/reclamations", dataToSend);
      navigate("/claim/confirmation");
    } catch (error) {
      console.error(
        "Une erreur s'est produite lors de l'envoi du formulaire : ",
        error
      );
    }
  };

  return (
    <>
      <Slider activeStep={3} />

      <section className="bg-background">
        <div className="text-center p-4 sm:p-6 md:p-8 lg:p-0">
          <h1 className="text-primary text-base font-semibold md:text-lg">
            CONFIRMATION
          </h1>
          <span className="text-primary text-sm font-semibold whitespace-nowrap">
            AVANT VALIDATIONFINALE DE VOTRE RÉCLAMATION
          </span>
          <div className="mt-2 px-3 lg:px-16 pt-8 flex items-center justify-between">
            <p className="text-black text-xs text-left sm:text-base">
              Ocean Bridges est désigné en premier lieu pour statuer sur votre
              réclamation. Nous nous engageons à accuser réception de votre
              réclamation dans un délai maximal de 10 jours ouvrables à compter
              de son envoi.
              <br />
              Nous nous engageons également à répondre à votre réclamation
              écrite dans un délai maximal de deux mois à compter de son envoi.
              <br />
              <br />
              <strong>Votre désaccord persiste</strong>
              <br />
              Si la réponse apportée à votre réclamation ne vous satisfait pas,
              et en tout état de cause à l’issue d’un délai de deux mois à
              compter de l’envoi de votre première réclamation écrite – que vous
              ayez ou non reçu une réponse, vous pouvez saisir le Médiateur de
              l’Assurance :
              <br />
              <ul className="list-disc ml-10 lg:ml-20">
                <li>
                  Par voie postale à l’adresse suivante :
                  <br />
                  <div className="text-center">
                    Monsieur le Médiateur de l’Assurance
                    <br />
                    TSA 50110
                    <br />
                    75441 Paris Cedex 09.
                  </div>
                </li>
                <li>
                  Par voie électronique :{" "}
                  <a
                    href="https://www.mediation-assurance.org"
                    className="text-primary block sm:inline text-center"
                  >
                    www.mediation-assurance.org
                  </a>
                </li>
              </ul>
              <br />
              <br />
              Le médiateur ne peut pas être saisi lorsque :
              <br />
              <br />
              <ul className="list-disc ml-10 lg:ml-20">
                <li>
                  Vous ne justifiez pas avoir tenté, au préalable, de résoudre
                  votre litige directement auprès de Ocean Bridges par une
                  réclamation écrite selon les modalités prévues ci-dessus ;
                </li>
                <li>Votre demande est manifestement infondée ou abusive ;</li>
                <li>
                  Le litige a été précédemment examiné ou est en cours d’examen
                  par un autre médiateur ou par un tribunal ;
                </li>
                <li>
                  Vous avez introduit votre demande auprès du médiateur dans un
                  délai supérieur à un an à compter de votre réclamation écrite
                  auprès du professionnel ;
                </li>
                <li>Le litige n’entre pas dans son champ de compétence.</li>
              </ul>
            </p>
          </div>

          <div className="flex gap-4 justify-center md:justify-between sm:mx-16 mt-20">
            <button
              className="bg-black font-semibold text-white text-xs rounded-[0.875rem] sm:rounded-[1.25rem] w-[7.625rem] sm:w-[11.75rem] sm:text-base px-6 py-2 my-6"
              onClick={() => navigate("/claim/contact")}
            >
              PRÉCÉDENT
            </button>
            <button
              className="bg-primary font-semibold text-white text-xs rounded-[0.875rem] sm:rounded-[1.25rem] w-[7.625rem] sm:w-[11.75rem] sm:text-base px-6 py-2 my-6"
              onClick={handleSubmit}
            >
              ENVOYER
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default FirstConfirmation;
