import { useState } from "react";
import axios from "axios";
import { IoMdCloseCircle } from "react-icons/io";
import { useContactModal } from "../../hooks/useContactModal";
import { motion } from "framer-motion";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/logo.png";
import { API_BASE_URL } from "../../const";

function ContactModal() {
  const { isContactOpen, toggleContactModal } = useContactModal();

  const [formData, setFormData] = useState({
    name: "",
    mail: "",
    phone: "",
    entreprise: "",
    message: "",
  });

  const [errors, setErrors] = useState<any>({});  // State for storing form errors

  // Handle form data change
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Basic validation rules
  const validateForm = () => {
    const newErrors: any = {};

    // Validate name
    if (!formData.name.trim()) {
      newErrors.name = "Le nom est requis";
    }

    // Validate email format
    if (!formData.mail.trim()) {
      newErrors.mail = "L'adresse mail est requise";
    } else if (!/\S+@\S+\.\S+/.test(formData.mail)) {
      newErrors.mail = "Le format de l'adresse mail est incorrect";
    }

    // Validate phone number (optional but can add regex for specific formats)
    if (!formData.phone.trim()) {
      newErrors.phone = "Le téléphone est requis";
    } else if (!/^\d{10,15}$/.test(formData.phone)) {
      newErrors.phone = "Le téléphone doit contenir entre 10 et 15 chiffres";
    }

    // Validate message
    if (!formData.message.trim()) {
      newErrors.message = "Le message est requis";
    }

    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!validateForm()) {
      // If form is invalid, stop submission and show error messages
      toast.error("Veuillez corriger les erreurs dans le formulaire");
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/contacts`, formData);
   

      // Show success toast
      toast.success("Formulaire envoyé avec succès!");

      // Reset form
      setFormData({
        name: "",
        mail: "",
        phone: "",
        entreprise: "",
        message: "",
      });

      // Close modal
      toggleContactModal();
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Erreur lors de l'envoi du formulaire.");
    }
  };

  return (
    <motion.div
      className="lg:flex relative"
      initial={{ opacity: 0, y: -20 }} // Animation starting state
      animate={{ opacity: 1, y: 0 }}  // Animation end state
      exit={{ opacity: 0, y: -20 }}    // Animation when component unmounts
      transition={{ duration: 0.5 }}   // Animation duration
    >
      <ToastContainer />
      <div className="p-4 text-left">
        <h1 className="text-lg font-bold">Demande de rappel</h1>
        <p className="text-sm my-2">
          Si vous avez besoin de plus d'informations ou si vous avez des
          questions, envoyez-nous un message
        </p>
        <form className="space-y-3" onSubmit={handleSubmit}>
          <div>
            <label className="block text-sm font-medium" htmlFor="name">
              Nom
            </label>
            <input
              className="text-lg w-full border-b-2 bg-transparent border-b-onAccent p-1 focus:outline-none focus:border-b-blue-300"
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
            {errors.name && (
              <p className="text-red-500 text-sm mt-1">{errors.name}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium" htmlFor="mail">
              Adresse mail
            </label>
            <input
              className="text-lg w-full border-b-2 bg-transparent border-b-onAccent p-1 focus:outline-none focus:border-b-blue-300"
              type="email"
              id="mail"
              name="mail"
              value={formData.mail}
              onChange={handleInputChange}
            />
            {errors.mail && (
              <p className="text-red-500 text-sm mt-1">{errors.mail}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium" htmlFor="phone">
              Téléphone
            </label>
            <input
              className="text-lg w-full border-b-2 bg-transparent border-b-onAccent p-1 focus:outline-none focus:border-b-blue-300"
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
            />
            {errors.phone && (
              <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium" htmlFor="entreprise">
              Entreprise
            </label>
            <input
              className="text-lg w-full border-b-2 bg-transparent border-b-onAccent p-1 focus:outline-none focus:border-b-blue-300"
              type="text"
              id="entreprise"
              name="entreprise"
              value={formData.entreprise}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="message">
              Message
            </label>
            <textarea
              className="w-full border border-onAccent rounded p-2 focus:outline-none focus:ring focus:border-blue-300"
              id="message"
              name="message"
              rows={4}
              value={formData.message}
              onChange={handleInputChange}
            ></textarea>
            {errors.message && (
              <p className="text-red-500 text-sm mt-1">{errors.message}</p>
            )}
          </div>
          <button
            className="bg-primary text-white mt-4 mx-4 px-2 py-2 rounded-full text-sm md:text-lg hover:bg-primary-dark focus:outline-none focus:ring"
            type="submit"
          >
            ENVOYEZ
          </button>
        </form>
      </div>
      <div className="bg-black text-white p-4 text-left justify-between flex-col flex">
        <div>
          <IoMdCloseCircle
            onClick={toggleContactModal}
            className="float-right mb-2"
          />
          <img src={logo} alt="logo" />
          <p className="opacity-60 text-sm my-4">
            24 rue de Kerandouret 29750 Loctudy
          </p>
          <p className="opacity-60 text-sm">
            +33 02 30 99 51 51 contact@oceanbridges.fr
          </p>
        </div>
      </div>
    </motion.div>
  );
}

export default ContactModal;
