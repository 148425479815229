import React from "react";

interface ReasonProps {
  image: string;
  title: string;
  subtitle: string;
}


const Reason: React.FC<ReasonProps> = ({ image, title, subtitle }) => {
  return (
    <div className="flex flex-row 2xl:flex-row gap-4 text-left lg:text-center">
      <img
        src={image}
        alt={title}
        className=" w-20 h-[80%] mb-6 sm:mx-4 rounded-md mx-auto self-left md:self-auto lg:self-auto 3xl:self-baseline"
      />
      <div className="text-left flex flex-col">
        <h2 className="text-left sm:text-left md:text-left lg:text-left text-lg lg:text-xl 2xl:text-xl font-bold md:h-auto">
          {title}

          <div className="text-xs md:text-base mt-2 mx-0 text-justify sm:text-justify md:text-left lg:text-left font-normal" dangerouslySetInnerHTML={{ __html: subtitle }} />

        </h2>
        {/* <p className="text-xs md:text-base mt-4 mx-4 text-justify sm:text-justify md:text-justify lg:text-justify">{subtitle}</p> */}

      </div>
    </div>
  );
};

export default Reason;
