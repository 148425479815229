import JoinUsSection from "../components/about/JoinUsSection";
import AdvantagesSection from "../components/advantages/AdvantagesSection";
import CustomAssuranceSection from "../components/custom-offers/AssuranceSection";
import CustomOffersSection from "../components/custom-offers/CustomOffers";
import PartnersSection from "../components/partners/PartnersSection";

function CustomOffers() {
  return (
    <div className="">
      <CustomAssuranceSection />
      <PartnersSection />
      <CustomOffersSection />
      <AdvantagesSection bg="black" />
      <JoinUsSection />
    </div>
  );
}

export default CustomOffers;
