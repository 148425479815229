import AboutSection from "../components/about/AboutSection";
import AdvantagesSection from "../components/advantages/AdvantagesSection";
import ObConnectSection from "../components/connect/ObConnectSection";
import ContactUsSection from "../components/contact/ContactUsSection";
import HeroSeciton from "../components/hero/HeroSection";
import LinksSections from "../components/links/LinksSections";
import OffersSection from "../components/offers/OffersSection";
import PartnersSection from "../components/partners/PartnersSection";


function Home() {
  return (
    <div>
      <HeroSeciton />
      <AboutSection />
      <OffersSection />
      <PartnersSection />
      <ContactUsSection />
      <AdvantagesSection />
      <ObConnectSection />
      <LinksSections/>
    </div>
  );
}

export default Home;
