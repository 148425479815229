import React, { useEffect, useState } from "react";
import axios from "axios";
import { Advantage } from "../../models/RecruitmentAvantages";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";



function WhyWorkHere() {
  const [advantages, setAdvantages] = useState<Advantage[]>([]);

  useEffect(() => {
    // Fetch data from the API
    axios
      // .get<Advantage[]>("http://64.226.126.75/api/recruitment-avantages")
      .get<Advantage[]>(`${API_BASE_URL}/recruitment-avantages`)
      .then((response) => {
        // Filter advantages based on visibility
        const visibleAdvantages = response.data.filter(
          (advantage) => advantage.visible === 1
        );
        setAdvantages(visibleAdvantages);
      })
      .catch((error) => {
        console.error("Error fetching recruitment advantages:", error);
      });
  }, []);

  return (
    <section className="bg-black text-white">
      <h1 className="text-left text-lg md:text-xl mb-6 md:mb-12">
        POURQUOI TRAVAILLER CHEZ NOUS ?
      </h1>
      <div className="grid lg:grid-cols-4 gap-6">
        {advantages.map((advantage) => (
          // @ts-ignore
          <div key={advantage.id} className="p-4" style={{textAlign: "-webkit-center"}}>
            <img
              src={`${STORAGE_BASE_URL}/${advantage.icon}`}
              alt={advantage.title}
              className="w-16 h-16 aspect-square mb-6 "
            />
            <h2 className="text-xl mb-4 lg:mb-8 font-bold">
              {advantage.title}
            </h2>
            <p
              className="text-base"
              dangerouslySetInnerHTML={{ __html: advantage.description }}
            />
          </div>
        ))}
      </div>
    </section>
  );
}

export default WhyWorkHere;