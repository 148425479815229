import React, { useState } from "react";
import Button from "../Button";
import CookiesOption from "./CookiesOption"; // Make sure the path is correct

interface OptionProps {
  index: number;
  title: string;
  text: string;
  moreInfo: string;
  checked: boolean;
}

interface CookiesSettingsProps {
  close: () => void;
}

const CookiesSettings: React.FC<CookiesSettingsProps> = ({ close }) => {
  const [options, setOptions] = useState<OptionProps[]>([
    {
      index: 0,
      title: "Publicité personnalisée",
      text: "Ocean Bridges et ses partenaires utilisent des traceurs afin d'afficher de la publicité personnalisée en fonction de votre navigation et de votre profil.",
      moreInfo:
        "La personnalisation de la publicité englobe différentes opérations techniques : La sélection d’une publicité en fonction du profil, Le plafonnement de l’affichage",
      checked: true,
    },
    {
      index: 1,
      title: "Publicité géolocalisée",
      text: "Ocean Bridges et ses partenaires utilisent des traceurs afin d'afficher de la publicité personnalisée en fonction de votre localisation.",
      moreInfo:
        "La publicité géolocalisée utilise votre emplacement pour afficher des annonces pertinentes.",
      checked: false,
    },
    {
      index: 2,
      title: "Partage sur les réseaux sociaux",
      text: "Ocean Bridges et ses partenaires utilisent des traceurs pour permettre de partager du contenu sur les réseaux sociaux.",
      moreInfo:
        "Le partage sur les réseaux sociaux permet de publier du contenu sur vos profils sociaux.",
      checked: false,
    },
  ]);

  const handleCheckboxChange = (index: number) => {
    setOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index].checked = !newOptions[index].checked;
      return newOptions;
    });
  };

  const checkAll = () => {
    setOptions((prevOptions) => {
      const newOption = [...prevOptions];
      newOption.map((option) => (option.checked = true));
      return newOption;
    });
  };

  const removeAll = () => {
    setOptions((prevOptions) => {
      const newOption = [...prevOptions];
      newOption.map((option) => (option.checked = false));
      return newOption;
    });
  };

  return (
    <div className="mt-6 text-left">
      <div className="p-4 md:px-12 md:pb-8">
        <h1 className="text-primary text-lg font-bold">
          Personnaliser mes choix
        </h1>
        <p className="my-4 text-xs lg:text-lg">
          Ocean Bridges conserve votre choix pendant 6 mois. Vous pouvez changer
          d'avis à tout moment en cliquant sur le lien en pied de page « gérer
          les cookies »
        </p>
        <div className="grid grid-cols-2 lg:flex gap-4">
          <Button
            onClick={() => removeAll()}
            size="2xs"
            // sizeSm="xs"
            text="TOUT REFUSER"
            textColor="white"
            color="black"
            sizeSm="[.6rem]"
            width="100"
          />
          <Button
            onClick={() => checkAll()}
            size="2xs"
            // sizeSm="xs"
            text="TOUT ACCEPTER"
            textColor="white"
            color="primary"
            sizeSm="[.6rem]"
            width="100"
          />
        </div>
        <div className="h-[40vh] overflow-y-auto mt-4">
          {options.map((option, index) => (
            <CookiesOption
              key={index}
              index={index}
              title={option.title}
              text={option.text}
              moreInfo={option.moreInfo}
              checked={option.checked}
              onCheckboxChange={handleCheckboxChange}
            />
          ))}
        </div>
      </div>
      <div className="bg-black flex flex-col gap-4 lg:flex-row p-4 md:p-8 lg:px-12 justify-between items-center">
        <a
          href="/cookies"
          className="text-white opacity-70 text-xs sm:text-sm lg:text-base underline lg:float-left block"
        >
          Consulter la page « gérer des cookies »
        </a>
        <div className="flex justify-center">
          <Button
            onClick={close}
            size="sm"
            text="VALIDER MES CHOIX"
            textColor="white"
            color="primary"
            sizeSm="[.6rem]"
            width="100"
          />
        </div>
      </div>
    </div>
  );
};

export default CookiesSettings;
