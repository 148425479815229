// import React from "react";
import Calender from "../../assets/img/links/Calendar_Pattern.png";
import Files from "../../assets/img/links/Files_Pattern.png";
import Info from "../../assets/img/links/Info_Pattern.png";
import Jobs from "../../assets/img/links/Jobs_Pattern.png";
import LawP from "../../assets/img/links/law_Pattern.png";
import NewP from "../../assets/img/links/News_Pattern.png";
import RC from "../../assets/img/links/RC_Pattern.png";
import Tattoo from "../../assets/img/links/Tattoo_Pattern.png";
import { useDeviceSize } from "react-device-sizes";
import plus from "../../assets/icons/plus.png";

function LinksSections() {
  const { xsDown } = useDeviceSize();

  const links = [
    { title: "ACTUALITÉ", images: NewP, link: "/news" },
    { title: "DOSSIER", images: Files, link: "/files" },
    { title: "CALENDRIER", images: Calender, link: "/calendar" },
    { title: "FAQ", images: Info, link: "/faq" },
    {
      title: xsDown
        ? "OFFRE TATOUER"
        : "OFFRE INTERMEDIAIRE D'ASSURANCE IMMOBILIER & FINANCIER",
      images: Tattoo,
      link: "/real-estate-professions",
    },
    { title: "RECRUTEMENT", images: Jobs, link: "/recruitment" },
    { title: "RÉGLEMENTATION", images: LawP, link: "/regulation" },
    { title: "SINISTRE", images: RC, link: "/sinister" },
  ];
  return (
    <section className="w-full">
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-3  p-[5%]">
        <div className="link-cards-column">
          <a href={links[0].link} className="link-card">
            <img
              src={links[0].images}
              alt=""
              className="link-card-image"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
            <div className="link-card-text-container">
              <p className="link-card-text">{links[0].title}</p>
              <img src={plus} alt="" className="h-[20%] w-[20%] font-bold" />
            </div>
          </a>
          <a href={links[4].link} className="link-card">
            <div className="bg-[#54cca7] text-white flex justify-center absolute top-0 p-4 items-center w-full z-10">
              <p className="text-sm sm:text-base">NOUVEAUTÉ</p>
            </div>
            <img
              src={links[4].images}
              alt=""
              className="link-card-image"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
            <div className="link-card-text-container">
              <p className="link-card-text font-semibold">{links[4].title}</p>
              <img src={plus} alt="" className="h-[20%] w-[20%] font-bold mt-0 2xl:mt-[30%]" />
            </div>
          </a>
        </div>
        <div className="link-cards-column">
          <a href={links[1].link} className="link-card">
            <img
              src={links[1].images}
              alt=""
              className="link-card-image"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
            <div className="link-card-text-container">
              <p className="link-card-text">{links[1].title}</p>
              <img src={plus} alt="" className="h-[20%] w-[20%] font-bold" />
            </div>
          </a>
          <a href={links[5].link} className="link-card">
            <img
              src={links[5].images}
              alt=""
              className="link-card-image"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
            <div className="link-card-text-container">
              <p className="link-card-text">{links[5].title}</p>
              <img src={plus} alt="" className="h-[20%] w-[20%] font-bold" />
            </div>
          </a>
        </div>
        <div className="link-cards-column">
          {/* <a href={links[2].link} className="link-card">
            <img
              src={links[2].images}
              alt=""
              className="link-card-image"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
            <div className="link-card-text-container">
              <p className="link-card-text">{links[2].title}</p>
              <img src={plus} alt="" className="h-[20%] w-[20%] font-bold" />
            </div>
          </a> */}
          <a href={links[6].link} className="link-card">
            <img
              src={links[6].images}
              alt=""
              className="link-card-image"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
            <div className="link-card-text-container">
              <p className="link-card-text">{links[6].title}</p>
              <img src={plus} alt="" className="h-[20%] w-[20%] font-bold" />
            </div>
          </a>
        </div>
        {/* <div className="link-cards-column">
          {/* <a href={links[3].link} className="link-card">
            <img
              src={links[3].images}
              alt=""
              className="link-card-image"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
            <div className="link-card-text-container">
              <p className="link-card-text">{links[3].title}</p>
              <img src={plus} alt="" className="h-[20%] w-[20%] font-bold" />
            </div>
          </a> */}
          {/* <a href={links[7].link} className="link-card">
            <img
              src={links[7].images}
              alt=""
              className="link-card-image"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
            <div className="link-card-text-container">
              <p className="link-card-text">{links[7].title}</p>
              <img src={plus} alt="" className="h-[20%] w-[20%] font-bold" />
            </div>
          </a> */}
        {/*  </div> */}
        
      </div>
    </section>
  );
}

export default LinksSections;
