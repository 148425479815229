
import React, { useEffect, useState } from "react";
import Why1 from "../../assets/img/about/why1.svg";
import Why2 from "../../assets/img/about/why2.svg";
import Why3 from "../../assets/img/about/why3.svg";
import Reason from "./Reason";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { CompanyAdvantage, MappedAdvantage } from "../../models/CompanyAdvantage";
import { API_BASE_URL } from "../../const";

function WhyUsSection() {
  const [advantages, setAdvantages] = useState<MappedAdvantage[]>([]);

  useEffect(() => {

    fetch(`${API_BASE_URL}/compamy-avantages`)
      .then((response) => response.json())
      .then((data: CompanyAdvantage[]) => {
        // Filter advantages based on visibility
        const visibleAdvantages = data.filter((advantage) => advantage.visibility === 1);
        // Map the advantages to the required structure
        const mappedAdvantages: MappedAdvantage[] = visibleAdvantages.map((advantage, index) => ({
          title: advantage.title,
          subtitle: advantage.description.replace(/<[^>]+>/g, ''), // Remove HTML tags
          image: [Why1, Why2, Why3][index % 3], // Alternate images
        }));
        setAdvantages(mappedAdvantages);
      })
      .catch((error) => {
        console.error("Error fetching company advantages:", error);
      });
  }, []);

  return (
    <div className="sm:px-[5%] md:px-[15%] lg:px-[16%] xl:px-[20%] py-16 bg-primary text-white text-left">
      <h1 className="text-lg md:text-2xl text-center sm:text-left">
        <span className="hidden sm:inline">A PROPOS - </span>POURQUOI CHOISIR
        OCEAN BRIDGES <span className="sm:hidden">?</span>
      </h1>
      <div className="mt-16 ">
        <Swiper
          modules={[A11y, Autoplay]}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          pagination={{ clickable: true }}
          className="max-w-[100vw] mt-4 text-white"
          breakpoints={{
            768: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 5,
            },
            1400: {
              slidesPerView: 3,
              spaceBetween: 5,
            },
          }}
        >
          {advantages.map((advantage, index) => (
            <SwiperSlide key={index} className="px-16 sm:px-0">
              <Reason
                key={index}
                image={advantage.image}
                title={advantage.title}
                subtitle={advantage.subtitle}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default WhyUsSection;