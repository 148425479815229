import React from "react";

interface OfferCardProps {
  title: string;
  description: string;
  icon: string;
  iconBgColor: string;
  textBgColor: string;
}

const OfferCard: React.FC<OfferCardProps> = ({
  title,
  description,
  icon,
  iconBgColor,
  textBgColor,
}) => {
  const lines = description.split("\n");

  return (
    <div className="bg-[#f7f4ea] text-left">
      <div className="flex h-[30%] w-full">
        <img
          src={icon}
          alt=""
          className="p-3 aspect-square"
          style={{ backgroundColor: iconBgColor }}
        />
        <div
          className="p-4 flex flex-col justify-center w-full"
          style={{ backgroundColor: textBgColor }}
        >
          <h3 className="text-xl font-semibold">{title}</h3>
        </div>
      </div>
      {/* <p className="text-base p-4">
        {lines.map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
      </p> */}
       <div className="text-base p-4" dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

export default OfferCard;
