import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";
import Offer from "./Offer";

interface OfferProps {
  color?: string;
}

interface OfferData {
  id: number;
  icon: string;
  title: string;
  visible: number;
  color_background: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

const Offers: React.FC<OfferProps> = ({ color }) => {
  const [offers, setOffers] = useState<OfferData[]>([]);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/our-offers`);
        const data: OfferData[] = await response.json();

        // Filter visible offers
        const visibleOffers = data.filter(
          (offer) => offer.visible === 1
        );
        setOffers(visibleOffers);
      } catch (error) {
        console.error("Failed to fetch offers:", error);
      }
    };

    fetchOffers();
  }, []);

  // Derive offers to display based on background color
  const offersToDisplay = color === "black"
    ? offers.filter((offer) => offer.color_background === "#000000")
    : offers.filter((offer) => offer.color_background !== "#000000");

  return (
    <section
      className="py-10 md:py-16"
      style={{
        backgroundColor: color ?? "#e5e1d3",
        color: color === "black" ? "white" : "#867b78",
      }}
    >
      <h1 className="text-base sm:text-lg md:text-xl lg:text-2xl text-center md:text-left">
        NOS OFFRES SUR MESURE
      </h1>
      <div className="block md:flex justify-between gap-x-12 mt-8 md:mt-16">
        <Swiper
          modules={[A11y, Autoplay]}
          slidesPerView={1}
          loop={true}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          pagination={{ clickable: true }}
          className="max-w-[100vw] mt-4 text-white"
          breakpoints={{
            768: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 5,
            },
            1400: {
              slidesPerView: 6,
              spaceBetween: 5,
            },
          }}
        >
          {offersToDisplay.map((offer, index) => (
            <SwiperSlide key={index} className="text-black text-left">
              <Offer
                image={`${STORAGE_BASE_URL}/${offer.icon}`}
                title={offer.title}
                textColor={color === "black" ? "white" : "black"}

              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Offers;
