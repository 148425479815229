import React, { useEffect, useState } from "react";
import ColoredCardsSlider from "../common/ColoredCardsSlider";
import { API_BASE_URL } from "../../const";

// Define the type for engagement data
interface Engagement {
  id: number;
  title: string;
  color: string;
  description: string;
  visibility: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

function RSEengagements() {
  // State to store engagements fetched from API
  const [engagements, setEngagements] = useState<Engagement[]>([]);

  useEffect(() => {
    const fetchEngagements = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/rseengagements`);
        const data: Engagement[] = await response.json();
        // Filter engagements based on visibility
        const visibleEngagements = data.filter(
          (engagement) => engagement.visibility === 1
        );
        setEngagements(visibleEngagements);
      } catch (error) {
        console.error("Failed to fetch engagements:", error);
      }
    };

    fetchEngagements();
  }, []);

  // Helper function to convert HTML to text items
  const parseHTMLToItems = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const items = Array.from(doc.querySelectorAll("strong"))
      .map((strong, index) => {
        const title = strong.textContent?.trim() || "";
        const description =
          strong.nextElementSibling?.textContent?.trim() || "";
        return { title, description };
      })
      .filter((item) => item.title && item.description);
    return items;
  };

  // Map engagements to match the structure expected by ColoredCardsSlider
  const sliderData = engagements.map((engagement) => ({
    title: engagement.title,
    color: engagement.color,
    items: parseHTMLToItems(engagement.description),
  }));

  return (
    <div className="bg-background">
      <ColoredCardsSlider title="NOS ENGAGEMENTS RSE" cards={sliderData} />
    </div>
  );
}

export default RSEengagements;
