import React from "react";
import Offers from "./Offers";
import Engagements from "./Engagements";

function OffersSection() {
  return (
    <div  >
      <Offers/>
      <Engagements />
    </div>
  );
}

export default OffersSection;
