import React from "react";

interface date {
  day?: number;
  month?: string;
  year?: number;
}

export interface LatestNewsCardProps {
  date?: date;
  title: string;
  news: string;
  icon?: string;
  dayBgColor: string;
  titleBgColor: string;
}

const LatestNewsCard: React.FC<LatestNewsCardProps> = ({
  date,
  title,
  news,
  dayBgColor,
  titleBgColor,
}) => {
  return (
    <div className="bg-[#f7f4ea] text-left ">
      <div className="flex w-full ">
        <div
          style={{ backgroundColor: dayBgColor }}
          className="p-2 lg:p-6 text-center lg:w-[35%] h-[9rem]"
        >
          <h1 className="text-white font-bold text-3xl">
            {date && date.day && date.day < 9 && "0"}
            {date && date.day}
          </h1>
          <h2 className="text-white font-bold text-sm uppercase">
            {date && date.month}
          </h2>
          <h2 className="text-white font-bold text-sm uppercase">
            {date && date.year}
          </h2>
        </div>
        <div
          className="py-4 pl-4 flex flex-col justify-center w-full"
          style={{ backgroundColor: titleBgColor }}
        >
          <h3 className="text-base 2xl:text-xl font-semibold">{title}</h3>
        </div>
      </div>
      <div className="p-4 ">
        <div className="mb-4 h-[10%]">
          <p
            className="text-sm"
            dangerouslySetInnerHTML={{ __html: news || "" }}
          />

        </div>
      </div>
    </div>
  );
};

export default LatestNewsCard;
