import { useNavigate } from "react-router-dom";
import Slider from "../../components/Slider";

function Claim() {
  const navigate = useNavigate();

  return (
    <>
      <Slider activeStep={0} />

      <section className="bg-background">
        <div className="text-center p-4 sm:p-6 md:p-8 lg:py-16 lg:px-0">
          <h1 className="text-primary text-base font-semibold md:text-lg">
            RÉCLAMATION
          </h1>
          <div className="mt-2 px-4 lg:px-0 py-8">
            <p className="text-black text-xs sm:text-base">
              Vous êtes mécontent ou insatisfait d'une décision prise par Ocean
              Bridges, de son fonctionnement ou du traitement de votre demande ?
              <br /> <br />
              Par le biais du formulaire de traitement des reclamations en ligne
            </p>
          </div>
          <div className="w-full justify-center flex">
            <button
              className=" bg-primary font-semibold text-white text-sm rounded-[1.25rem] sm:text-base px-6 py-2 my-6"
              onClick={() => navigate("/claim/yours")}
            >
              FAIRE UNE RECLAMATION EN LIGNE
            </button>
          </div>
          <div className="text-black mt-2 px-4 lg:px-16 py-8">
            <p className=" text-xs sm:text-base mb-4">
              Quel que soit l'objet de votre réclamation, vous pouvez prendre
              contact avec Ocean Bridges :
            </p>
            <p className="text-xs sm:text-sm">
              par courrier à l'adresse suivante :
            </p>
            <p className="text-sm font-semibold sm:text-base lg:w-[60%] mx-auto text-center my-10">
              OCEAN BRIDGES <br /> A l'attention personnelle des dirigeants
              <br />
              24 rue de Kerandouret <br /> 29750 LOCTUDY
            </p>
            <p className="text-xs sm:text-sm">
              par email à l’adresse : reclamation@oceanbridges.fr
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Claim;
