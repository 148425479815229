import {useEffect, useRef, useState} from "react";
import {A11y, Autoplay, Navigation, Scrollbar} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import Button from "../Button";
import axios from "axios";
import {Opportunity} from "../../models/Opportunity";
import {Card} from "../../models/Card";
import {API_BASE_URL} from "../../const";
import {Post} from "../../models/Post";
import {Swiper as SwiperType} from "swiper";

function OpportunitiesSection() {
  const [cards, setCards] = useState<Card[]>([]);
  const [post, setPost] = useState<Post>();
  const swiperRef = useRef<SwiperType | null>(null);

  useEffect(() => {

    axios
      .get(`${API_BASE_URL}/posts/13`)
      .then((response) => {
        const postData = response.data;

        // Check if the post is published
        if (postData.status === "PUBLISHED") {
          setPost(postData);
        }
      })
      .catch((error) => {
        console.error("Error fetching post:", error);
      });
    // Fetch data from the API
    axios
      .get<Opportunity[]>(`${API_BASE_URL}/opportunities`)
      .then((response) => {
        // Filter opportunities based on visibility
        const visibleOpportunities = response.data.filter(
          (opportunity) => opportunity.visibility === 1
        );
        // Map the filtered data to the structure expected by the component
        const mappedCards: Card[] = visibleOpportunities.map((opportunity) => ({
          title: opportunity.text.replace(/<\/?[^>]+(>|$)/g, ""), // Optional: Clean up the title if needed
          color: opportunity.color,
          items: opportunity.text, // Store the HTML as-is
        }));
        setCards(mappedCards);
      })
      .catch((error) => {
        console.error("Error fetching opportunities:", error);
      });
  }, []);

  useEffect(() => {
    if (swiperRef.current && cards.length > 0) {
      swiperRef.current.autoplay.start();
      swiperRef.current.update();
    }
  }, [cards]);

  return (
    <section>
      <div className="relative ">

        <h1 className="text-xl font-extrabold text-left">{post?.title}</h1>
        <div
            className="text-base text-left my-4 h-3/4"
          dangerouslySetInnerHTML={{ __html: post?.body || '' }}
        />
        <button className="rounded-full hidden absolute -left-14 top-1/2 lg:block custom-prev transition bg-[#675a5a] hover:bg-primary text-white p-3">
          <FaChevronLeft />
        </button>
        <button className="rounded-full hidden absolute lg:block -right-14 top-1/2 custom-next transition bg-[#675a5a] hover:bg-primary text-white p-3">
          <FaChevronRight />
        </button>
        <Swiper
          modules={[Navigation, A11y, Autoplay, Scrollbar]}
          slidesPerView={1}
          navigation={{
            nextEl: ".custom-next",
            prevEl: ".custom-prev",
          }}
          loop={cards?.length > 1}
          scrollbar={{ draggable: true }}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          pagination={{ clickable: true }}
          className="max-w-[100vw] h-[50vh] sm:h-[320px] lg:h-[300px] mt-8 text-white gap-x-2"
          breakpoints={{
            320: {
              slidesPerView: 1.2,
              spaceBetween: 0,
            },
            800: {
              slidesPerView: 1.2,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 2.2,
              spaceBetween: 0,
            },
            1400: {
              slidesPerView: 3.2,
              spaceBetween: 0,
            },
          }}
        >
          {cards.map((card, index) => (
            <SwiperSlide key={index} className="text-black">
              <div
                className={`mx-2 p-7 h-[80%] sm:h-[70%] md:h-[90%] text-left flex flex-col justify-between`}
                style={{ backgroundColor: card.color }}
              >
                <div>
                  {/* <h2 className="text-base font-extrabold">{card.title}</h2> */}
                  <div className="justify-between mt-6">
                    {/* {card.map((item:any) => ( */}
                    <div>
                      {/* <h2 className="text-base font-extrabold">{card.title}</h2> */}
                      <div className="justify-between mt-6">
                        <p
                          className="my-6 text-sm md:text-[16px]"
                          dangerouslySetInnerHTML={{ __html: card.items }}
                        />
                      </div>
                    </div>
                    {/* ))} */}
                  </div>
                  {/* <div className="text-sm 2xl:text-base mb-16 " dangerouslySetInnerHTML={{ __html: card.title }} ></div> */}
                </div>
                <div className="w-full flex justify-end align-baseline">
                  <Button
                    text="EN SAVOIR +"
                    color="black"
                    textColor="white"
                    size="xs"
                    sizeSm="[.6rem]"
                    width="100"
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}

export default OpportunitiesSection;

