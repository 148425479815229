import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Button from "../Button";

function SignUp() {
  return (
    <div>
      <div className="bg-[#a4e1e8] text-left p-8 h-[100%]">
        <h1 className="font-bold text-sm sm:text-lg">Pas encore inscrit ?</h1>
        <h2 className="text-xs sm:text-lg">Devenez partenaire</h2>
        <BrowserView>
          <p className="text-sm mt-4 mb-14 md:mb-[20%] xl:mb-[10%] 3xl:mb-[12%]">
            Rejoignez le réseau des partenaires d'Ocean Bridges.
            <br /> Accédez aux offres privilégiées et enrichissez votre
            catalogue de solutions d'assurance.
          </p>
          <div className="mt-2">
            <label className="block text-onAccent text-xl">Orias</label>
            <input
              type="email"
              className="py-2 px-4 w-full border-l-8 border-orange-400"
            />
          </div>
          {/* <button className="w-[50%] bg-black text-white font-semibold px-4 py-3 uppercase text-base mt-6">
            S'inscrire
          </button> */}
          <div className="grid xl:grid-cols-2 gap-6 items-center mt-6 w-[100%] xl:w-[100%]">
            <button className="bg-[#35BAC9] text-white font-semibold px-4 2xl:px-8 py-2 uppercase text-base md:text-sm 2xl:text-lg">
              S'inscrire
            </button>
            {/* <a href="/subscribe" className="text-onAccent text-xs 2xl:text-lg w-[40%]">
            Mot de passe oublié ?
          </a> */}
          </div>
        </BrowserView>
        <MobileView>
          <h3 className="text-black text-sm font-bold my-6">
            - Accès non disponible sur mobile -
          </h3>
          <p className="text-sm ">
            Nous regrettons de vous informer que cette fonctionnalité n'est pas disponible sur mobile.
            Pour une expérience optimale, veuillez accéder à cette section depuis un ordinateur de bureau.
          </p>
          <p className="text-sm mt-8">Assuré de votre compréhension.</p>
          <div className="flex justify-center mt-[10%]">
            <Button
              sizeSm="[.7rem]"
              text="RDV SUR VOTRE ORDINATEUR"
              color="primary"
              textColor="white"
              radius="full"
              // sizeSm="[.6rem]"
              width="100"
            />
          </div>
        </MobileView>
      </div>
    </div>
  );
}

export default SignUp;
