import { Link } from "react-router-dom";
import Logo from "../assets/logo.svg";
import LloydsLogo from "../assets/img/logos/lloyds-logo.png";
import Menu from "../assets/icons/menu-bar.svg";
import Search from "../assets/icons/search-bar.svg";
import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Chevron from "../assets/icons/chevron-up.svg";
import { IoMdCloseCircleOutline } from "react-icons/io";
import axios from "axios";
import { MenuItem } from "../models/MenuItem";
import { API_BASE_URL } from "../const";

interface NavigationProps {
  toggleContactModal: () => void;
}

interface NestedItem extends MenuItem {
  children: NestedItem[];
}

const NavigationBar: React.FC<NavigationProps> = ({ toggleContactModal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [menuItemsButton, setMenuItemsButton] = useState<MenuItem[]>([]);
  const [menuItemsSide, setMenuItemsSide] = useState<NestedItem[]>([]);
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await axios.get<MenuItem[]>(`${API_BASE_URL}/menus-navbars`);
        const visibleMenuItems = response.data.filter(item => item.visible === 1 && item.button === 0 && item.sidebare === 0);
        setMenuItems(visibleMenuItems);

        const visibleMenuItemsButton = response.data.filter(item => item.visible === 1 && item.button === 1 && item.sidebare === 0);
        setMenuItemsButton(visibleMenuItemsButton);

        const visibleMenuItemsSide = response.data.filter(item => item.visible === 1 && item.button === 0 && item.sidebare === 1);
        const nestedItems = nestItems(visibleMenuItemsSide, 1);
        setMenuItemsSide(nestedItems);

      } catch (error) {
        console.error("Error fetching menu items:", error);
        // You might want to display a user-friendly error message here
      }
    };

    fetchMenuItems();
  }, []);

  function nestItems(items: MenuItem[], order_sidebare: number): NestedItem[] {
    // Filter items based on order_sidebare
    const filteredItems = items.filter(item => item.sidebare === order_sidebare);

    const itemMap = new Map<number, NestedItem>();
    filteredItems.forEach(item => {
      itemMap.set(item.id, { ...item, children: [] });
    });

    const nestedItems: NestedItem[] = [];
    filteredItems.forEach(item => {
      if (item.parent_id === null) {
        nestedItems.push(itemMap.get(item.id)!);
      } else {
        const parent = itemMap.get(item.parent_id);
        if (parent) {
          parent.children.push(itemMap.get(item.id)!);
        }
      }
    });

    // Sort the top-level nested items based on `order_sidebare`
    nestedItems.sort((a, b) => (a.order_sidebare || 99) - (b.order_sidebare || 99));

    // Recursive function to sort children
    function sortChildren(items: NestedItem[]): void {
      items.forEach(item => {
        item.children.sort((a, b) => (a.order_sidebare || 99) - (b.order_sidebare || 99));
        sortChildren(item.children);
      });
    }

    sortChildren(nestedItems);

    return nestedItems;
  }


  const toggleDropDown = (id: number) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };

  return (
    <nav className="flex sticky top-0 justify-between md:justify-around items-center px-4 md:px-[10%] py-6 z-50 bg-white">
      <Link to="/" className="flex gap-4 2xl:gap-10 items-center">
        <img className="h-6 md:h-10" src={Logo} alt="logo" loading="lazy" />
        <img className="h-8 hidden sm:block" src={LloydsLogo} alt="logo" loading="lazy" />
      </Link>

      <ul className="flex space-x-2 2xl:space-x-4 text-sm sm:text-xs 2xl:text-sm">
        {menuItems.map((item) => (
          <li key={item.id} className="my-auto hidden xl:block">
            <Link to={item.url || "#"} className="hover:text-blue-400 transition duration-500 font-bold uppercase">
              {item.name}
            </Link>
          </li>
        ))}

        <div className="px-4 hidden md:flex gap-x-2 2xl:gap-x-4">
          {menuItemsButton.filter(item => item.button === 1).map((item, index) => (
            <a
              key={item.id}
              onClick={index === 0 ? () => { window.location.href = item.url || '#' } : toggleContactModal}
              className={`uppercase text-xs 2xl:text-sm ${index === 0 ? "bg-black" : "bg-primary"} text-white px-4 py-2 border border-white transition duration-500 hover:border-black hover:bg-white hover:text-black rounded-full my-auto hover:font-bold`}
            >
              {item.name}
            </a>
          ))}
        </div>

        <div className="relative">
          <div
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            className="relative w-fit h-fit"
          >
            <div className="flex gap-3 items-center">
              <img
                className="sm:hidden cursor-pointer"
                src={Search}
                onClick={() => setIsOpen(!isOpen)}
                alt="Search"
              />
              <img
                className="h-8 cursor-pointer"
                src={Menu}
                onClick={() => setIsOpen(!isOpen)}
                alt="Menu"
              />
            </div>

            <AnimatePresence>
              {isOpen && (
                <motion.div
                  initial={{ opacity: 0, y: 15 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 15 }}
                  style={{ translateX: "-50%" }}
                  transition={{ duration: 0.3, ease: "easeOut" }}
                  className="fixed md:absolute bg-transparent right-0 top-[5rem] right md:left-1/2 md:-top-8 bg-white text-black"
                >
                  <div
                    className={`fixed md:absolute right-0 top-[5px] sm:left-auto bg-black bg-opacity-80 sm:bg-opacity-60 h-auto sm:h-auto backdrop-blur-lg shadow-lg z-50 text-white w-screen md:w-[360px] md:-right-[120px] ${isOpen ? "block" : "hidden"}`}
                  >
                    <div className="p-4">
                      <IoMdCloseCircleOutline
                        onClick={() => setIsOpen(false)}
                        className="w-8 h-8 right-4 cursor-pointer text-white"
                      />
                    </div>
                    <ul className="flex flex-col overflow-y-auto w-full">

                      {menuItemsSide.map((link, index) => (
                        <li key={index} className="text-left w-full">
                          {link.name ? (
                            <Link
                              to={link.url ? link.url : "#"}
                              // onClick={!link.url ? () => window.location.reload : undefined}
                              className="hover:text-blue-400 transition duration-500 font-bold uppercase px-4 py-2 border-gray-800 border w-full block"
                            >
                              <div
                                onClick={() => link.children.length > 0 && toggleDropDown(link.id)}
                                className="flex justify-between"
                              >
                                <p>{link.name}</p>
                                {link.children.length > 0 && (
                                  <img
                                    src={Chevron}
                                    alt="chevron"
                                    className={`w-6 transition-transform duration-300 ${openDropdown === link.id ? "rotate-180" : ""}`}
                                  />
                                )}
                              </div>
                            </Link>
                          ) : (
                            <span className="hover:text-blue-400 transition duration-500 font-bold uppercase px-4 py-2 border w-full block">
                              {link.name}
                            </span>
                          )}
                          {link.children.length > 0 && (
                            <div className={`transition-all duration-500 ${openDropdown === link.id ? "max-h-screen" : "max-h-0 overflow-hidden"}`}>
                              <ul className="pl-4">
                                {link.children.map((child) => (
                                  <li key={child.id} className="text-left w-full">
                                    <Link
                                      to={child?.url || '#'}
                                      className="hover:text-blue-400 text-gray-300 text-xs transition duration-500  uppercase px-8 py-2 w-full block"
                                    >
                                      {child.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                    <button
                      onClick={toggleContactModal}
                      className="sm:hidden uppercase text-sm bg-primary text-white mt-8 px-6 py-2 transition duration-500 hover:bg-white hover:text-blue-400 rounded-full mb-4"
                    >
                      CONTACTS
                    </button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </ul>
    </nav>
  );
};

export default NavigationBar;
