import React, { useEffect, useState } from "react";
import { Post } from "../../models/Post";
import { API_BASE_URL, STORAGE_BASE_URL } from "../../const";
import axios from "axios";

function NormsSection() {
  const [post, setPost] = useState<Post>();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get<Post>(`${API_BASE_URL}/posts/18`);
        // const visiblePosts = response.data.filter((post) => post.visible === 1);
        setPost(response.data);
      } catch (error) {
        console.error("Error fetching useful tips:", error);
      }
    };

    fetchPosts();
  }, [])
  return (
    <div className="px-[5%] md:px-[25%] py-16 bg-[#fffdf8]">
      <div className="block md:flex text-center md:text-left mt-8 gap-x-10 items-center">
        <img
          src={`${STORAGE_BASE_URL}/${post?.image}`}
          alt=""
          className="w-[10%] md:w-[15%] mx-auto md:mx-0 my-4 md:my-0"
        />
        <div className="">
          <h1 className="text-xl lg:text-2xl text-black font-extrabold mb-4 md:w-1/2">
            {post?.title}
          </h1>
          <p className="text-lg font-semibold text-[#867b78]" dangerouslySetInnerHTML={{ __html: post?.body || "" }} />
        </div>
      </div>
    </div>
  );
}

export default NormsSection;
