import { createContext, ReactNode, useState, FC } from "react";

interface FormData {
    insured: string;
    against: string;
    otherCase: string;
    insuranceProposal: string;
    contractManagement: string;
    information: string;
    claim: string;
    contract: string;
    subscription: string;
    premium: string;
    personalInfo: string;
    other: string;
    otherCases: string;
    details: string;

    files: File[];

    alreadyInsured: string;
    lastName: string;
    firstName: string;
    address: string;
    adhesionDate: string;
    adhesionNumber: string;
    email: string;
    phone: string;
    clientNumber: string;
    contractNumber: string;
    claimNumber: string;
    receiptNumber: string;
}

interface FormContextType {
    formData: FormData;
    setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

export const FormContext = createContext<FormContextType>({
    formData: {
        insured: "",
        against: "",
        otherCase: "",
        insuranceProposal: "",
        contractManagement: "",
        information: "",
        claim: "",
        contract: "",
        subscription: "",
        premium: "",
        personalInfo: "",
        other: "",
        otherCases: "",
        details: "",

        files: [],

        alreadyInsured: "",
        lastName: "",
        firstName: "",
        address: "",
        adhesionDate: "",
        adhesionNumber: "",
        email: "",
        phone: "",
        clientNumber: "",
        contractNumber: "",
        claimNumber: "",
        receiptNumber: "",
    },
    setFormData: () => {},
});

interface FormProviderProps {
    children: ReactNode;
}

export const FormProvider: FC<FormProviderProps> = ({ children }) => {
    const [formData, setFormData] = useState<FormData>({
        insured: "",
        against: "",
        otherCase: "",
        insuranceProposal: "",
        contractManagement: "",
        information: "",
        claim: "",
        contract: "",
        subscription: "",
        premium: "",
        personalInfo: "",
        other: "",
        otherCases: "",
        details: "",

        files: [],

        alreadyInsured: "",
        lastName: "",
        firstName: "",
        address: "",
        adhesionDate: "",
        adhesionNumber: "",
        email: "",
        phone: "",
        clientNumber: "",
        contractNumber: "",
        claimNumber: "",
        receiptNumber: "",
    });

    return (
        <FormContext.Provider value={{ formData, setFormData }}>
            {children}
        </FormContext.Provider>
    );
};