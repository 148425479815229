import React from "react";

interface RegulationCardProps {
  title: string;
  description: string;
  color: string;
}

const RegulationCard: React.FC<RegulationCardProps> = ({
  title,
  description,
  color,
}) => {
  return (
    <div className="flex flex-col text-left">
      <div
        className="p-4 h-[35%] 2xl:h-[30%]"
        style={{ backgroundColor: color }}
      >
        {" "}
        <h1 className="font-bold text-base 2xl:text-lg">{title}</h1>
      </div>
      <div className="bg-background p-4 h-[65%] 2xl:h-[70%]">
        <p className="text-sm 2xl:text-base mb-16 " dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </div>
  );
};

export default RegulationCard;
