import React from 'react';

const Contact = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <h1 className="text-4xl">Contact Us</h1>
    </div>
  );
}

export default Contact;
